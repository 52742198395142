<template>
  <div class="MaterialesListado">
    <div id="card">
      <v-card
        outlined
        :disabled="cargando"
        :loading="cargando"
      >
        <v-card-title>
          Materiales
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            hide-details
          />
          <ButtonAdd @click="showDialog = true" />
        </v-card-title>
        <v-data-table
          class="upper"
          :headers="headers"
          :items="materiales"
          :search="search"
          :loading="cargando"
          loading-text="Cargando..."
        >
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon v-text="'more_vert'" />
                </v-btn>
              </template>
              <v-list dark>
                <v-list-item @click="_editMat(item,'form')">
                  <v-list-item-title v-text="'Editar'" />
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div id="dialog">
      <v-dialog
        v-model="showDialog"
        width="400"
      >
        <MaterialesForm
          v-if="whatDialogMustBeingShow==='form'"
          v-model="material"
          @cancel="showDialog=false"
          @msg="$emit('msg',$event)"
          @httpError="$emit('httpError',$event)"
          @saved="_addMaterial"
          @updated="_updateMaterial"
        />
        <MaterialesExistenciasAgregar />
      </v-dialog>
    </div>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
export default {
  name: "MaterialesListado",
  mixins: [standard],
  components: {
    MaterialesForm: () => import("@/components/materiales/MaterialesForm.vue"),
    MaterialesExistenciasAgregar: () =>
      import("@/components/materiales/MaterialesExistenciasAgregar.vue"),
  },
  data() {
    return {
      materiales: [],
      showDialog: false,
      whatDialogMustBeingShow: "form",
      headers: [
        { text: "Material", value: "desc_mat" },
        { text: "Mín", value: "min_mat" },
        { text: "Máx", value: "max_mat" },
        { text: "", value: "actions", align: "end" },
      ],
      material: {},
      newMaterial: {
        id_mat: null,
        id: null,
        desc_mat: null,
        min_mat: null,
        max_mat: null,
      },
    };
  },
  methods: {
    _addMaterial(data) {
      this.materiales.push(data);
    },
    _updateMaterial(data) {
      const leData = data;
      const index = this.materiales.findIndex((e) => {
        return e.id === data.id;
      });
      if (index >= 0) {
        this.materiales[index].desc_mat = data.desc_mat;
        this.materiales[index].min_mat = data.min_mat;
        this.materiales[index].max_mat = data.max_mat;
      }
    },
    _editMat(data, dialog = "showDialog") {
      this.material.id = data.id;
      this.material.id_mat = data.id_mat;
      this.material.desc_mat = data.desc_mat;
      this.material.min_mat = data.min_mat;
      this.material.max_mat = data.max_mat;
      this.whatDialogMustBeingShow = dialog;
      this.showDialog = true;
    },
  },
  created() {
    this.material = this.newMaterial;
    this._getAll("materiales");
  },
  watch: {
    showDialog(a) {
      if (!a) {
        this.material = this.newMaterial;
      }
    },
  },
};
</script>
