var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PacienteDetalleCitas"},[_c('div',{attrs:{"id":"card"}},[_c('v-card',{attrs:{"outlined":"","disabled":_vm.cargando,"loading":_vm.cargando}},[_c('v-card-title',[_vm._v(" Citas "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"primary",attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showDialogCita = true}}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('v-data-table',{staticClass:"upper",attrs:{"headers":_vm.headers,"items":_vm.citas,"search":_vm.search,"loading":_vm.cargando,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$Datetime .fromSQL(item.fecha) .setLocale("es") .toLocaleString(_vm.$Datetime.DATETIME_MED))+" ")]}},{key:"item.estatus_cit",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.color,"dark":"","small":""}},[_vm._v(" "+_vm._s(item.estatus_cit)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('more_vert')}})],1)]}}],null,true)},[_c('v-list',{attrs:{"dark":""}},[_c('v-list-item',{on:{"click":function($event){return _vm._editCita(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Editar')}})],1)],1)],1)]}}])})],1)],1),_c('citas-form-dialog',{attrs:{"id":_vm.idCitaEditar,"idPac":_vm.paciente},on:{"msg":function($event){return _vm.$emit('msg',$event)},"httpError":function($event){return _vm.$emit('httpError',$event)},"refresh":_vm._getCitas},model:{value:(_vm.showDialogCita),callback:function ($$v) {_vm.showDialogCita=$$v},expression:"showDialogCita"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }