<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="value"
        :label="label ? label:'Seleccione una fecha'"
        prepend-inner-icon="event"
        :error-messages="error"
        readonly
        filled
        v-on="on"
        :disabled="disabled"
      />
    </template>
    <v-date-picker
      ref="picker"
      v-model="value"
      :max="!sinceToday ? dateLimit : dateLimitYear"
      :min="!sinceToday ? '1900-01-01': dateLimit"
      @change="_doSave"
      @blur="$emit('blur',value)"
    />
  </v-menu>
</template>
<script>
export default {
  name: "DatePicker",
  props: {
    value: String,
    label: String,
    error: String,
    sinceToday: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      menu: false,
      dateLimit: new Date().toISOString().substr(0, 10),
      dateLimitYear: new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      )
        .toISOString()
        .substr(0, 10),
    };
  },
  watch: {
    menu(val) {
      if (this.value) {
      } else {
        val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
      }
    },
  },
  methods: {
    _doSave(date) {
      this.$refs.menu.save(date);
      this.$emit("input", date);
      this.$emit("blur", date);
      this.$emit("change", date);
    },
  },
};
</script>