var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DepositoDentalListado"},[_c('v-card',{attrs:{"outlined":"","disabled":_vm.cargando,"loading":_vm.cargando}},[_c('v-card-title',[_vm._v(" Depósito dental "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('deposito-dental-form-dialog',{on:{"msg":function ($event){ return _vm.$emit('msg',$event); },"httpError":function ($event){ return _vm.$emit('httpError',$event); },"refresh":_vm._getDepositos},model:{value:(_vm.idDep),callback:function ($$v) {_vm.idDep=$$v},expression:"idDep"}})],1),_c('v-data-table',{staticClass:"upper",attrs:{"headers":_vm.headers,"items":_vm.depositos,"search":_vm.search,"loading":_vm.cargando,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"item.precio_dep",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$nF(item.precio_dep))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dark":""}},[_c('v-list-item',{on:{"click":function($event){_vm.idDep=item.id}}},[_c('v-list-item-title',[_vm._v("Editar")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.idDepImg=item.id_dep;_vm.nombre=item.nombre_dep;_vm.showDialogImagenes=true}}},[_c('v-list-item-title',[_vm._v("Imágenes")])],1)],1)],1)]}}])})],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.showDialogImagenes),callback:function ($$v) {_vm.showDialogImagenes=$$v},expression:"showDialogImagenes"}},[_c('v-card',{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',[_vm._v("Imágenes para "+_vm._s(_vm.nombre.toUpperCase()))]),_c('v-card-text',[_c('v-file-input',{attrs:{"filled":"","label":"Subir fotografía del material","accept":"image/jpeg, image/png, image/jpg","prepend-icon":"","prepend-inner-icon":"attach_file"},model:{value:(_vm.archivo),callback:function ($$v) {_vm.archivo=$$v},expression:"archivo"}}),_c('v-btn',{staticClass:"mb24",attrs:{"disabled":_vm.archivo?false:true,"color":"primary"},on:{"click":_vm._saveImagenes}},[_c('v-icon',[_vm._v("upload")]),_vm._v("Subir")],1),_c('v-row',_vm._l((_vm.imagenes),function(img){return _c('v-col',{key:img.id_def,staticClass:"d-flex child-flex",attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"grey lighten-2",attrs:{"src":_vm._getImage(img.img_def),"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('v-list',{attrs:{"dark":""}},[_c('v-list-item',{on:{"click":function($event){return _vm._deleteImage(img.id_def)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("delete")]),_vm._v(" Eliminar imagen")],1)],1)],1)],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }