<template>
  <div class="PacienteDetalleCitas">
    <div id="card">
      <v-card
        outlined
        :disabled="cargando"
        :loading="cargando"
      >
        <v-card-title>
          Citas
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            hide-details
          />
          <v-btn
            icon
            class="primary"
            dark
            @click="showDialogCita = true"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          class="upper"
          :headers="headers"
          :items="citas"
          :search="search"
          :loading="cargando"
          loading-text="Cargando..."
        >
          <template v-slot:item.fecha="{ item }">
            {{
              $Datetime
                .fromSQL(item.fecha)
                .setLocale("es")
                .toLocaleString($Datetime.DATETIME_MED)
            }}
          </template>
          <template v-slot:item.estatus_cit="{ item }">
            <v-chip
              :color="item.color"
              dark
              small
            >
              {{ item.estatus_cit }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon v-text="'more_vert'" />
                </v-btn>
              </template>
              <v-list dark>
                <v-list-item @click="_editCita(item)">
                  <v-list-item-title v-text="'Editar'" />
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <citas-form-dialog
      v-model="showDialogCita"
      :id="idCitaEditar"
      :idPac="paciente"
      @msg="$emit('msg',$event)"
      @httpError="$emit('httpError',$event)"
      @refresh="_getCitas"
    />
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import CitasFormDialog from "@/components/citas/CitasFormDialog.vue";
export default {
  components: { CitasFormDialog },
  name: "PacienteDetalleCitas",
  mixins: [forms],
  data() {
    return {
      search: "",
      headers: [
        { text: "Fecha", value: "fecha" },
        { text: "Médico encargado", value: "medico" },
        { text: "Estatus", value: "estatus_cit" },
        { text: "", value: "actions", align: "end" },
      ],
      showDialogCita: false,
      idCitaEditar: null,
      paciente: null,
      citas: [],
    };
  },
  methods: {
    _getCitas() {
      this._getThings("citas/paciente/" + this.$route.params.idPac, "citas");
    },
    _editCita({ id }) {
      this.idCitaEditar = id;
      this.showDialogCita = true;
    },
  },
  created() {
    this.paciente = this.$route.params.idPac;
    this._getCitas();
  },
  watch: {
    showDialogCita(a) {
      if (!a) {
        this.idCitaEditar = null;
      }
    },
  },
};
</script>