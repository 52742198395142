<template>
  <div class="SelectPlanesTratamientoPaciente">
    <v-autocomplete
      v-model="valor"
      append-icon="search"
      :disabled="cargando"
      item-text="tratamiento"
      item-value="id_plt"
      :items="planes"
      :label="label ? label : 'Buscar plan de tratamiento'"
      filled
      :loading="cargando"
      @change="_searchAndSendPro"
    >
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title v-text="data.item.tratamiento"></v-list-item-title>
            <v-list-item-subtitle v-text="_formatFecha(data.item.fecha)" />
            <v-list-item-subtitle v-text="data.item.realizo" />
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "SelectPlanesTratamientoPaciente",
  props: {
    value: String,
    paciente: String,
    label: String,
  },
  mixins: [forms],
  data() {
    return {
      valor: null,
      planes: [],
    };
  },
  methods: {
    _getPlanesTratamiento() {
      this._getThings("planesdetratamiento/" + this.paciente, "planes");
    },
    _searchAndSendPro(a) {
      this.$emit("input", a);
      let i = this.planes.findIndex(function (b) {
        return b.id_plt === a;
      });
      if (i >= 0) {
        this.$emit("change", this.planes[i]);
      }
    },
    _formatFecha(fecha) {
      return this.$Datetime
        .fromSQL(fecha)
        .setLocale("es")
        .toLocaleString(this.$Datetime.DATE_HUGE);
    },
  },
  created() {
    this._getPlanesTratamiento();
  },
  watch: {
    valor(a) {
      this.$emit("input", a);
    },
    paciente(a) {
      if (a) this._getPlanesTratamiento();
    },
  },
};
</script>