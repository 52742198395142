<template>
  <div class="PlanesTratamientoForm">
    <v-dialog
      width="800"
      v-model="showDialog"
      persistent
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title v-text="'Registrar plan de tratamiento'" />
        <v-card-text>
          <v-text-field
            filled
            label="Descripción"
            v-model="plantratamiento.descripcion_plt"
          />
          <v-checkbox
            value="si"
            :label="plantratamiento.realizado_plt ==='si'?'Ya fue realizado':'¿Ya fue realizado?'"
            v-model="plantratamiento.realizado_plt"
          />
          <div v-if="plantratamiento.realizado_plt ==='si'">
            <date-picker
              label="¿Cuándo fue realizado?"
              v-model="plantratamiento.fecha_realizado_plt"
            />
            <v-textarea
              label="Nota de evolución"
              rows="2"
              filled
              v-model="plantratamiento.nota_evolucion_plt"
            />
          </div>
        </v-card-text>
        <button-card-actions
          text="Guardar"
          @cancel="showDialog=false"
          @click="_savePlanTratamiento"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import SelectPlanesTratamientoPaciente from "@/components/forms/planestratamientos/SelectPlanesTratamientoPaciente.vue";
import ButtonCardActions from "@/components/forms/buttons/ButtonCardActions.vue";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
import CitasFormDialog from "@/components/citas/CitasFormDialog.vue";
import AutocompleteUsuarios from "@/components/forms/autocompletes/AutocompleteUsuarios.vue";
export default {
  name: "PlanesTratamientoForm",
  props: {
    value: Boolean,
    paciente: String,
  },
  mixins: [forms],
  components: {
    SelectPlanesTratamientoPaciente,
    ButtonCardActions,
    DatePicker,
    CitasFormDialog,
    AutocompleteUsuarios,
  },
  data() {
    return {
      showDialog: false,
      preShowDialogCita: false,
      showDialogCita: false,
      plantratamiento: {
        id_pac_plt: null,
        realizado_plt: null,
        fecha_realizado_plt: null,
        descripcion_plt: null,
      },
      errors: {
        descripcion_plt: null,
      },
    };
  },
  methods: {
    _savePlanTratamiento() {
      if (this._validateAll("plantratamiento")) {
        this.plantratamiento.id_pac_plt = this.paciente;
        const data = { plantratamiento: this.plantratamiento };
        this._saveAll(
          "planesdetratamiento",
          data,
          "id",
          "_handleResponsePlanTratamiento"
        );
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _handleResponsePlanTratamiento(res) {
      this.$emit("msg", res.msg);
      this.$emit("refresh", true);
      this.showDialog = false;
    },
  },
  created() {
    if (this.value) this.showDialog = true;
    if (this.paciente) this.plantratamiento.id_pac_plt = this.paciente;
  },
  watch: {
    value(a) {
      if (a) this.showDialog = a;
    },
    showDialog(a) {
      if (!a) {
        this.$emit("input", false);
        this.showDialog = false;
        this._limpiarCampos("plantratamiento");
      } else {
        this.plantratamiento.id_pac_plt = this.paciente;
      }
    },
    paciente(a) {
      this.plantratamiento.id_pac_plt = a;
    },
  },
};
</script>