<template>
  <div class="PacienteDetalleGarantias">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Garantías del paciente
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="garantias"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.fecha_cgo="{item}">
          {{_doDiaMes(item.fecha_cgo)}}
        </template>
        <template v-slot:item.caducidad="{item}">
          {{_doDiaMes(item.caducidad)}}
        </template>

        <!-- <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item @click="idDep=item.id">
                <v-list-item-title v-text="'Editar'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template> -->
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
export default {
  name: "PacienteDetalleGarantias",
  mixins: [forms, dates],
  data() {
    return {
      search: "",
      garantias: [],
      headers: [
        { text: "Servicio", value: "desc_ser" },
        { text: "Cargo", value: "id_cgo" },
        { text: "Fecha de cargo", value: "fecha_cgo" },
        { text: "Vigencia garantía", value: "caducidad" },
        { text: "", value: "actions", align: "end" },
      ],
      paciente: null,
    };
  },
  methods: {},
  created() {
    this.paciente = this.$route.params.idPac;
  },
  mounted() {
    this._getThings("garantias/" + this.paciente, "garantias");
  },
};
</script>