<template>
  <div class="Home">
  </div>
</template>

<script>
import standard from "@/mixins/standard.js";
import whatsapp from "@/mixins/whatsapp.js";
export default {
  name: "Home",
  mixins: [standard, whatsapp],
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
