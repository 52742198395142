// const accountSid = 'AC26affb5a8fc54e14fa733fd9ea0e7753';
// const authToken = '08f91a43767763b137eac117200d1cc1';
// const client = require('twilio')(accountSid, authToken);
export default {
    data() {
        return {}
    },
    methods: {
        _sendWhatsApp() {
            console.log('about to send whatsapp message');
            const accountSid = 'AC26affb5a8fc54e14fa733fd9ea0e7753';
            const authToken = '08f91a43767763b137eac117200d1cc1';
            const client = require('twilio')(accountSid, authToken, {
                logLevel: 'debug'
            });
            client.messages
                .create({
                    body: 'Your appointment is coming up on July 21 at 3PM',
                    from: 'whatsapp:+14155238886',
                    to: 'whatsapp:+5215554656601'
                })
                .then(message => {
                    console.log('then')
                    console.log(message.sid)
                })
                .done(res => {
                    console.log('done')
                    console.log(res)
                });
        },
    }
}