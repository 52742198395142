<template>
  <v-card-text class="Loading">
    <v-progress-linear
      indeterminate
      color="secondary"
    />
    <p align="center">
      <small v-text="'Cargando...espere un momento'" />
    </p>
  </v-card-text>
</template>
<script>
export default {
  name: "Loading"
};
</script>