<template>
  <div class="ReportesCorteCaja">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Corte de caja del {{_doDiaMes(inicio)}} al {{_doDiaMes(fin)}}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
          >
            <div class="display-1">
              Total: {{$nF(total)}}
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <date-picker
              v-model="preInicio"
              label="Inicio"
              @change="_setFechas"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <date-picker
              v-model="preFin"
              label="Fin"
              @change="_setFechas"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="cortecaja"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.importe="{ item }">
          {{$nF(item.importe)}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item>
                <v-list-item-title v-text="'Ver más'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
export default {
  name: "ReportesCorteCaja",
  mixins: [standard, forms, dates],
  components: { DatePicker },
  data() {
    return {
      headers: [
        { text: "Folio", value: "id_abo" },
        { text: "Fecha", value: "fecha_abo" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Metodo pago", value: "metodo_pago" },
        { text: "Cobró", value: "cobro" },
        { text: "Paciente", value: "paciente" },
        { text: "", value: "actions", align: "end" },
      ],
      cortecaja: [],
      total: 0,
    };
  },
  methods: {
    async _setFechas() {
      this.inicio = this.preInicio ? this.preInicio : this._today();
      this.fin = this.preFin ? this.preFin : this._today();
      this.cargando = true;
      try {
        const { status, body } = await this.$http.get(
          "reportes/cortecaja?inicio=" + this.inicio + "&fin=" + this.fin,
          this.$headers
        );
        this.cargando = false;
        if (status === 200 && "cortecaja" in body) {
          this.cortecaja = body.cortecaja;
          this.total = body.total;
        } else {
          this.$emit("msg", res.error);
        }
      } catch (err) {
        this.cargando = false;
      }
      this._getThings("cortecaja");
    },
  },
  created() {
    this.preInicio = this._today();
    this.preFin = this._today();
    this._setFechas();
  },
};
</script>