<template>
  <div class="CumpleanosCalendario">
    <v-card outlined>
      <v-toolbar flat>
        <v-btn
          outlined
          color="primary"
          @click="_setToday"
          v-text="'Hoy'"
        />
        <v-btn
          icon
          @click="prev"
          class="ml4"
        >
          <v-icon v-text="'keyboard_arrow_left'" />
        </v-btn>
        <v-btn
          icon
          @click="next"
        >
          <v-icon v-text="'keyboard_arrow_right'" />
        </v-btn>
        <v-toolbar-title
          v-if="$refs.calendar"
          v-text="$refs.calendar.title"
        />
      </v-toolbar>
      <v-calendar
        flat
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="cumpleanos"
        :type="type"
        @click:event="_showCita"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="_updateRange"
      />
    </v-card>
  </div>
</template>

<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
export default {
  name: "CumpleanosCalendario",
  mixins: [standard, forms],
  data() {
    return {
      inicio: null, //guardamos el rango de fechas del calendario
      fin: null, //guardamos el rango de fechas del calendario
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Mensual",
        week: "Semanal",
        day: "Diario",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      cumpleanos: [],
      cargando: false,
      idCitaEditar: null,
      showDialogCita: false,
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    _setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    _showCita({ event }) {
      let routeData = this.$router.resolve({
        name: "PacientesForm",
      });
      window.open(routeData.href + "/historiaclinica/" + event.id, "_blank");
    },
    _updateRange({ start, end }) {
      this.inicio = start.date;
      this.fin = end.date;
      this._getCumpleanos();
    },
    _getCumpleanos() {
      this._getThings(
        "cumpleanos?inicio=" + this.inicio + "&fin=" + this.fin,
        "cumpleanos"
      );
    },
  },
  watch: {
    showDialogCita(a) {
      if (!a) {
        this.idCitaEditar = null;
      }
    },
  },
};
</script>
