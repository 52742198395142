<template>
  <v-list-group
    color="white"
    :prepend-icon="icon"
    no-action
  >
    <template v-slot:activator>
      <v-list-item-title v-text="title" />
    </template>
    <v-list-item
      v-for="(item,i) in items"
      :key="i"
      link
      :to="item.to"
    >
      <v-list-item-title v-text="item.text" />
    </v-list-item>
  </v-list-group>
</template>
<script>
export default {
  name: "ListGroupMenu",
  props: {
    color: String,
    icon: String,
    title: String,
    items: Array
  }
};
</script>