<template>
  <div class="PacientesInfoGeneral">
    <v-card outlined>
      <v-list>
        <v-list-item three-line>
          <v-list-item-avatar size="64">
            <v-img
              v-if="!paciente.foto_pac"
              src="@/assets/avatars/perfil.png"
            ></v-img>
            <v-img
              v-else
              :src="$raiz + 'uploads/imagenes/' + paciente.foto_pac"
              @click="showDialogFullFoto=true"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="upper"
              v-text="`${paciente.nombre_pac} ${paciente.ape_pat_pac} ${paciente.ape_mat_pac}`"
            />
            <v-list-item-subtitle v-text="'Género: '+paciente.genero_pac" />
            <v-list-item-subtitle v-text="edad+' años de edad'" />
            <v-list-item-subtitle
              class="upper"
              v-if="edad <18"
              v-text="paciente.tipo_tutor_pac+', '+paciente.nombre_tutor_pac"
            />
          </v-list-item-content>
          <v-list-item-action v-if="id">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon v-text="'more_vert'" />
                </v-btn>
              </template>
              <v-list dark>
                <v-list-item :to="'/paciente/'+id">
                  <v-list-item-title v-text="'Editar'" />
                </v-list-item>
                <v-list-item :to="'/paciente/historiaclinica/'+id+'/citas'">
                  <v-list-item-title v-text="'Historia clínica'" />
                </v-list-item>
                <v-list-item :to="'/consulta/'+id">
                  <v-list-item-title v-text="'Consulta dental'" />
                </v-list-item>
                <v-list-item @click="idPac=id">
                  <v-list-item-title v-text="'Carrito de compra'" />
                </v-list-item>
                <v-list-item @click="showDialogFotoPerfil=true">
                  <v-list-item-title v-text="'Foto de perfil'" />
                </v-list-item>
                <v-list-item :to="'/encuesta/'+id">
                  <v-list-item-title v-text="'Encuesta de satisfacción'" />
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
    <carrito-dialog
      v-model="idPac"
      @msg="$emit('msg',$event)"
      @httpError="$emit('httpError',$event)"
    />
    <v-dialog
      v-model="showDialogFotoPerfil"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title>Subir imagen de perfil</v-card-title>
        <v-card-text>
          <v-file-input
            label="Seleccionar fotografía"
            filled
            v-model="archivo"
            accept="image/jpeg, image/png, image/jpg"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            :disabled="cargando"
            v-text="'Cancelar'"
            @click="showDialogFotoPerfil=false"
          />
          <v-btn
            :loading="cargando"
            :disabled="cargando"
            @click="_doUpload"
            v-text="'Subir imagen'"
            class="primary"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialogFullFoto">
      <v-card>
        <v-card-title>
          <v-btn
            icon
            @click="showDialogFullFoto=false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          {{paciente.nombre_pac+' '+paciente.ape_pat_pac+' '+paciente.ape_mat_pac}}
        </v-card-title>
        <v-img
          aspect-ratio="1"
          :src="$raiz + 'uploads/imagenes/' + paciente.foto_pac"
        ></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import CarritoDialog from "@/components/carrito/CarritoDialog.vue";
export default {
  name: "PacientesInfoGeneral",
  mixins: [forms],
  components: { CarritoDialog },
  props: {
    id: String, //id del paciente
    showEdit: Boolean,
    showMore: Boolean,
  },
  data() {
    return {
      paciente: {},
      idPac: null,
      showDialogFotoPerfil: false,
      showDialogFullFoto: false,
      archivo: null,
    };
  },
  methods: {
    _getPaciente() {
      if (this.id) {
        this._getThings("pacientes/" + this.id, "paciente");
      }
    },
    async _doUpload() {
      this.cargando = true;
      try {
        let data = new FormData();
        data.append("imagen", this.archivo);
        const { status, body } = await this.$http.post(
          "pacientes/foto/" + this.id,
          data,
          this.$headers
        );
        this.cargando = false;
        if ("msg" in body) {
          this.$emit("msg", body.msg);
          this.showDialogFotoPerfil = false;
          this._getPaciente();
        } else {
          this.$emit("msg", body.error);
        }
      } catch (err) {
        this.$emit("httpError", err);
        this.cargando = false;
      }
    },
  },
  created() {
    this._getPaciente();
  },
  computed: {
    edad() {
      var fecha = this.$Datetime.fromISO(this.paciente.fecha_nac_pac);
      var { years } = this.$Datetime.local().diff(fecha, "years").toObject();
      return Math.floor(years);
    },
  },
  watch: {
    id(a) {
      this._getPaciente();
    },
  },
};
</script>