<template>
  <div class="mb24">
    <v-autocomplete
      class="AutocompletePacientes"
      v-model="idPac"
      append-icon="search"
      clearable
      item-value="id_pac"
      :items="pacientes"
      item-text="paciente"
      label="Buscar paciente"
      :loading="cargando"
      no-filter
      filled
      :search-input.sync="buscar"
    >
      <template v-slot:item="data">
        <v-list-item-content dense>
          <v-list-item-title v-text="data.item.paciente" />
          <v-list-item-subtitle v-text="data.item.fecha_nac_pac" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <span>
      <router-link
        to="/paciente"
        target="_blank"
      >Ir y registrar al paciente</router-link>
    </span>
  </div>
</template>
<script>
export default {
  name: "AutocompletePacientes",
  props: {
    value: String,
  },
  data() {
    return {
      cargando: false,
      cie10: [],
      idPac: null,
      buscar: null,
      primerBusqueda: true,
      pacientes: [],
      error: String,
    };
  },
  methods: {
    _getPac(a = "") {
      this.cargando = true;
      this.$http.post("pacientes/buscar", { paciente: a }, this.$headers).then(
        (res) => {
          this.cargando = false;
          if (res.status === 200 && "pacientes" in res.body) {
            this.pacientes = res.body.pacientes;
          } else {
            this.$emit("httpError", res);
          }
        },
        (err) => {
          this.cargando = false;
          this.$emit("httpError", err);
        }
      );
    },
  },
  watch: {
    buscar(a) {
      if (a && a != "" && a != " ") {
        this._getPac(a);
      }
    },
    value(a) {
      this.idPac = a;
    },
    idPac(a) {
      if (parseInt(a) >= 1 && a != this.value) {
        this.$emit("input", a);
        this.$emit("change", a);
      }
    },
    diagnostico(a) {
      if (a != this.idPac) {
        this.idPac = a;
      }
    },
  },
};
</script>