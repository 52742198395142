<template>
  <div class="DepositoCategoriaFormDialog">
    <v-dialog
      v-model="showDialog"
      persistent
      scrollable
      width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          dark
          class="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-text="'add'" />
        </v-btn>
      </template>
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title>{{categoria.id ? 'Editar':'Registrar'}} caetgoría</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="categoria.nombre_dca"
            :error-messages="errors.nombre_dca"
            label="Nombre*"
            filled
          />
          <v-text-field
            v-model="categoria.descripcion_dca"
            :error-messages="errors.descripcion_dca"
            label="Descripcion*"
            filled
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cancelar'"
            @click="showDialog=false"
          />
          <v-btn
            class="primary"
            v-text="categoria.id ? 'Actualizar':'Guardar'"
            @click="_saveUpdateCategoria"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "DepositoCategoriaFormDialog",
  props: {
    value: [String, Number],
  },
  mixins: [forms],
  data() {
    return {
      categoria: {
        id: null,
        id_dca: null,
        nombre_dca: null,
        descripcion_dca: null,
      },
      errors: {
        nombre_dca: null,
      },
      showDialog: false,
    };
  },
  methods: {
    _getCategoria() {
      if (this.value) {
        this._getThings("depositodental/categorias/" + this.value, "categoria");
      }
    },
    _saveUpdateCategoria() {
      if (this._validateAll("categoria")) {
        const data = { categoria: this.categoria };
        this.categoria.id
          ? this._updateAll(
              "depositodental/categorias/" + this.value,
              data,
              "msg",
              "_handleResponseCategoria"
            )
          : this._saveAll(
              "depositodental/categorias",
              data,
              "id",
              "_handleResponseCategoria"
            );
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _handleResponseCategoria(res) {
      this.$emit("msg", res.msg);
      this.$emit("refresh", Date.now());
      this.showDialog = false;
    },
  },
  watch: {
    showDialog(a) {
      if (!a) {
        this.$emit("input", null);
        this._limpiarCampos("categoria");
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler: "_getCategoria",
    },
    categoria(a) {
      if (a.id) {
        this.showDialog = true;
      }
    },
  },
};
</script>