<template>
  <div>
    <v-autocomplete
      class="AutocompleteCie10"
      v-model="idCie"
      append-icon="search"
      clearable
      :error-messages="error"
      item-value="id_cie"
      item-text="nombre"
      :items="cie10"
      :label="label ? label : 'Buscar diagnóstico CIE10'"
      :loading="cargando"
      no-filter
      filled
      :search-input.sync="buscar"
      :disabled="disabled"
    >
      <template v-slot:item="data">
        <v-list-item-content dense>
          <v-list-item-title v-text="data.item.nombre" />
          <v-list-item-subtitle v-text="data.item.epi_clave_desc" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
export default {
  name: "AutocompleteCie10",
  props: {
    value: String,
    isSelect: Boolean,
    label: String,
    error: String,
    disabled: Boolean,
    diagnostico: String,
  },
  data() {
    return {
      cargando: false,
      cie10: [],
      idCie: null,
      buscar: null,
      primerBusqueda: true,
    };
  },
  methods: {
    _getCie10(a = "") {
      this.cargando = true;
      this.$http.get("data/cie10/?search=" + a).then(
        (res) => {
          this.cargando = false;
          if (res.status === 200 && "cie10" in res.body) {
            this.cie10 = res.body.cie10;
          } else {
            this.$emit("httpError", res);
          }
        },
        (err) => {
          this.cargando = false;
          this.$emit("httpError", err);
        }
      );
    },
  },
  created() {
    this._getCie10();
    if (this.diagnostico) {
      this.idCie = this.diagnostico;
    }
  },
  watch: {
    buscar(a) {
      if (a && a != "" && a != " ") {
        this._getCie10(a);
      }
    },
    idCie(a) {
      if (parseInt(a) >= 1 && a != this.value) {
        this.$emit("input", a);
        this.$emit("change");
      }
    },
    diagnostico(a) {
      if (a != this.idCie) {
        this.idCie = a;
      }
    },
  },
};
</script>