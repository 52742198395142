var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"servicios"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.cargando,"disabled":_vm.cargando}},[_c('v-card-title',{domProps:{"textContent":_vm._s((_vm.servicio.id ? 'Editar' : 'Agregar') + ' servicio')}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":"Servicio*","error-messages":_vm.errors.desc_ser},model:{value:(_vm.servicio.desc_ser),callback:function ($$v) {_vm.$set(_vm.servicio, "desc_ser", $$v)},expression:"servicio.desc_ser"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-text-field',{attrs:{"filled":"","label":"Precio*","error-messages":_vm.errors.precio_ser},model:{value:(_vm.servicio.precio_ser),callback:function ($$v) {_vm.$set(_vm.servicio, "precio_ser", $$v)},expression:"servicio.precio_ser"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-select',{attrs:{"filled":"","label":"Tipo*","error-messages":_vm.errors.tipo_ser,"items":[
                { text: 'Producto', value: 'producto' },
                { text: 'Servicio', value: 'servicio' } ]},model:{value:(_vm.servicio.tipo_ser),callback:function ($$v) {_vm.$set(_vm.servicio, "tipo_ser", $$v)},expression:"servicio.tipo_ser"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-select',{attrs:{"filled":"","label":"¿Puede ser motivo cita?*","error-messages":_vm.errors.para_cita_ser,"items":[
                { text: 'Si', value: 'si' },
                { text: 'No', value: 'no' } ]},model:{value:(_vm.servicio.para_cita_ser),callback:function ($$v) {_vm.$set(_vm.servicio, "para_cita_ser", $$v)},expression:"servicio.para_cita_ser"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-select',{attrs:{"filled":"","label":"Horas","items":[
                    { text: '0 horas', value: 0 },
                    { text: '1 horas', value: 1 },
                    { text: '2 horas', value: 2 },
                    { text: '3 horas', value: 3 },
                    { text: '4 horas', value: 4 },
                    { text: '5 horas', value: 5 },
                    { text: '6 horas', value: 5 } ],"hint":"Duración aprox. del servicio","persistent-hint":""},on:{"change":_vm._calcTimeService},model:{value:(_vm.duracionHoras),callback:function ($$v) {_vm.duracionHoras=$$v},expression:"duracionHoras"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-select',{attrs:{"filled":"","label":"Minutos","items":[
                    { text: '0 minutos', value: 0 },
                    { text: '10 minutos', value: 10 },
                    { text: '20 minutos', value: 20 },
                    { text: '30 minutos', value: 30 },
                    { text: '40 minutos', value: 40 },
                    { text: '50 minutos', value: 50 } ],"hint":"Duración aprox. del servicio","persistent-hint":""},on:{"change":_vm._calcTimeService},model:{value:(_vm.duracionMinutos),callback:function ($$v) {_vm.duracionMinutos=$$v},expression:"duracionMinutos"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-select',{attrs:{"filled":"","label":"Garantía*","error-messages":_vm.errors.garantia_ser,"items":[
                { text: 'Sin garantía', value: 'no' },
                { text: '1 mes', value: '1' },
                { text: '2 meses', value: '2' },
                { text: '3 meses', value: '3' },
                { text: '4 meses', value: '4' },
                { text: '6 meses', value: '6' },
                { text: '12 meses', value: '12' } ]},model:{value:(_vm.servicio.garantia_ser),callback:function ($$v) {_vm.$set(_vm.servicio, "garantia_ser", $$v)},expression:"servicio.garantia_ser"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-select',{attrs:{"filled":"","label":"Estatus*","error-messages":_vm.errors.estatus_ser,"items":[
                { text: 'Activo', value: 'activo' },
                { text: 'Inactivo', value: 'inactivo' } ]},model:{value:(_vm.servicio.estatus_ser),callback:function ($$v) {_vm.$set(_vm.servicio, "estatus_ser", $$v)},expression:"servicio.estatus_ser"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","to":"/servicios"},domProps:{"textContent":_vm._s('Cancelar')}}),_c('v-btn',{staticClass:"primary",domProps:{"textContent":_vm._s(_vm.servicio.id ? 'Actualizar' : 'Guardar')},on:{"click":_vm._preSaveServicio}})],1)],1)],1),(_vm.servicio.id)?_c('v-row',{staticClass:"mt24"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4"}})],1):_vm._e(),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{domProps:{"textContent":_vm._s('¿Que deseas hacer a continuación?')}}),_c('v-list-item',{attrs:{"to":"/"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Ir a inicio')}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":"/servicios"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Ir a servicios')}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.showDialog = false}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Continuar en la información de servicio')}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }