<template>
  <div class="DepositoDentalFormDialog">
    <v-dialog
      v-model="showDialog"
      persistent
      scrollable
      width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          dark
          class="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-text="'add'" />
        </v-btn>
      </template>
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title>{{deposito.id ? 'Editar':'Registrar'}} caetgoría</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="deposito.nombre_dep"
            :error-messages="errors.nombre_dep"
            label="Nombre*"
            filled
          />
          <v-select
            label="Categoria*"
            :items="categorias"
            item-text="nombre_dca"
            item-value="id_dca"
            filled
            v-model="deposito.id_dca_dep"
          />
          <v-text-field
            v-model="deposito.precio_dep"
            :error-messages="errors.precio_dep"
            number
            min="0"
            label="Desde*"
            filled
            prepend-inner-icon="attach_money"
          />
          <v-text-field
            v-model="deposito.descripcion_dep"
            :error-messages="errors.descripcion_dep"
            label="Descripcion*"
            filled
          />
          <!-- <v-file-input
            filled
            label="Fotografía del material"
            accept="image/jpeg, image/png, image/jpg"
            prepend-icon=""
            prepend-inner-icon="attach_file"
            v-model="archivo"
          /> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cancelar'"
            @click="showDialog=false"
          />
          <v-btn
            class="primary"
            v-text="deposito.id ? 'Actualizar':'Guardar'"
            @click="_saveUpdateDeposito"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "DepositoDentalFormDialog",
  props: {
    value: [String, Number],
  },
  mixins: [forms],
  data() {
    return {
      deposito: {
        id: null,
        id_dep: null,
        id_dca_dep: null,
        nombre_dep: null,
        descripcion_dep: null,
        precio_dep: null,
      },
      errors: {
        id_dca_dep: null,
        nombre_dep: null,
        precio_dep: null,
      },
      showDialog: false,
      categorias: [],
      archivo: null,
    };
  },
  methods: {
    _getDeposito() {
      if (this.value) {
        this._getThings("depositodental/depositos/" + this.value, "deposito");
      }
    },
    _saveUpdateDeposito() {
      if (this._validateAll("deposito")) {
        let data = new FormData();
        data.append("imagen", this.archivo);
        data.append("material", JSON.stringify(this.deposito));
        const id = this.deposito.id ? this.deposito.id : "";
        this._saveAll(
          "depositodental/depositos/" + id,
          data,
          "msg",
          "_handleResponseDeposito"
        );
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _handleResponseDeposito(res) {
      this.$emit("msg", res.msg);
      this.$emit("refresh", Date.now());
      this.showDialog = false;
    },
  },
  created() {
    this._getThings("depositodental/categorias", "categorias");
  },
  watch: {
    showDialog(a) {
      if (!a) {
        this.$emit("input", null);
        this._limpiarCampos("deposito");
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler: "_getDeposito",
    },
    deposito(a) {
      if (a.id) this.showDialog = true;
    },
  },
};
</script>