<template>
  <div>
    <v-select
      v-model="valor"
      class="TimePicker"
      :items="valuesArr"
      :label="label ? label : 'Seleccione una hora'"
      filled
      @change="$emit('input',$event);$emit('change',$event)"
      :prepend-inner-icon="icon ? icon : 'schedule'"
      :disabled="disabled"
      :error-messages="error"
    />
  </div>
</template>
<script>
export default {
  name: "TimePicker",
  props: {
    value: [String, Number],
    horaInicio: [Number, String],
    horaFin: [Number, String],
    label: String,
    value: String,
    icon: String,
    error: String,
    disabled: Boolean,
  },
  data() {
    return {
      valor: null,
      valuesArr: [],
    };
  },
  methods: {
    _createHourArr() {
      const horaInicio = parseInt(this.horaInicio);
      const horaFin = parseInt(this.horaFin);
      let inicio = horaInicio >= 0 && horaInicio <= 22 ? horaInicio : 0;
      let fin = horaFin >= 0 && horaFin <= 23 ? horaFin : 23;
      for (let index = inicio; index < fin + 1; index++) {
        let n = index.toString().padStart(2, "0");
        this.valuesArr.push({
          value: n + ":00:00",
          text: n + ":00",
        });
        if (index != fin) {
          this.valuesArr.push({
            value: n + ":30:00",
            text: n + ":30",
          });
        }
      }
    },
  },
  created() {
    this.valor = this.value;
    this._createHourArr();
  },
  watch: {
    value(a) {
      this.valor = a;
    },
    horaInicio(a) {
      this._createHourArr();
    },
    horaFin(a) {
      this._createHourArr();
    },
  },
};
</script>