export default {
    data() {
        return {
            cargando: false,
            search: "",
            showModalNextAction: false,
            statusFemenino: [{ value: 'activa', text: 'Activa' }, { value: 'inactiva', text: 'Inactiva' }],
            statusMasculino: [{ value: 'activo', text: 'Activo' }, { value: 'inactivo', text: 'Inactivo' }]
        }
    },
    components: {
        ButtonAdd: () => import("@/components/forms/buttons/ButtonAdd.vue"),
    },
    methods: {
        _handleError(err) {
            this.$emit("httpError", err);
            this.cargando = false;
        },
        async _getAll(whatToGet = null, params = '', nextAction = null) {
            if (whatToGet) {
                this.cargando = true;
                try {
                    const { status, body } = await this.$http.get(
                        whatToGet + params,
                        this.$headers
                    );
                    if (status === 200 && whatToGet in body) {
                        this[whatToGet] = body[whatToGet];
                        if (nextAction) {
                            this[nextAction](body)
                        }
                    } else {
                        this.$emit("httpError", res);
                    }
                    this.cargando = false;
                } catch (err) {
                    this._handleError(err);
                }
            }
        },
    },
    beforeCreate() {
        if (!this.$session.exists() && !this.$session.get("tipo")) {
            this.$router.push("/login");
        } else {
            this.$emit("visible", true);
            this.$emit("classes", "juno-container");
        }
    }
}