<template>
  <div class="OdontogramaForm">
    <v-dialog
      v-model="showDialogForm"
      fullscreen
      scrollable
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title v-text="'Registrar cambios en el odontograma'" />
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <h2 class="mb8">Superior Izquierdo</h2>
              <div
                v-for="n in 8"
                :key="n"
              >
                <v-text-field
                  class="mt24"
                  :label="'1' + n"
                  v-model="odontograma['si' + n + '_odo']"
                  filled
                  append-icon="add_shopping_cart"
                  @click:append="dienteToAdd = 'si' + n"
                />
                <span
                  v-for="(ood,idxood) in odontoCart['si' + n]"
                  :key="idxood"
                >
                  {{ood.cant_sca}} x {{ood.desc_ser}}: {{$nF(ood.cant_sca*ood.precio_sca)}}
                  {{idxood+1>=odontoCart['si' + n].length? '':','}}
                </span>
                <v-divider></v-divider>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <h2 class="mb8">Superior Derecho</h2>
              <div
                v-for="n in 8"
                :key="n"
              >
                <v-text-field
                  class="mt24"
                  :label="'2' + n"
                  v-model="odontograma['sd' + n + '_odo']"
                  filled
                  append-icon="add_shopping_cart"
                  @click:append="dienteToAdd = 'sd' + n"
                />
                <span
                  v-for="(ood,idxood) in odontoCart['sd' + n]"
                  :key="idxood"
                >
                  {{ood.cant_sca}} x {{ood.desc_ser}}: {{$nF(ood.cant_sca*ood.precio_sca)}}
                  {{idxood+1>=odontoCart['sd' + n].length? '':','}}
                </span>
                <v-divider></v-divider>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <h2 class="mb8">Inferior izquierdo</h2>
              <div
                v-for="n in 8"
                :key="n"
              >
                <v-text-field
                  class="mt24"
                  :label="'4' + n"
                  v-model="odontograma['ii' + n + '_odo']"
                  filled
                  append-icon="add_shopping_cart"
                  @click:append="dienteToAdd = 'ii' + n"
                />
                <span
                  v-for="(ood,idxood) in odontoCart['ii' + n]"
                  :key="idxood"
                >
                  {{ood.cant_sca}} x {{ood.desc_ser}}: {{$nF(ood.cant_sca*ood.precio_sca)}}
                  {{idxood+1>=odontoCart['ii' + n].length? '':','}}
                </span>
                <v-divider></v-divider>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <h2 class="mb8">Inferior derecho</h2>
              <div
                v-for="n in 8"
                :key="n"
              >
                <v-text-field
                  class="mt24"
                  :label="'3' + n"
                  v-model="odontograma['id' + n + '_odo']"
                  filled
                  append-icon="add_shopping_cart"
                  @click:append="dienteToAdd = 'id' + n"
                />
                <span
                  v-for="(ood,idxood) in odontoCart['id' + n]"
                  :key="idxood"
                >
                  {{ood.cant_sca}} x {{ood.desc_ser}}: {{$nF(ood.cant_sca*ood.precio_sca)}}
                  {{idxood+1>=odontoCart['id' + n].length? '':','}}
                </span>
                <v-divider></v-divider>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cancelar'"
            @click="showDialogForm = false"
          />
          <v-btn
            class="primary"
            v-text="'Guardar'"
            @click="_checkHowToSaveOdontograma"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="400"
      v-model="showDialogAddToCart"
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title v-text="'Añadir servicios al diente'" />
        <v-card-text>
          <autocomplete-servicios
            v-model="cart.id_ser_sca"
            @change="_handleServicioChange"
          />
          <v-text-field
            filled
            label="Cantidad"
            v-model="cart.cant_sca"
            type="number"
            min="1"
          />

          <v-text-field
            filled
            label="Precio unitario"
            prepend-inner-icon="attach_money"
            v-model="cart.precio_sca"
          />
          <v-btn
            class="primary"
            @click="_addCarritoDiente"
          >
            <v-icon>add</v-icon>Agregar
          </v-btn>
        </v-card-text>
        <v-list v-if="odontoCart[dienteToAdd] && odontoCart[dienteToAdd].length>=1">
          <div
            v-for="(od,idxo) in odontoCart[dienteToAdd]"
            :key="idxo"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title v-text="od.desc_ser+' '+$nF(od.cant_sca*od.precio_sca)" />
                <v-list-item-subtitle v-text="'Cantidad: '+od.cant_sca" />
                <v-list-item-subtitle v-text="'Precio unitario: '+$nF(od.precio_sca)" />
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="_deleteCarrito(idxo)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cerrar'"
            @click="showDialogAddToCart=false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="400"
      v-model="showDialogConfirmCart"
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title>¿Está correcto el carrito?</v-card-title>
        <v-list>
          <div
            v-for="(fc,idxfc) in finalCart"
            :key="idxfc"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title v-text="fc.cant_sca+' x '+fc.desc_ser" />
              </v-list-item-content>
              <v-list-item-action-text v-text="$nF(fc.cant_sca*fc.precio_sca)" />
            </v-list-item>
            <v-divider />
          </div>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <strong> Total: {{$nF(total)}} </strong>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'No'"
            @click="showDialogConfirmCart=false"
          />
          <v-btn
            class="primary"
            v-text="'Si, guardar'"
            @click="_guardarOdontograma"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import AutocompleteServicios from "@/components/forms/autocompletes/AutocompleteServicios.vue";
export default {
  name: "OdontogramaForm",
  mixins: [forms],
  components: { AutocompleteServicios },
  props: {
    value: Boolean,
    paciente: String,
  },
  data() {
    return {
      odontograma: {
        id_odo: null,
        id_pac_odo: null,
        id_usr_odo: null,
        fecha_odo: null,
      },
      odontoCart: [],
      finalCart: [],
      showDialogForm: false,
      showDialogAddToCart: false,
      showDialogConfirmCart: false,
      cart: {
        id_pac_sca: null,
        id_ser_sca: null,
        cant_sca: null,
        precio_sca: null,
        desc_ser: null,
      },
      dienteToAdd: null,
    };
  },
  methods: {
    _checkHowToSaveOdontograma() {
      this.finalCart.length >= 1
        ? (this.showDialogConfirmCart = true)
        : this._guardarOdontograma();
    },
    _guardarOdontograma() {
      this.odontograma.id_pac_odo = this.paciente;
      const data = { odontograma: this.odontograma, carrito: this.finalCart };
      this._saveAll("odontogramas", data, "msg", "_handleOdontogramaResponse");
    },
    _handleOdontogramaResponse(res) {
      this.$emit("updated", true);
      this.showDialogForm = false;
      this.showDialogAddToCart = false;
      this.showDialogConfirmCart = false;
      this.$emit("input", false);
      this.$emit("msg", res.msg);
    },
    _createCleanOdontograma() {
      for (let index = 0; index < 8; index++) {
        const i = index + 1;
        this.odontograma["si" + i + "_odo"] = null;
        this.odontograma["si" + i + "_cart_odo"] = null;
        this.odontoCart["si" + i] = [];
        this.odontograma["sd" + i + "_odo"] = null;
        this.odontograma["sd" + i + "_cart_odo"] = null;
        this.odontoCart["sd" + i] = [];
        this.odontograma["ii" + i + "_odo"] = null;
        this.odontograma["ii" + i + "_cart_odo"] = null;
        this.odontoCart["ii" + i] = [];
        this.odontograma["id" + i + "_odo"] = null;
        this.odontograma["id" + i + "_cart_odo"] = null;
        this.odontoCart["id" + i] = [];
      }
    },
    _handleServicioChange(a) {
      this.cart.cant_sca = 1;
      this.cart.precio_sca = a.precio_ser;
      this.cart.desc_ser = a.desc_ser;
    },
    _addCarritoDiente() {
      let err = null;
      if (!this.cart.id_ser_sca)
        err = "Selecciona un servicio para agregar al carrito";
      if (!this.cart.cant_sca && this.cart.cant_sca < 1)
        err = "La cantidad debe ser por lo menos 1";
      if (!this.cart.precio_sca && this.cart.precio_sca <= 0)
        err = "El precio no puede ser negativo";
      if (err) {
        this.$emit("msg", err);
        return false;
      }
      this.odontoCart[this.dienteToAdd].push({
        id_pac_sca: this.cart.id_pac_sca,
        id_ser_sca: this.cart.id_ser_sca,
        cant_sca: this.cart.cant_sca,
        precio_sca: this.cart.precio_sca,
        desc_ser: this.cart.desc_ser,
      });
      this._getTotalCart();
      this.$emit("msg", "Servicio añadido al diente");
      this._limpiarCart();
    },
    _limpiarCart() {
      Object.keys(this.cart).forEach((e) => {
        this.cart[e] = null;
      });
    },
    _deleteCarrito(idx) {
      this.odontoCart[this.dienteToAdd].splice(idx, 1);
      this._getTotalCart();
      this.$emit("msg", "Servicio eliminado");
      this._limpiarCart();
    },
    _getTotalCart() {
      let cartToAdd = [];
      Object.keys(this.odontoCart).forEach((e) => {
        if (this.odontoCart[e].length >= 1)
          cartToAdd = cartToAdd.concat(this.odontoCart[e]);
      });
      this.finalCart = cartToAdd;
    },
  },
  created() {
    this._createCleanOdontograma();
  },
  watch: {
    value(a) {
      this.showDialogForm = a;
      if (!a) {
        this._limpiarCampos;
        this._createCleanOdontograma();
      } else {
        this._getThings("odontograma/latest/" + this.paciente, "odontograma");
      }
    },
    showDialogAddToCart(a) {
      if (!a) {
        this.dienteToAdd = null;
        this._limpiarCart();
      }
    },
    dienteToAdd(a) {
      if (a) this.showDialogAddToCart = true;
    },
    showDialogForm(a) {
      this.$emit("input", a);
    },
  },
  computed: {
    total() {
      let subtotal = 0;
      this.finalCart.forEach((fc) => {
        subtotal = subtotal + fc.cant_sca * fc.precio_sca;
      });
      console.log("subtotal: " + subtotal);
      return subtotal;
    },
  },
};
</script>