<template>
  <div class="PacientesForm">
    <v-card
      outlined
      :loading="cargando"
      :disabled="cargando"
    >
      <v-card-title>{{ paciente.id ? "Editar" : "Registrar" }} paciente</v-card-title>
      <v-card-text>
        Información general
        <v-row class="mb4">
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="3"
          >
            <v-text-field
              label="Nombre"
              v-model="paciente.nombre_pac"
              :error-messages="errors.nombre_pac"
              filled
              @blur="_checkIfPacienteDuplicado"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              label="Apellido paterno"
              v-model="paciente.ape_pat_pac"
              :error-messages="errors.ape_pat_pac"
              filled
              @blur="_checkIfPacienteDuplicado"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              label="Apellido materno"
              v-model="paciente.ape_mat_pac"
              :error-messages="errors.ape_mat_pac"
              filled
              @blur="_checkIfPacienteDuplicado"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
          >
            <v-select
              label="Género"
              v-model="paciente.genero_pac"
              :error-messages="errors.genero_pac"
              :items="[
                { text: 'Femenino', value: 'femenino' },
                { text: 'Masculino', value: 'fasculino' },
                { text: 'Otro', value: 'ftro' },
                {
                  text: 'Prefiero no especificar',
                  value: 'prefiero no especificar',
                },
              ]"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
          >
            <DatePicker
              label="Fecha de nacimiento"
              v-model="paciente.fecha_nac_pac"
              :error="errors.fecha_nac_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="3"
          >
            <v-text-field
              label="Lugar de nacimiento"
              v-model="paciente.lugar_nac_pac"
              :error-messages="errors.lugar_nac_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
          >
            <v-select
              label="Estado cívil"
              :items="[
                { text: 'Soltera/o', value: 'soltero' },
                { text: 'Casada/o', value: 'casado' },
              ]"
              v-model="paciente.edo_civil_pac"
              :error-messages="errors.edo_civil_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
          >
            <v-select
              :items="escolaridad"
              label="Escolaridad"
              v-model="paciente.escolaridad_pac"
              :error-messages="errors.escolaridad_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
          >
            <v-text-field
              label="Ocupación"
              v-model="paciente.ocupacion_pac"
              :error-messages="errors.ocupacion_pac"
              filled
            />
          </v-col>
        </v-row>
        <div v-if="edad < 18">
          Información de un padre de familia o tutor del menor de edad
          <v-row class="mb4">
            <v-col
              cols="12"
              sm="6"
              md="3"
              lg="3"
            >
              <v-text-field
                label="Nombre del tutor"
                v-model="paciente.nombre_tutor_pac"
                :error-messages="errorsMenor.nombre_tutor_pac"
                filled
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              lg="3"
            >
              <v-select
                label="Relación con el paciente"
                v-model="paciente.tipo_tutor_pac"
                :error-messages="errorsMenor.tipo_tutor_pac"
                :items="[
                  'Padre',
                  'Madre',
                  'Hermana(o)',
                  'Tia(o)',
                  'Abuela(o)',
                  'Tutor(a)',
                  'Otro',
                ]"
                filled
              />
            </v-col>
          </v-row>
        </div>
        Información de ubicación geográfica
        <v-row class="mb4">
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="3"
          >
            <AutocompleteDataEstados
              label="Seleccionar estado"
              v-model="paciente.id_edo_pac"
              :error="errors.id_edo_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="3"
          >
            <AutocompleteDataMunicipios
              label="Seleccionar municipio"
              :estado="paciente.id_edo_pac"
              v-model="paciente.id_mpio_pac"
              :error="errors.id_mpio_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-text-field
              label="Calle"
              v-model="paciente.calle_pac"
              :error-messages="errors.calle_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="2"
            lg="1"
          >
            <v-text-field
              label="# int."
              v-model="paciente.num_int_pac"
              :error-messages="errors.num_int_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="2"
            lg="1"
          >
            <v-text-field
              label="# ext."
              v-model="paciente.num_ext_pac"
              :error-messages="errors.num_ext_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-text-field
              label="Colonia"
              v-model="paciente.colonia_pac"
              :error-messages="errors.colonia_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="2"
            lg="2"
          >
            <v-text-field
              label="C.P."
              v-model="paciente.cp_pac"
              :error-messages="errors.cp_pac"
              filled
            />
          </v-col>
        </v-row>
        Información de contacto
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-text-field
              label="Teléfono fijo"
              v-model="paciente.tel_pac"
              :error-messages="errors.tel_pac"
              v-mask="'### ### ####'"
              filled
              hint="Recuerda que con el teléfono fijo el paciente podrá ingresar desde su portal"
              persistent-hint
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-text-field
              label="Celular"
              v-model="paciente.cel_pac"
              :error-messages="errors.cel_pac"
              v-mask="'### ### ####'"
              filled
              hint="Recuerda que con el teléfono celular el paciente podrá ingresar desde su portal"
              persistent-hint
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-text-field
              label="Email"
              v-model="paciente.email_pac"
              :error-messages="errors.email_pac"
              filled
              hint="Recuerda que con el correo electrónico el paciente podrá ingresar desde su portal"
              persistent-hint
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          v-text="'Cancelar'"
          to="/pacientes"
        />
        <v-btn
          class="primary"
          v-text="paciente.id ? 'Actualizar' : 'Guardar'"
          @click="_preSavePac"
        />
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="showDialogPacientes"
      width="700"
      persistent
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title v-text="'Parece encontramos un paciente similar al que estas registrando'" />
        <v-list>
          <div
            v-for="pp in pacientesDuplicados"
            :key="pp.id"
          >
            <v-list-item :to="'/paciente/historiaclinica/' + pp.id">
              <v-list-item-content>
                <v-list-item-title v-text="pp.nombre_pac+' '+pp.ape_pat_pac+' '+pp.ape_mat_pac" />
                <v-list-item-subtitle v-text="_doDiaMes(pp.fecha_nac_pac)" />
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  :to="'/paciente/historiaclinica/' + pp.id"
                >
                  <v-icon>chevron_right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cerrar'"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
import AutocompleteDataEstados from "@/components/forms/autocompletes/AutocompleteDataEstados.vue";
import AutocompleteDataMunicipios from "@/components/forms/autocompletes/AutocompleteDataMunicipios.vue";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";

export default {
  name: "PacientesForm",
  mixins: [standard, forms, dates],
  components: {
    AutocompleteDataEstados,
    AutocompleteDataMunicipios,
    DatePicker,
  },
  data() {
    return {
      idTest: null,
      showDialogPacientes: false,
      paciente: {
        id: null,
        id_pac: null,
        nombre_pac: null,
        ape_pat_pac: null,
        ape_mat_pac: null,
        genero_pac: null,
        fecha_nac_pac: null,
        lugar_nac_pac: null,
        ocupacion_pac: null,
        escolaridad_pac: null,
        edo_civil_pac: null,
        cel_pac: null,
        tel_pac: null,
        id_edo_pac: null,
        id_mpio_pac: null,
        calle_pac: null,
        num_int_pac: null,
        num_ext_pac: null,
        colonia_pac: null,
        cp_pac: null,
        email_pac: null,
        nombre_tutor_pac: null,
        tipo_tutor_pac: null,
      },
      errors: {
        nombre_pac: null,
        ape_pat_pac: null,
        // ape_mat_pac: null,
        genero_pac: null,
        fecha_nac_pac: null,
        cel_pac: null,
        id_edo_pac: null,
        id_mpio_pac: null,
        // calle_pac: null,
        // colonia_pac: null,
      },
      errorsMenor: {
        nombre_tutor_pac: null,
        tipo_tutor_pac: null,
      },
      escolaridad: [
        { text: "Preescolar", value: "preescolar" },
        { text: "Primaria", value: "primaria" },
        { text: "Secundaria", value: "secundaria" },
        { text: "Preparatoria", value: "preparatoria" },
        { text: "Licenciatura", value: "licenciatura" },
        { text: "Maestria", value: "maestria" },
        { text: "Posgrado", value: "posgrado" },
        { text: "Otro", value: "otro" },
        { text: "Ninguno", value: "ninguno" },
      ],
      pacientesDuplicados: [],
    };
  },
  methods: {
    _preSavePac() {
      if (this._validateAll("paciente") && this._validateIfIsMinor()) {
        const data = { paciente: this.paciente };
        const aa = "_redirectToExpediente";
        this.paciente.id
          ? this._updateAll("pacientes/" + this.paciente.id, data, "msg", aa)
          : this._saveAll("pacientes", data, "id", aa);
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _validateIfIsMinor() {
      if (this.edad < 18) {
        this.errorsMenor.tipo_tutor_pac = this._hasError(
          this.paciente.tipo_tutor_pac
        );
        this.errorsMenor.nombre_tutor_pac = this._hasError(
          this.paciente.nombre_tutor_pac
        );
        return this.errorsMenor.tipo_tutor_pac ||
          this.errorsMenor.nombre_tutor_pac
          ? false
          : true;
      } else {
        return true;
      }
    },
    _redirectToExpediente(res) {
      //el parametro res se recibe automaticamente desde el mixin forms en la funcion _handleResponse
      const id = "id" in res ? res.id : this.paciente.id;
      this.$router.push("/paciente/historiaclinica/" + id);
      this.$emit("msg", res.msg);
    },
    async _checkIfPacienteDuplicado() {
      if (!this.paciente.id) {
        const n = this.paciente.nombre_pac ? 1 : 0;
        const ap = this.paciente.ape_pat_pac ? 1 : 0;
        const am = this.paciente.ape_mat_pac ? 1 : 0;
        console.log(suma);
        if (n + ap + am >= 2) {
          this.cargando = true;
          try {
            const { body } = await this.$http.post(
              "pacientes/buscar",
              { paciente: this.paciente },
              this.$headers
            );
            this.cargando = false;
            console.log("check if is fine");
            if ("pacientes" in body && body.pacientes.length >= 1) {
              console.log("inside if pacientes");
              this.showDialogPacientes = true;
              this.pacientesDuplicados = body.pacientes;
            } else {
              this.pacientesDuplicados = [];
            }
          } catch (err) {
            this.cargando = false;
          }
        }
      }
    },
  },
  created() {
    if (this.$route.params.idPac) {
      this._getThings("pacientes/" + this.$route.params.idPac, "paciente");
    }
  },
  computed: {
    edad() {
      if (this.paciente.fecha_nac_pac) {
        var fecha = this.$Datetime.fromISO(this.paciente.fecha_nac_pac);
        var { years } = this.$Datetime.local().diff(fecha, "years").toObject();
        return Math.round(years);
      } else {
        return 100;
      }
    },
  },
};
</script>
