<template>
  <div>
    <div id="servicios">
      <v-card
        outlined
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title v-text="(servicio.id ? 'Editar' : 'Agregar') + ' servicio'" />
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
            >
              <v-text-field
                filled
                label="Servicio*"
                v-model="servicio.desc_ser"
                :error-messages="errors.desc_ser"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              lg="3"
            >
              <v-text-field
                filled
                label="Precio*"
                v-model="servicio.precio_ser"
                :error-messages="errors.precio_ser"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              lg="3"
            >
              <v-select
                filled
                label="Tipo*"
                v-model="servicio.tipo_ser"
                :error-messages="errors.tipo_ser"
                :items="[
                  { text: 'Producto', value: 'producto' },
                  { text: 'Servicio', value: 'servicio' },
                ]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              lg="3"
            >
              <v-select
                filled
                label="¿Puede ser motivo cita?*"
                v-model="servicio.para_cita_ser"
                :error-messages="errors.para_cita_ser"
                :items="[
                  { text: 'Si', value: 'si' },
                  { text: 'No', value: 'no' },
                ]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              lg="3"
            >
              <v-row>
                <v-col
                  cols="6"
                  sm="6"
                >
                  <v-select
                    filled
                    label="Horas"
                    :items="[
                      { text: '0 horas', value: 0 },
                      { text: '1 horas', value: 1 },
                      { text: '2 horas', value: 2 },
                      { text: '3 horas', value: 3 },
                      { text: '4 horas', value: 4 },
                      { text: '5 horas', value: 5 },
                      { text: '6 horas', value: 5 },
                    ]"
                    hint="Duración aprox. del servicio"
                    persistent-hint
                    v-model="duracionHoras"
                    @change="_calcTimeService"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                >
                  <v-select
                    filled
                    label="Minutos"
                    :items="[
                      { text: '0 minutos', value: 0 },
                      { text: '10 minutos', value: 10 },
                      { text: '20 minutos', value: 20 },
                      { text: '30 minutos', value: 30 },
                      { text: '40 minutos', value: 40 },
                      { text: '50 minutos', value: 50 },
                    ]"
                    hint="Duración aprox. del servicio"
                    persistent-hint
                    v-model="duracionMinutos"
                    @change="_calcTimeService"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              lg="3"
            >
              <v-select
                filled
                label="Garantía*"
                v-model="servicio.garantia_ser"
                :error-messages="errors.garantia_ser"
                :items="[
                  { text: 'Sin garantía', value: 'no' },
                  { text: '1 mes', value: '1' },
                  { text: '2 meses', value: '2' },
                  { text: '3 meses', value: '3' },
                  { text: '4 meses', value: '4' },
                  { text: '6 meses', value: '6' },
                  { text: '12 meses', value: '12' },
                ]"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
              lg="3"
            >
              <v-select
                filled
                label="Estatus*"
                v-model="servicio.estatus_ser"
                :error-messages="errors.estatus_ser"
                :items="[
                  { text: 'Activo', value: 'activo' },
                  { text: 'Inactivo', value: 'inactivo' },
                ]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cancelar'"
            to="/servicios"
          />
          <v-btn
            class="primary"
            v-text="servicio.id ? 'Actualizar' : 'Guardar'"
            @click="_preSaveServicio"
          />
        </v-card-actions>
      </v-card>
    </div>
    <v-row
      class="mt24"
      v-if="servicio.id"
    >
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="4"
      >
        <!-- <ServiciosMaterialesConsumo
          :id="servicio.id"
          @msg="$emit('msg',$event)"
          @httpError="$emit('httpError',$event)"
        /> -->
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDialog"
      width="400"
    >
      <v-card>
        <v-card-title v-text="'¿Que deseas hacer a continuación?'" />
        <v-list-item to="/">
          <v-list-item-content>
            <v-list-item-title v-text="'Ir a inicio'" />
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>keyboard_arrow_right</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <v-list-item to="/servicios">
          <v-list-item-content>
            <v-list-item-title v-text="'Ir a servicios'" />
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>keyboard_arrow_right</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <v-list-item @click="showDialog = false">
          <v-list-item-content>
            <v-list-item-title v-text="'Continuar en la información de servicio'" />
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>keyboard_arrow_right</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
export default {
  name: "ServiciosForm",
  mixins: [standard, forms],
  components: {
    // ServiciosMaterialesConsumo: () => import("@/components/servicios/ServiciosMaterialesConsumo.vue"),
  },
  data() {
    return {
      servicio: {
        id: null,
        id_ser: null,
        desc_ser: null,
        tipo_ser: null,
        para_cita_ser: "no",
        precio_ser: null,
        estatus_ser: "activo",
        duracion_ser: null,
        garantia_ser: "no",
        // meses_garantia_ser: null,
        duracion_ser: null,
      },
      errors: {
        desc_ser: null,
        tipo_ser: null,
        para_cita_ser: null,
        precio_ser: null,
        estatus_ser: null,
        garantia_ser: null,
      },
      showDialog: false,
      duracionHoras: null,
      duracionMinutos: null,
    };
  },
  methods: {
    _preSaveServicio() {
      if (this._validateAll("servicio")) {
        const data = { servicio: this.servicio };
        //pasamos la ruta, data, lo que se debe encontrar en la respuesta, la accion a la que debe mandar la respuesta en caso de que todo salga bien
        this.servicio.id
          ? this._updateAll(
              "servicios/" + this.servicio.id,
              data,
              "msg",
              "_wasUpdated"
            )
          : this._saveAll("servicios", data, "id", "_wasSaved");
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _wasSaved(res) {
      this.showDialog = true;
      this.$router.push("/servicio/" + res.id);
      this.servicio.id = res.id;
      this.servicio.id_ser = res.id_ser;
      this.$emit("msg", res.msg);
    },
    _wasUpdated(res) {
      this.$emit("msg", res.msg);
      this.$router.push("/servicios");
    },
    _calcDuracionHoras() {
      const horas = parseInt(this.servicio.duracion_ser) / 60;
      this.duracionHoras = horas;
      this.duracionMinutos = (horas - parseInt(horas)) * 60;
    },
    _calcTimeService() {
      const minutos = this.duracionHoras * 60 + this.duracionMinutos;
      this.servicio.duracion_ser = minutos;
    },
  },
  created() {
    if (this.$route.params.idSer) {
      this._getAll(
        "servicio",
        "/" + this.$route.params.idSer,
        "_calcDuracionHoras"
      );
    }
  },
};
</script>