<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      floating
      temporary
    >
      <AppMenu />
    </v-navigation-drawer>
    <v-app-bar
      app
      collapse
      color="transparent"
      dense
      flat
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-btn icon>
        <v-icon v-text="'help_outline'" />
      </v-btn>
    </v-app-bar>
  </div>
</template>
<script>
import AppMenu from "@/components/appbar/AppMenu";
export default {
  name: "AppBar",
  components: { AppMenu },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>