<template>
  <div class="EncuestaForm">
    <v-stepper
      class="elevation-0"
      v-model="step"
    >
      <v-stepper-header class="elevation-0">
        <v-stepper-step
          step="1"
          :complete="step > 1"
        />
        <v-divider />
        <v-stepper-step
          step="2"
          :complete="step > 2"
        />
        <v-divider />
        <v-stepper-step
          step="3"
          :complete="step > 3"
        />
        <v-divider />
        <v-stepper-step
          step="4"
          :complete="step > 4"
        />
        <v-divider />
        <v-stepper-step
          step="5"
          :complete="step > 5"
        />
        <v-divider />
        <v-stepper-step
          step="6"
          :complete="step > 6"
        />
        <v-divider />
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="text-center">
            <h3>¿El servicio que te brindaron fue amable y profesional?</h3><br></br>
            <v-btn
              x-large
              style="margin-right:100px"
              :color="preguntas.a=='si'?'primary':'gray'"
              @click="preguntas.a='si';step++"
            >Si</v-btn>
            <v-btn
              x-large
              :color="preguntas.a=='no'?'red':'gray'"
              @click="preguntas.a='no';step++"
            >No</v-btn>
            <v-card-actions>
              <v-spacer />
              <v-btn
                icon
                @click="
              step++"
              >
                <v-icon>arrow_forward</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card class="text-center">
            <h3>¿Te dieron explicación de tu tratamiento odontológico y presupuesto?</h3><br></br>
            <v-btn
              x-large
              style="margin-right:100px"
              :color="preguntas.b=='si'?'primary':'gray'"
              @click="preguntas.b='si';step++"
            >Si</v-btn>
            <v-btn
              x-large
              :color="preguntas.b=='no'?'red':'gray'"
              @click="preguntas.b='no';step++"
            >No</v-btn>
            <v-card-actions>
              <v-btn
                @click="step--"
                icon
              >
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                icon
                @click="step++"
              >
                <v-icon>arrow_forward</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card class="text-center">
            <h3>¿Recomendarías a Dental Master con tus familiares y amigos?</h3><br></br>
            <v-btn
              x-large
              style="margin-right:100px"
              :color="preguntas.c=='si'?'primary':'gray'"
              @click="preguntas.c='si';step++"
            >Si</v-btn>
            <v-btn
              x-large
              :color="preguntas.c=='no'?'red':'gray'"
              @click="preguntas.c='no';step++"
            >No</v-btn>
            <v-card-actions>
              <v-btn
                @click="step--"
                icon
              >
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                icon
                @click="step++"
              >
                <v-icon>arrow_forward</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-card class="text-center">
            <h3>¿Por qué medio conociste <strong>DENTAL MASTER</strong>?</h3><br></br>
            <v-list outlined>
              <v-list-item
                @click="preguntas.d='Otro doctor me recomendó';step++"
                :class="preguntas.d=='Otro doctor me recomendó'?'primary':null"
                :dark="preguntas.d=='Otro doctor me recomendó'?true:false"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="'Otro doctor me recomendó'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                @click="preguntas.d='Amigos o familiares';step++"
                :class="preguntas.d=='Amigos o familiares'?'primary':null"
                :dark="preguntas.d=='Amigos o familiares'?true:false"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="'Amigos o familiares'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                @click="preguntas.d='Redes sociales (facebook, instagram)';step++"
                :class="preguntas.d=='Redes sociales (facebook, instagram)'?'primary':null"
                :dark="preguntas.d=='Redes sociales (facebook, instagram)'?true:false"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="'Redes sociales (facebook, instagram)'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                @click="preguntas.d='Cine';step++"
                :class="preguntas.d=='Cine'?'primary':null"
                :dark="preguntas.d=='Cine'?true:false"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="'Cine'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                @click="preguntas.d='Otro medio';step++"
                :class="preguntas.d=='Otro medio'?'primary':null"
                :dark="preguntas.d=='Otro medio'?true:false"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="'Otro medio'" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-btn
                @click="step--"
                icon
              >
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                icon
                @click="step++"
              >
                <v-icon>arrow_forward</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="5">
          <v-card class="text-center">
            <v-textarea
              filled
              label="¿Recomendaciones o sugerencias para Dental Master?"
            />
            <v-card-actions>
              <v-btn
                @click="step--"
                icon
              >
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                @click="_saveEncuesta"
                :loading="cargando"
              >
                Guardar respuestas
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="6">
          <v-card>
            step="6"
            <v-card-actions>
              <v-btn
                @click="step--"
                icon
              >
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                icon
                @click="step++"
              >
                <v-icon>arrow_forward</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
export default {
  name: "EncuestaForm",
  mixins: [standard, forms],
  data() {
    return {
      step: 1,
      preguntas: {
        id_pac_enc: null,
        a: null,
        b: null,
        c: null,
        d: null,
        e: null,
      },
    };
  },
  methods: {
    _saveEncuesta() {
      const data = { encuesta: this.preguntas };
      this._saveAll("encuestas", data, "msg", "_handleRedirect");
    },
    _handleRedirect(res) {
      this.$emit("msg", res.msg);
      this.$router.push("/");
    },
  },
  created() {},
  mounted() {},
};
</script>
