<template>
  <div class="ProveedoresListado">
    <div id="card">
      <v-card
        outlined
        :disabled="cargando"
        :loading="cargando"
      >
        <v-card-title>
          Proveedores
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            hide-details
          />
          <ButtonAdd @click="showDialog = true" />
        </v-card-title>
        <v-data-table
          class="upper"
          :headers="headers"
          :items="proveedores"
          :search="search"
          :loading="cargando"
          loading-text="Cargando..."
        >
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon v-text="'more_vert'" />
                </v-btn>
              </template>
              <v-list dark>
                <v-list-item @click="_editPro(item)">
                  <v-list-item-title v-text="'Editar'" />
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div id="dialogs">
      <v-dialog
        v-model="showDialog"
        persistent
        scrollable
        width="400"
      >
        <v-card>
          <v-card-title v-text="(proveedor.id ? 'Editar' : 'Registrar') + ' proveedor'" />
          <v-card-text>
            <v-text-field
              v-model="proveedor.nombre_pro"
              :error-messages="errors.nombre_pro"
              filled
              label="Nombre o razón social del proveedor"
            />
            <v-select
              v-model="proveedor.estatus_pro"
              :error-messages="errors.estatus_pro"
              filled
              label="Estatus"
              :items="statusMasculino"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              v-text="'Cancelar'"
              @click="showDialog = false"
            />
            <v-btn
              @click="_preSaveProv"
              class="primary"
              v-text="proveedor.id ? 'Actualizar' : 'Guardar'"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
export default {
  name: "ProveedoresListado",
  mixins: [standard, forms],
  data() {
    return {
      showDialog: false,
      search: "",
      headers: [
        { text: "Nombre", value: "nombre_pro" },
        { text: "Estatus", value: "estatus_pro" },
        { text: "", value: "actions", align: "end" },
      ],
      proveedores: [],
      proveedor: {
        id: null,
        id_pro: null,
        nombre_pro: null,
        estatus_pro: null,
      },
      errors: {
        nombre_pro: null,
        estatus_pro: null,
      },
    };
  },
  methods: {
    _getProveedores(res) {
      if (res && "msg" in res) {
        this.$emit("msg", res.msg);
        this.showDialog = false;
      }
      this._getAll("proveedores");
    },
    _preSaveProv() {
      if (this._validateAll("proveedor")) {
        const data = { proveedor: this.proveedor };
        this.proveedor.id
          ? this._updateAll(
              "proveedores/" + this.proveedor.id,
              data,
              "msg",
              "_getProveedores"
            )
          : this._saveAll("proveedores", data, "id", "_getProveedores");
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _editPro(a) {
      this.proveedor.id = a.id;
      this.proveedor.id_pro = a.id_pro;
      this.proveedor.nombre_pro = a.nombre_pro;
      this.proveedor.estatus_pro = a.estatus_pro;
      this.showDialog = true;
    },
  },
  created() {
    this._getProveedores();
  },
  watch: {
    showDialog(a) {
      if (!a) {
        this._limpiarCampos("proveedor");
      }
    },
  },
};
</script>