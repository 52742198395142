export default {
    data() {
        return {
            cargando: false,
            llenarCampo: "Este campo es obligatorio"
        }
    },
    methods: {
        async _getThings(url = null, whatToGet = null, params = '', nextAction = null) {
            if (url && whatToGet) {
                this.cargando = true;
                try {
                    const { status, body } = await this.$http.get(
                        url + params,
                        this.$headers
                    );
                    if (status === 200 && whatToGet in body) {
                        this[whatToGet] = body[whatToGet];
                        if (nextAction) {
                            this[nextAction](body)
                        }
                    } else {
                        this.$emit("httpError", res);
                    }
                    this.cargando = false;
                } catch (err) {
                    this._handleError(err);
                }
            }
        },
        _hasError(a) {
            return a ? null : this.llenarCampo;
        },
        _validateArray(arr) {
            return Object.values(arr).indexOf(this.llenarCampo) <= -1 ? true : false;
        },
        _validateAll(dataVariable = null) {
            //Se estandariza que solo lo que verificara estara en "errors" y debera tener el mismo object que que en su variable correspondiente
            if (dataVariable) {
                Object.keys(this.errors).forEach(e => {
                    this.errors[e] = this._hasError(this[dataVariable][e])
                });
                return this._validateArray(this.errors)
            } else { return false; }
        },
        async _saveAll(ruta = null, data = null, whatShouldLookFor = null, functionHandleSuccess = null) {
            if (ruta && data && whatShouldLookFor) {
                this.cargando = true;
                try {
                    const { status, body } = await this.$http.post(ruta, data, this.$headers);
                    this._handleResponse(status, whatShouldLookFor, body, functionHandleSuccess);
                    this.cargando = false;
                } catch (err) {
                    this._handleError(err);
                }
            }
        },
        async _updateAll(ruta = null, data = null, whatShouldLookFor = null, functionHandleSuccess = null) {
            if (ruta && data && whatShouldLookFor) {
                this.cargando = true;
                try {
                    const { status, body } = await this.$http.put(ruta, data, this.$headers)
                    this._handleResponse(status, whatShouldLookFor, body, functionHandleSuccess)
                    this.cargando = false;
                } catch (err) {
                    this._handleError(err)
                }
            }
        },
        async _deleteAll(ruta = null, whatShouldLookFor = null, functionHandleSuccess = null) {
            if (ruta && whatShouldLookFor) {
                this.cargando = true;
                try {
                    const { status, body } = await this.$http.delete(ruta, this.$headers)
                    this._handleResponse(status, whatShouldLookFor, body, functionHandleSuccess)
                    this.cargando = false;
                } catch (err) {
                    this._handleError(err)
                }
            }
        },
        _handleResponse(responseStatus, whatShouldLookFor, responseBody, functionHandleSuccess) {
            if (
                (responseStatus === 200 || responseStatus === 201) &&
                whatShouldLookFor in responseBody
            ) {
                if (functionHandleSuccess) this[functionHandleSuccess](responseBody);
            } else {
                this.$emit('msg', responseBody.error)
            }
        },
        _handleError(err) {
            this.$emit("httpError", err);
            this.cargando = false;
        },
        _limpiarCampos(propMustBeCleaned = null, clearErrors = true) {
            if (propMustBeCleaned)
                Object.keys(this[propMustBeCleaned]).forEach(e => {
                    this[propMustBeCleaned][e] = null
                });
            Object.keys(this.errors).forEach(e => {
                this.errors[e] = null
            });

        },
    }
}