<template>
  <div>
    <v-card outlined :disabled="cargando" :loading="cargando">
      <v-tabs
        background-color="accent"
        active-class="secondary"
        fixed-tabs
        show-arrows
        v-model="tab"
      >
        <v-tab
          v-for="(status, idxstatus) in estatus"
          :key="idxstatus"
          v-text="_doCustomText(status)"
          :to="ruta + status"
        />
      </v-tabs>
      <div v-if="status != 'abono'">
        <!-- <v-card-title>{{_doCustomText(status)}}</v-card-title> -->
        <v-card-actions v-if="status == 'incompleto'" class="mb24">
          <v-spacer />
          <v-btn
            v-if="cargos.length >= 1"
            class="primary"
            @click="_getSaldoPendiente()"
          >
            Abonar <v-icon>add</v-icon>
          </v-btn>
        </v-card-actions>
        <v-data-table
          class="upper"
          :headers="status == 'completo' ? headersCompleto : headers"
          :items="cargos"
          :loading="cargando"
          loading-text="Cargando..."
        >
          <template v-slot:item.fecha_cgo="{ item }">
            {{ _doDiaMesHoras(item.fecha_cgo) }}
          </template>
          <template v-slot:item.importe_cgo="{ item }">
            {{ $nF(item.importe_cgo) }}
          </template>
          <template v-slot:item.pendiente_cgo="{ item }">
            {{ $nF(item.pendiente_cgo) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y v-if="status != 'cancelado'">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon v-text="'more_vert'" />
                </v-btn>
              </template>
              <v-list dark>
                <v-list-item @click="_getCargoDetalle(item.id)">
                  <v-list-item-title v-text="'Ver detalle'" />
                </v-list-item>
                <div v-if="status == 'incompleto'">
                  <v-divider />

                  <v-list-item @click="_getSaldoPendiente(item.id)">
                    <v-list-item-title v-text="'Abonar a este cargo'" />
                  </v-list-item>
                </div>
                <div v-if="status == 'incompleto'">
                  <v-divider />
                  <v-list-item @click="cargoToDelete = item.id">
                    <v-list-item-title v-text="'Cancelar cargo'" />
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
      <div v-else>
        <!-- <v-card-title>Abonos registrados</v-card-title> -->
        <v-data-table
          class="upper"
          :headers="headersAbono"
          :items="abonos"
          :loading="cargando"
          loading-text="Cargando..."
        >
          <template v-slot:item.fecha_abo="{ item }">
            {{ _doDiaMesHoras(item.fecha_abo) }}
          </template>
          <template v-slot:item.importe_abo="{ item }">
            {{ $nF(item.importe_abo) }}
          </template>
          <!-- <template v-slot:item.actions="{ item }"><v-menu offset-y v-if="status!='cancelado'"><template v-slot:activator="{ on }"><v-btn icon v-on="on"><v-icon v-text="'more_vert'"/></v-btn></template><v-list dark><v-list-item @click="_getCargoDetalle(item.id)"><v-list-item-title v-text="'Ver detalle'"/></v-list-item></v-list></v-menu></template> -->
        </v-data-table>
      </div>
    </v-card>
    <v-dialog v-model="showDialogCargoDetalle" scrollable width="400">
      <v-card :loading="cargando" :disabled="cargando">
        <v-card-title>Información del cargo</v-card-title>
        <v-list>
          <v-subheader v-text="'Productos y servicios del cargo'" />
          <div v-for="ser in cargo.servicios" :key="ser.id_cse">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="
                    $nF(parseFloat(ser.precio_cse * ser.cantidad_cse)) +
                      ', ' +
                      ser.desc_ser
                  "
                />
                <v-list-item-subtitle
                  v-text="'Cantidad: ' + ser.cantidad_cse"
                />
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </div>
        </v-list>
        <v-list v-if="'abonos' in cargo && cargo.abonos.length >= 1">
          <v-subheader>Abonos registrados al cargo</v-subheader>
          <div v-for="abc in cargo.abonos" :key="abc.id_abc">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Abono por ' + $nF(abc.importe_abo)"
                />
                <v-list-item-subtitle v-text="_doDiaMesHoras(abc.fecha_abo)" />
                <v-list-item-subtitle
                  v-text="'Método de pago: ' + abc.metodo_pago_abo"
                />
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </div>
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cerrar'"
            @click="showDialogCargoDetalle = false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialogAbonar" scrollable width="400">
      <v-card :loading="cargando" :disabled="cargando">
        <v-card-title v-text="'Abonar a la cuenta del cliente'" />
        <h1 class="h3 text-center">{{ $nF(saldo) }}</h1>
        <p class="text-center">Saldo pendiente</p>
        <v-card-text>
          <v-text-field
            class="mt4"
            prepend-inner-icon="attach_money"
            label="Importe a abonar"
            min="1"
            max="saldo"
            outlined
            v-model="abono.importe_abo"
          />
          <v-select
            v-model="abono.metodo_pago_abo"
            outlined
            :items="[
              { text: 'Efectivo', value: 'efectivo' },
              { text: 'Tarjeta', value: 'tarjeta' },
              { text: 'Transferencia', value: 'transferencia' },
              { text: 'Cheque', value: 'cheque' }
            ]"
            label="Método de pago"
          />
          <v-btn
            block
            :disabled="!(abono.importe_abo >= 1 && abono.metodo_pago_abo)"
            class="primary"
            v-text="'Guardar abono'"
            @click="_guardarAbono"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text v-text="'Cerrar'" @click="showDialogAbonar = false" />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :showDialog="showDialogConfirmDelete"
      errorMessage="¿Estas seguro deseas eliminar este concepto?"
      errorMessageDetail="Eliminar este concepto también borrará toda informacón relacionada con sus abonos y no podrá ser revertido"
      @confirmDelete="_cancelarCargo"
      @close="showDialogConfirmDelete = false"
    />
  </div>
</template>
<script>
  import forms from '@/mixins/forms.js';
  import dates from '@/mixins/dates.js';
  import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
  export default {
    components: { DeleteDialog },
    name: 'PacientesDetallesPagos',
    props: {
      id: [String, Number]
    },
    mixins: [forms, dates],
    data() {
      return {
        tab: null,
        status: 'incompleto',
        estatus: ['incompleto', 'abono', 'completo', 'cancelado'],
        search: '',
        headers: [
          { text: 'Folio', value: 'id_cgo', align: 'end' },
          { text: 'Desde', value: 'fecha_cgo' },
          { text: 'Importe', value: 'importe_cgo', align: 'end' },
          { text: 'Debe', value: 'pendiente_cgo', align: 'end' },
          { text: '', value: 'actions', align: 'end' }
        ],
        headersCompleto: [
          { text: 'Folio', value: 'id_cgo', align: 'end' },
          { text: 'Desde', value: 'fecha_cgo' },
          { text: 'Importe', value: 'importe_cgo', align: 'end' },
          { text: '', value: 'actions', align: 'end' }
        ],
        headersAbono: [
          { text: 'Fecha', value: 'fecha_abo' },
          { text: 'Importe', value: 'importe_abo', align: 'end' },
          { text: 'Metodo', value: 'metodo_pago_abo', align: 'end' },
          { text: '', value: 'actions', align: 'end' }
        ],
        cargos: [],
        saldos: [],
        cargo: {},
        abonos_cargos: [],
        abono: {
          importe_abo: null,
          metodo_pago_abo: null,
          id_cgo_abo: null,
          id_pac_abo: null
        },
        saldo: 0,
        porAbonar: null,
        showDialogCargoDetalle: false,
        showDialogAbonar: false,
        cargoSeleccionado: null,
        cargoToDelete: null,
        showDialogConfirmDelete: false,
        ruta: null
      };
    },
    methods: {
      async _getCargos(res = null) {
        this.showDialogConfirmDelete = false;
        if (res && 'msg' in res) {
          this.$emit('msg', res.msg);
        }
        this.cargando = true;
        try {
          const {
            status,
            body: { cargos, saldos }
          } = await this.$http.get(
            'cargos/paciente/' + this.id + '?estatus=' + this.status,
            this.$headers
          );
          this.cargando = false;
          this.cargos = cargos;
          this.saldos = saldos;
          this.search = '';
        } catch (err) {
          this.cargando = false;
          this.$emit('httpError', err);
        }
      },
      _getAbonos() {
        this.abonos = [];
        this._getThings('abonos/paciente/' + this.id, 'abonos');
      },
      async _getCargoDetalle(idCgo) {
        this.cargando = true;
        try {
          const {
            body: { cargo }
          } = await this.$http.get('cargos/detalle/' + idCgo, this.$headers);
          this.cargando = false;
          if (cargo) {
            this.cargo = cargo;
            this.showDialogCargoDetalle = true;
          } else {
            this.$emit('error', body.error);
          }
        } catch (err) {
          this._handleError(err);
        }
      },
      async _getSaldoPendiente(a = '') {
        this.cargando = true;
        this.cargoSeleccionado = a;
        try {
          const {
            body: { saldo }
          } = await this.$http.get(
            'cargos/pendiente/' + this.id + '/' + a,
            this.$headers
          );
          this.cargando = false;
          if (saldo >= 0.00000001) {
            this.saldo = saldo;
            this.showDialogAbonar = true;
          } else {
            this.$emit('error', 'No se encontró la información solicitada');
          }
        } catch (err) {
          this._handleError(err);
        }
      },
      _guardarAbono() {
        const a = parseFloat(this.saldo);
        if (this.abono.importe_abo > a) this.abono.importe_abo = a;
        const data = {
          abono: {
            importe_abo: this.abono.importe_abo,
            metodo_pago_abo: this.abono.metodo_pago_abo,
            id_cgo_abo: this.cargoSeleccionado,
            id_pac_abo: this.id
          }
        };
        this._saveAll('abonos', data, 'id', '_handleResponseAbonoGuardado');
      },
      _handleResponseAbonoGuardado(res) {
        this.$emit('msg', res.msg);
        if ('whatsapp' in res) window.open(res.whatsapp, '_blank');
        this.showDialogAbonar = false;
        this._getCargos();
      },
      _cancelarCargo(a) {
        this._deleteAll('cargos/' + this.cargoToDelete, 'msg', '_getCargos');
      },
      _doCustomText(text = '') {
        switch (text) {
          // “deuda”/“abono”/liquidado/cancelado
          case 'incompleto':
            return 'deuda';
            break;
          case 'completo':
            return 'liquidado';
            break;
          case 'cancelado':
            return 'cancelado';
            break;
          case 'abono':
            return 'abono';
            break;
          default:
            return '';
            break;
        }
      }
    },
    created() {
      this.ruta =
        '/paciente/historiaclinica/' + this.$route.params.idPac + '/pagos/';
    },
    mounted() {
      this._getCargos();
    },
    watch: {
      '$route.params.estatus'(a) {
        this.cargos = [];
        this.abonos = [];
        this.status = a;
        a == 'abono' || a == 'abonos' ? this._getAbonos() : this._getCargos();
      },
      showDialogCargoDetalle(a) {
        if (!a) this.cargo = {};
      },
      showDialogAbonar(a) {
        if (!a) {
          this.abono.importe_abo = null;
          this.abono.metodo_pago_abo = null;
          this.abono.id_cgo_abo = null;
          this.abono.id_pac_abo = null;
          this._getCargos();
        }
      },
      cargoToDelete(a) {
        this.showDialogConfirmDelete = a ? true : false;
      },
      showDialogCargoDetalle(a) {
        if (!a) this.cargoToDelete = null;
      }
    }
  };
</script>
