<template>
  <div class="MaterialesForm">
    <v-card
      :loading="cargando"
      :disabled="cargando"
    >
      <v-card-title v-text="(material.id?'Editar' :'Registrar')+' material'" />
      <v-card-text>
        <v-text-field
          filled
          dense
          label="Nombre del material*"
          v-model="material.desc_mat"
          :error-messages="errors.desc_mat"
        />
        <v-text-field
          filled
          dense
          label="Existencia mínima*"
          v-model="material.min_mat"
          :error-messages="errors.min_mat"
        />
        <v-text-field
          filled
          dense
          label="Existencia máxima*"
          v-model="material.max_mat"
          :error-messages="errors.max_mat"
        />

      </v-card-text>
      <ButtonCardActions
        @cancel="_limpiarCampos('material');$emit('cancel')"
        :text="material.id ? 'Actualizar':'Guardar'"
        @click="_preSaveMat"
      />
    </v-card>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "MaterialesForm",
  props: {
    value: Object,
  },
  mixins: [forms],
  components: {
    ButtonCardActions: () =>
      import("@/components/forms/buttons/ButtonCardActions.vue"),
  },
  data() {
    return {
      material: {
        id: null,
        id_mat: null,
        desc_mat: null,
        min_mat: null,
        max_mat: null,
      },
      errors: {
        desc_mat: null,
        min_mat: null,
        max_mat: null,
      },
    };
  },
  methods: {
    _preSaveMat() {
      if (this._validateAll("material")) {
        this.material.id
          ? this._updateAll(
              "materiales/" + this.material.id,
              { material: this.material },
              "msg",
              "_doEmitUpdateMat"
            )
          : this._saveAll(
              "materiales",
              { material: this.material },
              "id",
              "_doEmitNewMat"
            );
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _doEmitNewMat(res) {
      this.material.id = res.id;
      this.material.id_mat = res.id_mat;
      this.$emit("saved", this.material);
      this.$emit("msg", res.msg);
      this.$emit("cancel");
    },
    _doEmitUpdateMat(res) {
      this.$emit("updated", this.material);
      this.$emit("msg", res.msg);
      this.$emit("cancel");
    },
    _valueChange(a) {
      if (a && a.id) {
        this.material.id = a.id;
        this.material.id_mat = a.id_mat;
        this.material.desc_mat = a.desc_mat;
        this.material.min_mat = a.min_mat;
        this.material.max_mat = a.max_mat;
      } else {
        this._limpiarCampos(material);
      }
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: "_valueChange",
    },
  },
  created() {
    if (this.value) this._valueChange(this.value);
  },
};
</script>