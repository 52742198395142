<template>
  <div class="PacientesDetallePlanesTratamiento">
    <v-card outlined>
      <v-list>
        <v-list-item @click="showTratamientoDialog=true">
          <v-list-item-content>
            <v-list-item-title v-text="'Registrar plan de tratamiento'" />
          </v-list-item-content>
          <v-list-item-action>
            <button-add @click="showTratamientoDialog=true" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <div
          v-for="(plan,idxplt) in planes"
          :key="idxplt"
        >
          <v-list-item v-if="plan.realizado_plt==='no'">
            <v-list-item-content>
              <v-list-item-title
                class="nowrap"
                v-text="plan.descripcion_plt"
              />
              <v-list-item-subtitle
                class="nowrap"
                v-text="'Aun no realizado'"
              />
              <v-list-item-subtitle class="nowrap">
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon v-text="'more_vert'" />
                  </v-btn>
                </template>
                <v-list dark>
                  <v-list-item @click="plan.realizado_plt='si';_updatePlan(idxplt)">
                    <v-list-item-title v-text="'Ya fue realizado'" />
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          <v-expansion-panels
            v-else
            class="ep-has-no-shadow"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>{{plan.descripcion_plt}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="plan.fecha_realizado_plt && plan.fecha_realizado_plt!='0000-00-00'">
                  Realizado el {{_doDiaMes(plan.fecha_realizado_plt)}}<br><br>
                </div>
                <div
                  v-else
                  class="mb24"
                >
                  <date-picker
                    label="¿Cuándo fue realizado?"
                    v-model="fecha_realizado_plt"
                  />
                  <v-btn
                    class="primary"
                    v-text="'Guardar fecha'"
                    @click="plan.fecha_realizado_plt = fecha_realizado_plt ;_updatePlan(idxplt)"
                  />
                </div>
                <div v-if="plan.id_odo_plt">
                  <v-btn
                    v-text="'Ver odontograma'"
                    class="primary"
                    @click="showDetalleOdonto = plan.id_odo_plt"
                  />
                  <br><br>
                </div>
                <div
                  v-else
                  class="mb24"
                >
                  <AutocompleteOdontogramas
                    label="Anexar odontograma"
                    v-model="id_odo_plt"
                    :paciente="paciente"
                  />
                  <v-btn
                    class="primary"
                    v-text="'Guardar odontograma'"
                    @click="plan.id_odo_plt = id_odo_plt ;_updatePlan(idxplt)"
                  />
                </div>
                <div v-if="plan.nota_evolucion_plt">
                  <strong>Nota de evolución:</strong><br><span v-html="plan.nota_evolucion_plt" />
                </div>
                <div
                  v-else
                  class="mb24"
                >
                  <v-textarea
                    label="Nota de evolución"
                    rows="2"
                    filled
                    v-model="plan.nota_evolucion_plt_alt"
                  />
                  <v-btn
                    class="primary"
                    v-text="'Guardar nota de evolución'"
                    @click="plan.nota_evolucion_plt = plan.nota_evolucion_plt_alt ;_updatePlan(idxplt)"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider v-if="idxplt+1<planes.length" />
        </div>
      </v-list>
    </v-card>
    <planes-tratamiento-form
      v-model="showTratamientoDialog"
      :paciente="paciente"
      @msg="$emit('msg',$event)"
      @httpError="$emit('httpError',$event)"
      @refresh="_getPlanesTratamientos"
    />
    <OdontogramaDetalle v-model="showDetalleOdonto" />
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
import ButtonAdd from "@/components/forms/buttons/ButtonAdd.vue";
import PlanesTratamientoForm from "@/components/planestratamiento/PlanesTratamientoForm.vue";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
import OdontogramaDetalle from "@/components/odontograma/OdontogramaDetalle.vue";
import AutocompleteOdontogramas from "@/components/forms/autocompletes/AutocompleteOdontogramas.vue";
export default {
  name: "PacientesDetallePlanesTratamiento",
  mixins: [forms, dates],
  components: {
    ButtonAdd,
    PlanesTratamientoForm,
    DatePicker,
    AutocompleteOdontogramas,
    OdontogramaDetalle,
  },
  data() {
    return {
      planes: [],
      paciente: null,
      showTratamientoDialog: null,
      showDialogDetallePlan: false,
      showDetalleOdonto: null,
      errors: {},
      plan: {},
      fecha_realizado_plt: null,
      nota_evolucion_plt: null,
      id_odo_plt: null,
    };
  },
  methods: {
    _asignarPaciente() {
      this.paciente = this.$route.params.idPac;
    },
    _getPlanesTratamientos() {
      this._getThings("planesdetratamiento/" + this.paciente, "planes");
    },
    _doFecha(fecha, conHora = false) {
      return this.$Datetime
        .fromSQL(fecha)
        .setLocale("es")
        .toLocaleString(
          conHora ? this.$Datetime.DATETIME_HUGE : this.$Datetime.DATE_HUGE
        );
    },
    _getPlanTratamiento(data) {
      this._getThings("plandetratamiento/" + data, "plan");
    },
    _openDialogDetallePlt() {
      if (this.plan.id) this.showDialogDetallePlan = true;
    },
    async _updatePlan(idplan) {
      this.cargando = true;
      const data = { plantratamiento: this.planes[idplan] };
      this._updateAll(
        "planesdetratamiento/" + this.planes[idplan].id,
        data,
        "msg",
        "_handleResponsePlanTratamiento"
      );
    },
    _handleResponsePlanTratamiento(res) {},
  },
  created() {
    this._asignarPaciente();
    this._getPlanesTratamientos();
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler: "_asignarPaciente",
    },
    showDialogDetallePlan(a) {
      if (!a) {
        this._limpiarCampos("plan");
        this.plan.anteriores = [];
      }
    },
    plan: {
      deep: true,
      immediate: true,
      handler: "_openDialogDetallePlt",
    },
  },
};
</script>