<template>
  <div
    class="Login"
    fluid
  >
    <div class="login-row">
      <div class="login-card">
        <v-card
          flat
          color="transparent"
          :disabled="cargando"
          :loading="cargando"
        >
          <v-card-text>
            <img
              class="img-login img-center"
              src="@/assets/logo.svg"
              style="max-width:260px;width:100%"
              alt="Cruz Roja Mexicana Delegación Chihuahua Logo"
            />
          </v-card-text>
          <v-card-title v-text="'Inicio de sesión'" />
          <v-card-subtitle v-text="'Ingrese su usuario y correspondiente para acceder a su sesión'" />
          <v-card-text>
            <v-text-field
              elevation="0"
              v-model="usuario"
              placeholder="Usuario"
              :disabled="cargando"
              prepend-inner-icon="person_outline"
              @keyup.native.enter="_doLogin"
              outlined
              background-color="#fefffe"
            />
            <v-text-field
              v-model="password"
              placeholder="Contraseña"
              :disabled="cargando"
              prepend-inner-icon="vpn_key"
              type="password"
              @keyup.native.enter="_doLogin"
              outlined
              background-color="#fefffe"
            />
            <v-btn
              v-if="!cargando"
              block
              class="primary"
              depressed
              large
              v-text="'Iniciar sesión'"
              @click="_doLogin"
            />
          </v-card-text>
          <Loading v-if="cargando" />
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading";
export default {
  name: "Login",
  components: { Loading },
  data() {
    return {
      inputUserIsFocus: false,
      inputPassIsFocus: false,
      usuario: null,
      password: null,
      cargando: false,
    };
  },
  methods: {
    _doLogin() {
      this.cargando = true;
      this.$http
        .post("login", {
          a: this.usuario,
          b: this.password,
        })
        .then(
          (res) => {
            this.cargando = false;
            if (res.status === 200 && "token" in res.body) {
              this.$session.start();
              delete this.$headers.headers.Token;
              this.$headers.headers.Token = res.body.token;
              this.$session.set("nombre", res.body.nombre);
              this.$session.set("token", res.body.token);
              this.$session.set("tipo", res.body.tipo);
              this.$emit("msg", "Hola " + res.body.nombre);
              //  this.$LogRocket.identify(res.body.token, {
              //    name: res.body.nombre,
              //    type: res.body.tipo,
              //  });
              this.$router.push("/pacientes");
            } else {
              this.$emit("msg", res.body.error);
            }
          },
          (err) => {
            this.cargando = false;
            this.$emit("httpError", err);
          }
        );
    },
  },
  created() {
    this.$session.destroy();
    delete this.$headers.headers.Token;
    delete this.$headers.headers.token;
    this.$emit("visible", false);
    this.$emit("classes", "");
  },
};
</script>
