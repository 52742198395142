<template>
  <v-card-actions class="ButtonCardActions">
    <v-spacer />
    <v-btn text v-text="'Cancelar'" :to="to" @click="$emit('cancel')" />

    <v-btn v-text="text" class="primary" @click="$emit('click')" />
  </v-card-actions>
</template>
<script>
export default {
  name: "ButtonCardActions",
  props: {
    to: String,
    text: String,
  },
};
</script>