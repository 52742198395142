<template>
  <div class="TestimoniosLisstado">
    <v-btn
      fab
      class="primary floating-buttons"
      medium
      @click="showDialog=true"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-card
      class="mx-auto"
      max-width="350"
      flat
      v-if="cargando"
      loading
    >
      <v-skeleton-loader
        class="mx-auto"
        max-width="350"
        type="image,card-heading,divider,list-item-three-line,actions"
      />

    </v-card>
    <v-row
      style="margin-top:-40px"
      v-else
    >
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="item in testimonios"
        :key="item.id"
      >
        <v-card outlined>
          <v-img
            height="250"
            :src="$http.options.root+item.img_tes"
          />
          <v-card-title>
            {{item.paciente_tes}}
            <v-spacer />
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list dark>
                <v-list-item @click="_editTestimonio(item)">
                  <v-list-item-title v-text="'Editar'" />
                </v-list-item>
                <v-list-item @click="_preDeleteTestimonio(item)">
                  <v-list-item-title v-text="'Eliminar'" />
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <div class="subtitle-1">
              <blockquote v-html="item.testimonio_tes" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      width="400"
      v-model="showDialog"
    >
      <v-card outlined>
        <v-card-title>{{testimonio.id?'Editar':'Nuevo'}} testimonio</v-card-title>
        <v-card-text>
          <v-text-field
            filled
            label="Nombre del paciente"
            v-model="testimonio.paciente_tes"
            :error-messages="errors.paciente_tes"
          />
          <v-textarea
            filled
            label="Testimonio"
            v-model="testimonio.testimonio_tes"
            :error-messages="errors.testimonio_tes"
          />
          <v-file-input
            filled
            prepend-icon="add_a_photo"
            label="Fotografía del paciente"
            v-model="testimonio.img_tes"
            @change="imagen=$event"
            hint="Te sugerimos adjuntar una fotografía del paciente, recuerda que una imágen acompañada de un testimonio genera un mejor impacto."
            persistent-hint
            chips
            truncate-length="15"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="showDialog=false"
            text
            v-text="'Cancelar'"
          />
          <v-btn
            class="accent"
            @click="_preSaveTestimonio"
          >
            {{testimonio.id?'Actualizar':'Guardar'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DeleteDialog
      :showDialog="showDeleteDialog"
      errorMessage="¿Estás seguro que deseas eliminar este testimonio?"
      errorMessageDetail="Una vez eliminado, este no podra ser recuperado y se deberá capturar toda la información de nuevo"
      @closed="showDeleteDialog=false"
      @confirmDelete="_deleteTestimonio"
    />
  </div>
</template>

<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
export default {
  name: "TestimoniosLisstado",
  mixins: [standard, forms],
  components: {
    DeleteDialog: () => import("@/components/dialogs/DeleteDialog.vue"),
  },
  data() {
    return {
      showDialog: false,
      showDeleteDialog: false,
      testimonios: [],
      testimonio: {
        id: null,
        id_tes: null,
        paciente_tes: null,
        img_tes: null,
        testimonio_tes: null,
      },
      errors: {
        paciente_tes: null,
        // img_tes: null,
        testimonio_tes: null,
      },
      imagen: null,
    };
  },
  methods: {
    _getTestimonios(res) {
      this.showDialog = false;
      this.showDeleteDialog = false;
      if (res && "msg" in res) {
        this.$emit("msg", res.msg);
      }
      this.testimonios = [];
      //
      this._getThings("testimonios", "testimonios");
    },
    _preSaveTestimonio() {
      if (this._validateAll("testimonio")) {
        var data = new FormData();
        data.append("testimonio", JSON.stringify(this.testimonio));
        data.append("imagen", this.imagen);
        this._saveAll(
          "testimonios/" + (this.testimonio.id ? this.testimonio.id : ""),
          data,
          "msg",
          "_getTestimonios"
        );
      } else {
      }
    },
    _editTestimonio(data) {
      this._asignData(data);
      this.showDialog = true;
    },
    _preDeleteTestimonio(data) {
      this._asignData(data);
      this.showDeleteDialog = true;
    },
    _asignData(data) {
      this.testimonio.id = data.id;
      this.testimonio.id_tes = data.id_tes;
      this.testimonio.paciente_tes = data.paciente_tes;
      this.testimonio.testimonio_tes = data.testimonio_tes;
    },
    _deleteTestimonio() {
      this._deleteAll(
        "testimonios/" + this.testimonio.id,
        "msg",
        "_getTestimonios"
      );
    },
    _doCleanFields(a) {
      if (!a) {
        this._limpiarCampos("testimonio");
        this.imagen = null;
      }
    },
  },
  created() {
    this._getTestimonios();
  },
  watch: {
    showDialog: {
      immediate: true,
      handler: "_doCleanFields",
    },
    showDeleteDialog: {
      immediate: true,
      handler: "_doCleanFields",
    },
  },
};
</script>
<style lang="scss">
.floating-buttons {
  position: sticky;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  top: 10px;
  left: 100vw;
  z-index: 99 !important;
}
</style>