<template>
  <div class="PacientesDetalleGaleriaTratamiento">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Galeria de tratamiento
        <v-spacer />
        <button-add @click="showDialogUpload=true" />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            v-for="(g,n) in galeria"
            :key="n"
            class="d-flex child-flex"
            cols="3"
          >
            <v-img
              :src="$raiz + 'uploads/imagenes/' + g.imagen_pag"
              class="grey lighten-2"
              aspect-ratio="1"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showDialogUpload"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title>Subir imagen a la galería</v-card-title>
        <v-card-text>
          <v-file-input
            label="Seleccionar imagen"
            filled
            v-model="archivo"
            accept="image/jpeg, image/png, image/jpg"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            :disabled="cargando"
            v-text="'Cancelar'"
            @click="showDialogUpload=false"
          />
          <v-btn
            :loading="cargando"
            :disabled="cargando"
            @click="_doUpload"
            v-text="'Subir imagen'"
            class="primary"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
import ButtonAdd from "@/components/forms/buttons/ButtonAdd.vue";
export default {
  name: "PacientesDetalleGaleriaTratamiento",
  mixins: [forms, dates],
  components: { ButtonAdd },
  data() {
    return {
      search: "",
      paciente: null,
      showDialogUpload: false,
      archivo: null,
      galeria: [],
    };
  },
  methods: {
    _getGaleria() {
      this._getThings("pacientes/galeria/" + this.paciente, "galeria");
    },
    // _getImg(img) {
    //   return require(this.$raiz + "uploads/imagenes/" + img);
    // },
    async _doUpload() {
      this.cargando = true;
      try {
        let data = new FormData();
        data.append("imagen", this.archivo);
        const { body } = await this.$http.post(
          "pacientes/galeria/" + this.paciente,
          data,
          this.$headers
        );
        this.cargando = false;
        if ("msg" in body) {
          this.$emit("msg", body.msg);
          this.showDialogUpload = false;
          this._getGaleria();
        } else {
          this.$emit("msg", body.error);
        }
      } catch (err) {
        this.$emit("httpError", err);
        this.cargando = false;
      }
    },
    _handleResponseImagen(res) {
      this.$emit("msg", res.msg);
      this.showDialogUpload = false;
      this._getGaleria();
    },
  },
  created() {
    this.paciente = this.$route.params.idPac;
    this._getGaleria();
  },
  watch: {},
};
</script>