<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-card outlined>
          <v-list>
            <v-list-item :to="'/consulta/'+paciente">
              <v-list-item-content>
                <v-list-item-title v-text="'Generar nueva consulta'" />
              </v-list-item-content>
              <v-list-item-action>
                <button-add :to="'/consulta/'+paciente" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="consulta in consultas"
        :key="consulta.id"
      >
        <v-card outlined>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{$Datetime.fromSQL(consulta.fecha_con).setLocale('es').toLocaleString($Datetime.DATETIME_MED)}}</v-list-item-subtitle>
                <v-list-item-title>Motivo: {{consulta.motivo_con}}</v-list-item-title>
                <v-list-item-subtitle class="nowrap">
                  Médico: {{consulta.medico}}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list dark>
                    <v-list-item :to="`/consulta/${paciente}/${consulta.id}`">
                      <v-list-item-title v-text="'Ver consulta'" />
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        v-text="'Imprimir receta'"
                        to="/"
                      />
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import ButtonAdd from "../../../components/forms/buttons/ButtonAdd.vue";
export default {
  components: { ButtonAdd },
  name: "PacientesDetalleConsultas",
  mixins: [forms],
  data() {
    return {
      paciente: null,
      consultas: [],
    };
  },
  methods: {
    _getConsultas() {
      this._getThings("consultas/" + this.paciente, "consultas");
    },
  },
  created() {
    this.paciente = this.$route.params.idPac;
    this._getConsultas();
  },
  watch: {
    $route(a, b) {
      this._getConsultas();
    },
  },
};
</script>