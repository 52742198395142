<template>
  <div class="ServiciosListado">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Servicios
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
        <ButtonAdd to="/servicio" />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="servicios"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.precio_ser="{ item }">
          {{$nF(item.precio_ser)}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item :to="'/servicio/'+item.id">
                <v-list-item-title v-text="'Editar'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
export default {
  name: "ServiciosListado",
  mixins: [standard],
  data() {
    return {
      servicios: [],
      headers: [
        { text: "Servicio", value: "desc_ser" },
        { text: "Precio", value: "precio_ser", align: "end" },
        { text: "Tipo", value: "tipo_ser" },
        { text: "Estatus", value: "estatus_ser" },
        { text: "", value: "actions", align: "end" },
      ],
    };
  },
  methods: {},
  created() {
    this._getAll("servicios");
  },
};
</script>