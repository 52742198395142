<template>
  <div>
    <ButtonAdd @click="showDialog = true" />
    <v-dialog
      v-model="showDialog"
      persistent
      scrollable
      width="400"
    >
      <v-card
        :disabled="cargando"
        :loading="cargando"
      >
        <v-card-title v-text="(usuario.id ? 'Editar' : 'Registrar') + ' usuario'" />
        <v-card-text>
          <v-text-field
            class="mt4"
            v-model="usuario.nombre_usr"
            :error-messages="errors.nombre_usr"
            label="Nombre*"
            filled
          />
          <v-text-field
            v-model="usuario.apePat_usr"
            :error-messages="errors.apePat_usr"
            label="Apellido paterno*"
            filled
          />
          <v-text-field
            v-model="usuario.apeMat_usr"
            :error-messages="errors.apeMat_usr"
            label="Apellido materno*"
            filled
          />
          <v-select
            v-model="usuario.tipo_usr"
            :error-messages="errors.tipo_usr"
            :items="tiposUsr"
            label="Tipo*"
            filled
          />
          <v-text-field
            v-if="
              usuario.tipo_usr === 'medico' ||
              usuario.tipo_usr === 'medico pasante'
            "
            v-model="usuario.escuela_usr"
            :error-messages="errors.escuela_usr"
            label="Escuela de egreso*"
            filled
          />
          <v-text-field
            v-if="
              usuario.tipo_usr === 'medico' ||
              usuario.tipo_usr === 'medico pasante'
            "
            v-model="usuario.cedulaProfesional_usr"
            :error-messages="errors.cedulaProfesional_usr"
            label="Cedula Profesional*"
            filled
          />
          <v-select
            v-if="usuario.tipo_usr === 'medico pasante'"
            v-model="usuario.tutor_usr"
            :error-messages="errors.tutor_usr"
            :items="tutores"
            item-text="nombre"
            item-value="id_usr"
            label="Tutor*"
            filled
          />
          <v-text-field
            v-model="usuario.usuario_usr"
            :error-messages="errors.usuario_usr"
            label="Usuario*"
            filled
          />
          <v-text-field
            v-model="usuario.password_usr"
            :error-messages="errors.password_usr"
            label="Contraseña"
            type="password"
            filled
          />
          <v-select
            v-model="usuario.estatus_usr"
            :error-messages="errors.estatus_usr"
            :items="estatusUsr"
            label="Estatus*"
            filled
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDialog = false"
            v-text="'Cancelar'"
          />
          <v-btn
            class="primary"
            @click="_preSaveUsr"
            v-text="usuario.id ? 'Actualizar' : 'Guardar'"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "UsuariosFormDialog",
  mixins: [forms],
  props: {
    usuario: Object,
    usuarios: Array,
  },
  components: {
    ButtonAdd: () => import("@/components/forms/buttons/ButtonAdd.vue"),
  },
  data() {
    return {
      showDialog: false,
      tiposUsr: [
        { text: "Administrativo", value: "administrativo" },
        { text: "Médico dentista", value: "medico" },
        { text: "Médico dentista pasante", value: "medico pasante" },
        { text: "Otro", value: "otro" },
      ],
      estatusUsr: [
        { text: "Activo", value: "activo" },
        { text: "Inactivo", value: "inactivo" },
      ],
      tutores: [],
      errors: {
        nombre_usr: null,
        apePat_usr: null,
        apeMat_usr: null,
        usuario_usr: null,
        tipo_usr: null,
        estatus_usr: null,
      },
    };
  },
  methods: {
    _preSaveUsr() {
      if (this._validateAll("usuario")) {
        const data = { usuario: this.usuario };
        this.usuario.id
          ? this._updateAll(
              "usuarios/" + this.usuario.id,
              data,
              "msg",
              "_updateUserData"
            )
          : this._saveAll("usuarios", data, "id", "_addUsrData");
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _addUsrData(res) {
      this.usuarios.push({
        id: res.id,
        id_usr: res.id_usr,
        id_cli_usr: this.usuario.id_cli_usr,
        nombre_usr: this.usuario.nombre_usr,
        nombre: this.usuario.nombre_usr + " " + this.usuario.apePat_usr,
        apePat_usr: this.usuario.apePat_usr,
        apeMat_usr: this.usuario.apeMat_usr,
        escuela_usr: this.usuario.escuela_usr,
        cedulaProfesional_usr: this.usuario.cedulaProfesional_usr,
        tutor_usr: this.usuario.tutor_usr,
        usuario_usr: this.usuario.usuario_usr,
        password_usr: this.usuario.password_usr,
        tipo_usr: this.usuario.tipo_usr,
        estatus_usr: this.usuario.estatus_usr,
      });
      this.$emit("msg", res.msg);
      this.showDialog = false;
    },
    _updateUserData(res) {
      let a = this.usuario.id;
      let i = this.usuarios.findIndex(function (b) {
        return b.id === a;
      });
      if (i >= 0) {
        this.usuarios[i].nombre =
          this.usuario.nombre_usr + " " + this.usuario.apePat_usr;
        this.usuarios[i].id_cli_usr = this.usuario.id_cli_usr;
        this.usuarios[i].nombre_usr = this.usuario.nombre_usr;
        this.usuarios[i].apePat_usr = this.usuario.apePat_usr;
        this.usuarios[i].apeMat_usr = this.usuario.apeMat_usr;
        this.usuarios[i].escuela_usr = this.usuario.escuela_usr;
        this.usuarios[i].cedulaProfesional_usr =
          this.usuario.cedulaProfesional_usr;
        this.usuarios[i].tutor_usr = this.usuario.tutor_usr;
        this.usuarios[i].usuario_usr = this.usuario.usuario_usr;
        this.usuarios[i].tipo_usr = this.usuario.tipo_usr;
        this.usuarios[i].estatus_usr = this.usuario.estatus_usr;
      }
      this.showDialog = false;
      this.$emit("msg", res.msg);
    },
    watchCambioUsr() {
      if (this.usuario.id) {
        this.showDialog = true;
      }
    },
  },
  watch: {
    usuario: {
      immediate: true,
      deep: true,
      handler: "watchCambioUsr",
    },
    showDialog(a) {
      if (!a) {
        this._limpiarCampos("usuario");
      }
    },
  },
  created() {},
};
</script>