<template>
  <div class="CitasCalendario">
    <v-card outlined>
      <v-toolbar flat>
        <v-btn
          class="ml4"
          icon
          @click="showDialogFiltros = true"
        >
          <v-icon>search</v-icon>
        </v-btn>
        <v-btn
          outlined
          color="primary"
          class="ml4"
          @click="_setToday"
          v-text="'Hoy'"
        />
        <v-btn
          icon
          @click="prev"
          class="ml4"
        >
          <v-icon v-text="'keyboard_arrow_left'" />
        </v-btn>
        <v-btn
          icon
          @click="next"
        >
          <v-icon v-text="'keyboard_arrow_right'" />
        </v-btn>
        <v-toolbar-title
          v-if="$refs.calendar"
          v-text="$refs.calendar.title"
        />
        <v-spacer />
        <v-menu
          bottom
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ typeToLabel[type] }}</span>
              <v-icon
                right
                v-text="'expand_more'"
              />
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title v-text="'Diario'" />
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title v-text="'Semanal'" />
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title v-text="'Mensual'" />
            </v-list-item>
          </v-list>
        </v-menu>

        <button-add
          style="margin-left: 8px"
          @click="showDialogCita = true"
        />
      </v-toolbar>
      <v-calendar
        flat
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="citas"
        :type="type"
        @click:event="_showCita"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="_updateRange"
      />
    </v-card>
    <citas-form-dialog
      v-model="showDialogCita"
      :id="idCitaEditar"
      @msg="$emit('msg',$event)"
      @httpError="$emit('httpError',$event)"
      @refresh="_getCitas"
    />
    <v-dialog
      v-model="showDialogFiltros"
      width="400"
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title>Filtrar calendario</v-card-title>
        <v-card-text>
          <v-text-field
            filled
            label="Buscar pacientes con nombre..."
            v-model="prefiltro.nombre"
            clearable
          />
          <v-select
            v-model="prefiltro.estatus"
            clearable
            filled
            :items="[
              { text: 'Programada', value: 'programada' },
              { text: 'Confirmada', value: 'confirmada' },
              { text: 'Cancelada', value: 'cancelada' },
            ]"
            label="Estatus de la cita"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDialogFiltros=false"
          >Cerrar</v-btn>
          <v-btn
            @click="_aplicarFiltros"
            color="primary"
            :loading="cargando"
          >
            Aplicar filtros
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import ButtonAdd from "@/components/forms/buttons/ButtonAdd.vue";
import CitasFormDialog from "@/components/citas/CitasFormDialog.vue";
export default {
  name: "CitasCalendario",
  mixins: [standard, forms],
  components: { ButtonAdd, CitasFormDialog },
  data() {
    return {
      inicio: null, //guardamos el rango de fechas del calendario
      fin: null, //guardamos el rango de fechas del calendario
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Mensual",
        week: "Semanal",
        day: "Diario",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      citas: [],
      cargando: false,
      idCitaEditar: null,
      showDialogCita: false,
      prefiltro: {
        nombre: null,
        estatus: null,
      },
      filtro: {
        nombre: null,
        estatus: null,
      },
      showDialogFiltros: false,
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    _setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    _showCita({ event }) {
      this.idCitaEditar = event.id;
      this.showDialogCita = true;
    },
    _updateRange({ start, end }) {
      this.inicio = start.date;
      this.fin = end.date;
      this._getCitas();
    },
    _getCitas() {
      var queryString = Object.keys(this.filtro)
        .map((key) => key + "=" + this.filtro[key])
        .join("&");
      this._getThings(
        "citas?inicio=" + this.inicio + "&fin=" + this.fin + "&" + queryString,
        "citas"
      );
    },
    _aplicarFiltros() {
      const { nombre, estatus } = this.prefiltro;
      this.filtro.nombre = nombre;
      this.filtro.estatus = estatus;
      this._getCitas();
    },
  },
  watch: {
    showDialogCita(a) {
      if (!a) {
        this.idCitaEditar = null;
      }
    },
  },
};
</script>
