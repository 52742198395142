<template>
  <v-dialog
    persistent
    dark
    v-model="showDialog"
    width="400"
  >
    <v-card :color="$error">
      <v-card-title v-text="errorMessage" />
      <v-card-text v-if="errorMessageDetail">
        {{errorMessageDetail}}
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          v-text="'Cancelar'"
          @click="_closeDialog"
        />
        <v-btn
          light
          class="white"
          text
          @click="$emit('confirmDelete',Date.now())"
          v-text="'Si, eliminar'"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    showDialog: Boolean,
    errorMessage: String,
    errorMessageDetail: String,
  },
  methods: {
    _closeDialog() {
      this.showDialog = false;
      this.$emit("closed", Date.now());
    },
  },
};
</script>