var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title",domProps:{"textContent":_vm._s('Dental Master')}})],1)],1),_c('v-divider'),_c('v-list-item-group',{attrs:{"active-class":"primary","color":"white"}},[_c('ListItemMenu',{attrs:{"icon":"home","title":"Inicio","to":"/"}}),_c('ListItemMenu',{attrs:{"icon":"today","title":"Citas","to":"/citas"}}),_c('ListGroupMenu',{attrs:{"icon":"settings","title":"Configuraciones","items":[
      {text:'Misión',to:'/configuracion/mision'},
      {text:'Visión',to:'/configuracion/vision'},
      {text:'Aviso de privacidad',to:'/configuracion/avisodeprivacidad'},
      {text:'Términos y condiciones',to:'/configuracion/terminosycondiciones'},
      {text:'Consentimientos informados',to:'/consentimientos'} ]}}),_c('ListGroupMenu',{attrs:{"icon":"home_repair_service","title":"Deposito dental","items":[
      {text:'Materiales',to:'/depositodental/materiales'},
      {text:'Categorias',to:'/depositodental/categorias'} ]}}),_c('ListItemMenu',{attrs:{"icon":"cake","title":"Cumpleaños","to":"/cumpleanos"}}),_c('ListItemMenu',{attrs:{"icon":"list_alt","title":"Materiales","to":"/materiales"}}),_c('ListGroupMenu',{attrs:{"icon":"loyalty","title":"Servicios","items":[
      {text:'Servicios registrados',to:'/servicios'},
      {text:'Registrar servicio',to:'/servicio'} ]}}),_c('ListGroupMenu',{attrs:{"icon":"supervisor_account","title":"Pacientes","items":[
      {text:'Pacientes registrados',to:'/pacientes'},
      {text:'Registrar paciente',to:'/paciente'} ]}}),_c('ListItemMenu',{attrs:{"icon":"local_shipping","title":"Proveedores","to":"/proveedores"}}),_c('ListGroupMenu',{attrs:{"icon":"ssid_chart","title":"Reportes","items":[
      {text:'Corte de caja',to:'/reportes/cortecaja'},
      {text:'Deudores',to:'/reportes/deudores'},
      {text:'Encuestas de satisfaccion',to:'/reportes/encuestas'},
      {text:'Garantías',to:'/reportes/garantias'} ]}}),_c('ListItemMenu',{attrs:{"icon":"supervised_user_circle","title":"Usuarios","to":"/usuarios"}}),_c('v-divider',{staticClass:"mt8 mb8"}),_c('ListItemMenu',{attrs:{"icon":"exit_to_app","title":"Cerrar sesión","to":"/login"},on:{"click":function($event){return this.$session.destroy()}}}),_c('v-list-item',{attrs:{"dense":"","disabled":""}},[_c('v-list-item-content',[_c('small',[_vm._v(_vm._s(_vm.$version))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }