var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"PacientesAntecedentesNoPatologicos",attrs:{"outlined":"","loading":_vm.cargando}},[_c('v-card-title',[_vm._v("Antecedentes no patológicos")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"12"}},[_c('v-select',{attrs:{"label":"Cepillo dental","items":[
            '1 vez al día',
            '2 veces al día',
            '3 veces al día',
            '4 + veces al día',
            'Ninguna' ],"filled":"","hint":"¿Con qué frecuencia el paciente se cepilla los dientes?","persistent-hint":""},on:{"blur":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos.frecuencia_cepillo_ano),callback:function ($$v) {_vm.$set(_vm.nopatologicos, "frecuencia_cepillo_ano", $$v)},expression:"nopatologicos.frecuencia_cepillo_ano"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"12"}},[_c('v-select',{attrs:{"label":"Accesorios de higiene bucal","items":[
            'Hilo dental',
            'Cepillo',
            'Interproximal',
            'Enjuague dental' ],"multiple":"","filled":"","hint":"¿Qué accesorios usa el paciente?","persistent-hint":""},on:{"blur":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos.accesorios_bucales_ano),callback:function ($$v) {_vm.$set(_vm.nopatologicos, "accesorios_bucales_ano", $$v)},expression:"nopatologicos.accesorios_bucales_ano"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"12"}},[_c('v-select',{attrs:{"label":"Consumo de golosinas","items":[
            '1 vez al día',
            '2 veces al día',
            '3 + veces al día',
            'Ninguna' ],"filled":"","hint":"Frecuencia con la que consume glosinas","persistent-hint":""},on:{"blur":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos.frecuencia_golosinas_ano),callback:function ($$v) {_vm.$set(_vm.nopatologicos, "frecuencia_golosinas_ano", $$v)},expression:"nopatologicos.frecuencia_golosinas_ano"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"12"}},[_c('v-select',{attrs:{"label":"Grupo sanguíneo","items":[
            'O negativo',
            'O positivo',
            'A negativo',
            'A positivo',
            'B negativo',
            'B positivo',
            'AB negativo',
            'AB positivo' ],"filled":""},on:{"blur":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos.grupo_sangre_ano),callback:function ($$v) {_vm.$set(_vm.nopatologicos, "grupo_sangre_ano", $$v)},expression:"nopatologicos.grupo_sangre_ano"}})],1)],1),_vm._l((_vm.campos),function(item,i){return _c('table',{key:i,staticStyle:{"min-width":"100%"}},[_c('tr',[_c('td',{attrs:{"width":"50%"},domProps:{"textContent":_vm._s(item.text)}}),_c('td',{attrs:{"width":"50%"}},[_c('v-radio-group',{attrs:{"row":""},on:{"change":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos[item.radio]),callback:function ($$v) {_vm.$set(_vm.nopatologicos, item.radio, $$v)},expression:"nopatologicos[item.radio]"}},[_c('v-radio',{attrs:{"label":"Si","value":"si"}}),_c('v-radio',{attrs:{"label":"No","value":"no"}})],1)],1)]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[(_vm.nopatologicos[item.radio] === 'si')?_c('v-text-field',{staticClass:"mt8",attrs:{"dense":"","label":'Notas del antecedente ' + item.text,"filled":""},on:{"blur":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos[item.input]),callback:function ($$v) {_vm.$set(_vm.nopatologicos, item.input, $$v)},expression:"nopatologicos[item.input]"}}):_vm._e(),(i + 1 < _vm.campos.length)?_c('v-divider'):_vm._e()],1)])])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }