import forms from "@/mixins/forms.js";
export default {
    mixins: [forms],
    components: {
        AutocompletePacientes: () =>
            import("@/components/forms/autocompletes/AutocompletePacientes.vue"),
        AutocompleteServicios: () =>
            import("@/components/forms/autocompletes/AutocompleteServicios.vue"),
        AutocompleteUsuarios: () =>
            import("@/components/forms/autocompletes/AutocompleteUsuarios.vue"),
        DatePicker: () => import("@/components/forms/pickers/DatePicker.vue"),
        TimePicker: () => import("@/components/forms/pickers/TimePicker.vue"),
    },
    data() {
        return {
            citas: [],
            showDialogCita: false,
            showDialogDeleteCita: false,
            cita: {
                id_cit: null,
                id_pac_cit: null,
                id_usr_cit: null,
                fecha_cit: null,
                hora_cit: null,
                estatus_cit: "programada",
                created_cit: null,
                servicios: [],
                feriado_cit: 'no',
            },
            errors: {
                id_pac_cit: null,
                id_usr_cit: null,
                fecha_cit: null,
                hora_cit: null,
            },
        }
    },
    methods: {
        _preSaveCita() {
            if (this.cita.feriado_cit == 'si' || this._validateAll("cita")) {
                const data = {
                    cita: this.cita,
                    servicios: this.cita.servicios,
                };
                this.cita.id
                    ? this._updateAll(
                        "citas/" + this.cita.id,
                        data,
                        "msg",
                        "_citaSavedUpdated"
                    )
                    : this._saveAll("citas", data, "id", "_citaSavedUpdated");
            } else {
                this.$emit("msg", this.$lleneCampos);
            }
        },
        _citaSavedUpdated(res) {
            if ("msg" in res) {
                this.$emit("msg", res.msg);
                this.$emit('refresh', true)
                this.$emit('id', this.cita.id ? this.cita.id_cit : res.id_cit)
                this.showDialogDeleteCita = false;
                this.showDialogCita = false;
            } else {
                this.$emit("msg", res.error);
            }
        },
        _checkIfFeriado() {
            if (this.cita.feriado_cit === "si") {
                this.cita.estatus_cit = "feriado";
                this.cita.hora_cit = '00:00:01';
                this.cita.id_pac_cit = -1;
                this.cita.id_usr_cit = -1;
            } else {
                this.cita.estatus_cit = "programada";
                this.cita.hora_cit = null;
                this.cita.id_pac_cit = null;
                this.cita.id_usr_cit = null;
            }
        },
        _deleteCita(idCit) {
            this._deleteAll('citas/' + idCit, 'msg', '_citaSavedUpdated')
        },
    },
    watch: {
        showDialogCita(a) {
            if (a && !this.cita.id) {
                this.showDialogDeleteCita = false
                this.cita.fecha_cit = this.$Datetime.now().toISODate();
            } else {
                this._limpiarCampos('cita')
            }
            this.cita.id_pac_cit = this.paciente.id_pac
            this.$emit("input", a);
        },
    },
}