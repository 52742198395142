
<template>
  <div class="PacientesHistoriaClinica">
    <PacientesInfoGeneral
      :id="idPac"
      showEdit
      @msg="$emit('msg', $event)"
      @httpError="$emit('httpError', $event)"
      class="mb24"
    />
    <v-tabs
      centered
      background-color="primary"
      dark
      active-class="secondary"
      show-arrows
    >
      <v-tab
        v-for="(r,idxr) in rutas"
        :key="idxr"
        v-text="r.text"
        :to="ruta+r.to"
      >
      </v-tab>
    </v-tabs>
    <router-view
      class="mt24"
      :id="idPac"
      @msg="$emit('msg', $event)"
      @httpError="$emit('httpError', $event)"
    />
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
export default {
  name: "PacientesHistoriaClinica",
  mixins: [standard],
  components: {
    PacientesInfoGeneral: () =>
      import("@/components/pacientes/PacientesInfoGeneral.vue"),
  },
  data() {
    return {
      idPac: null,
      ruta: "/paciente/historiaclinica/",
      rutas: [
        { text: "Citas", to: "citas" },
        { text: "Pagos", to: "pagos/incompletos" },
        { text: "Antecedentes", to: "antecedentes" },
        { text: "Consentimientos", to: "consentimientos" },
        { text: "Consultas", to: "consultas" },
        { text: "Odontogramas", to: "odontogramas" },
        { text: "Plan de tratamiento", to: "planesdetratamiento" },
        { text: "Galería de tratamiento", to: "galeriadetratamiento" },
        { text: "Garantías", to: "garantias" },
      ],
    };
  },
  methods: {},
  created() {
    this.idPac = this.$route.params.idPac;
    this.ruta += this.idPac + "/";
  },
};
</script>
