<template>
  <div class="PacienteDetalleAntecedentes">
    <v-card-title v-text="'Antecedentes'" />
    <v-card-subtitle v-text="'Toda acción realizada en los antecedentes se guarda automáticamente'" />
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="4"
      >
        <PacientesAntecedentesAlergias
          class="mb24"
          :paciente="idPac"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="4"
      >
        <PacientesAntecedentesHeredofamiliares
          class="mb24"
          :paciente="idPac"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="4"
      >
        <PacientesAntecedentesNoPatologicos
          class="mb24"
          :paciente="idPac"
        />
      </v-col>
      <v-col cols="12">
        <PacientesAntecedentesPatologicos
          class="mb24"
          :paciente="idPac"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PacientesAntecedentesAlergias from "@/components/pacientes/antecedentes/PacientesAntecedentesAlergias.vue";
import PacientesAntecedentesHeredofamiliares from "@/components/pacientes/antecedentes/PacientesAntecedentesHeredofamiliares.vue";
import PacientesAntecedentesNoPatologicos from "@/components/pacientes/antecedentes/PacientesAntecedentesNoPatologicos.vue";
import PacientesAntecedentesPatologicos from "@/components/pacientes/antecedentes/PacientesAntecedentesPatologicos.vue";
export default {
  name: "PacienteDetalleAntecedentes",
  components: {
    PacientesAntecedentesAlergias,
    PacientesAntecedentesHeredofamiliares,
    PacientesAntecedentesNoPatologicos,
    PacientesAntecedentesPatologicos,
  },
  data() {
    return {
      idPac: null,
    };
  },
  methods: {
    _handleIdPac() {
      this.idPac = this.$route.params.idPac;
    },
  },
  created() {
    this._handleIdPac();
  },
  watch: {
    $route() {
      this._handleIdPac();
    },
  },
};
</script>