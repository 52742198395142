export default {
    data() {
        return {
            cargando: false,
            estados: [],
            municipios: []
        }
    },
    methods: {
        _getEdos() {
            this.cargando = true;
            this.$http.get("data/estados").then(
                res => {
                    this.cargando = false;
                    if (res.status === 200 && "estados" in res.body) {
                        this.estados = res.body.estados;
                    } else {
                        this.$emit("httpError", res);
                    }
                },
                err => {
                    this.cargando = false;
                    this.$emit("httpError", err);
                }
            );
        },
        _getMpios(a = '') {
            this.cargando = true;
            this.$http.get("data/municipios" + a, this.$headers).then(
                res => {
                    this.cargando = false;
                    if (res.status === 200 && "municipios" in res.body) {
                        this.municipios = res.body.municipios;
                    } else {
                        this.$emit("httpError", res);
                    }
                },
                err => {
                    this.cargando = false;
                    this.$emit("httpError", err);
                }
            );
        }
    }
}