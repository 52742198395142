<template>
  <v-list
    nav
    dense
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title
          class="title"
          v-text="'Dental Master'"
        />
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item-group
      active-class="primary"
      color="white"
    >
      <ListItemMenu
        icon="home"
        title="Inicio"
        to="/"
      />
      <ListItemMenu
        icon="today"
        title="Citas"
        to="/citas"
      />
      <ListGroupMenu
        icon="settings"
        title="Configuraciones"
        :items="[
        {text:'Misión',to:'/configuracion/mision'},
        {text:'Visión',to:'/configuracion/vision'},
        {text:'Aviso de privacidad',to:'/configuracion/avisodeprivacidad'},
        {text:'Términos y condiciones',to:'/configuracion/terminosycondiciones'},
        {text:'Consentimientos informados',to:'/consentimientos'},
        ]"
      />
      <ListGroupMenu
        icon="home_repair_service"
        title="Deposito dental"
        :items="[
        {text:'Materiales',to:'/depositodental/materiales'},
        {text:'Categorias',to:'/depositodental/categorias'},
        ]"
      />
      <ListItemMenu
        icon="cake"
        title="Cumpleaños"
        to="/cumpleanos"
      />
      <ListItemMenu
        icon="list_alt"
        title="Materiales"
        to="/materiales"
      />
      <ListGroupMenu
        icon="loyalty"
        title="Servicios"
        :items="[
        {text:'Servicios registrados',to:'/servicios'},
        {text:'Registrar servicio',to:'/servicio'},
        ]"
      />
      <ListGroupMenu
        icon="supervisor_account"
        title="Pacientes"
        :items="[
        {text:'Pacientes registrados',to:'/pacientes'},
        {text:'Registrar paciente',to:'/paciente'},
        ]"
      />
      <ListItemMenu
        icon="local_shipping"
        title="Proveedores"
        to="/proveedores"
      />
      <ListGroupMenu
        icon="ssid_chart"
        title="Reportes"
        :items="[
        {text:'Corte de caja',to:'/reportes/cortecaja'},
        {text:'Deudores',to:'/reportes/deudores'},
        {text:'Encuestas de satisfaccion',to:'/reportes/encuestas'},
        {text:'Garantías',to:'/reportes/garantias'},
        ]"
      />
      <ListItemMenu
        icon="supervised_user_circle"
        title="Usuarios"
        to="/usuarios"
      />
      <v-divider class="mt8 mb8" />
      <ListItemMenu
        @click="this.$session.destroy()"
        icon="exit_to_app"
        title="Cerrar sesión"
        to="/login"
      />
      <v-list-item
        dense
        disabled
      >
        <v-list-item-content><small>{{$version}}</small></v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
import ListItemMenu from "@/components/lists/ListItemMenu";
import ListGroupMenu from "@/components/lists/ListGroupMenu";
export default {
  name: "AppMenu",
  components: { ListItemMenu, ListGroupMenu },
  data() {
    return {
      color: "#fafafa",
    };
  },
};
</script>
