<template>
  <div class="DepositoCategoriaListado">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Categorías del depósito dental
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
        <deposito-categoria-form-dialog
          @msg="$emit('msg',$event)"
          @httpError="$emit('httpError',$event)"
          @refresh="_getCategorias"
          v-model="idDca"
        />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="categorias"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item @click="idDca=item.id">
                <v-list-item-title v-text="'Editar'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import DepositoCategoriaFormDialog from "@/components/depositodental/DepositoCategoriaFormDialog.vue";
export default {
  name: "DepositoCategoriaListado",
  mixins: [standard, forms],
  components: {
    DepositoCategoriaFormDialog,
  },
  methods: {
    _getCategorias() {
      this.idDca = null;
      this._getThings("depositodental/categorias", "categorias");
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Nombre", value: "nombre_dca" },
        { text: "Descripción", value: "descripcion_dca" },
        { text: "", value: "actions", align: "end" },
      ],
      categorias: [],
      idDca: null,
    };
  },
  created() {
    this._getCategorias();
  },
};
</script>