<template>
  <div class="ConsultasForm">
    <PacientesInfoGeneral
      :id="idPac"
      @msg="$emit('msg', $event)"
      @httpError="$emit('httpError', $event)"
      class="mb24"
      showEdit
      showMore
    />
    <v-row class="mt16">
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <v-card
          outlined
          :disabled="cargando || disabled"
          :loading="cargando"
        >
          <v-card-title>Motivo de la consulta</v-card-title>
          <v-card-text>
            <v-textarea
              rows="2"
              label="Ingrese aquí el motivo de la consulta"
              filled
              v-model="consulta.motivo_con"
              :error-messages="errors.motivo_con"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <v-card
          outlined
          :disabled="cargando || disabled"
          :loading="cargando"
        >
          <v-card-title>Síntomas subjetivos</v-card-title>
          <v-card-text>
            <v-textarea
              rows="2"
              label="Ingrese aquí los síntomas subjetivos"
              filled
              v-model="consulta.sintomas_con"
              :error-messages="errors.sintomas_con"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <v-card
          outlined
          :disabled="cargando || disabled"
          :loading="cargando"
        >
          <v-card-title>Exploración física</v-card-title>
          <v-card-text>
            <v-textarea
              rows="2"
              label="Ingrese aquí la exploración física"
              filled
              v-model="consulta.exploracion_con"
              :error-messages="errors.exploracion_con"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <v-card
          outlined
          :disabled="cargando || disabled"
          :loading="cargando"
        >
          <v-card-title>Diagnóstico</v-card-title>
          <v-card-text>
            <AutocompleteCie10
              v-model="consulta.id_cie_con"
              label="Diagnóstico CIE10"
              :error="errors.id_cie_con"
            />
            <v-textarea
              rows="2"
              label="Ingrese aquí el diagnóstico"
              filled
              v-model="consulta.diagnostico_con"
              :error-messages="errors.diagnostico_con"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <v-card
          outlined
          :disabled="cargando || disabled"
          :loading="cargando"
        >
          <v-card-title>Tratamiento</v-card-title>
          <v-card-text>
            <v-textarea
              rows="2"
              label="Ingrese aquí el tratamiento"
              filled
              v-model="consulta.tratamiento_con"
              :error-messages="errors.tratamiento_con"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <v-card
          outlined
          :disabled="cargando || disabled"
          :loading="cargando"
        >
          <v-card-title>Receta</v-card-title>
          <v-card-text>
            <v-textarea
              rows="2"
              label="Ingrese aquí la receta"
              filled
              v-model="consulta.receta_con"
              :error-messages="errors.receta_con"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        v-text="'Cancelar'"
        to="/pacientes"
      />
      <v-btn
        class="primary"
        @click="_saveConsulta"
        :disabled="cargando || disabled"
        :loading="cargando"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import PacientesInfoGeneral from "@/components/pacientes/PacientesInfoGeneral.vue";
import AutocompleteCie10 from "@/components/forms/autocompletes/AutocompleteCie10.vue";
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
export default {
  name: "ConsultasForm",
  mixins: [standard, forms],
  components: {
    PacientesInfoGeneral,
    AutocompleteCie10,
  },
  data() {
    return {
      idPac: null,
      consulta: {
        id: null,
        id_con: null,
        id_cie_con: null,
        motivo_con: null,
        sintomas_con: null,
        exploracion_con: null,
        diagnostico_con: null,
        tratamiento_con: null,
        receta_con: null,
        id_pac_con: null,
        fecha_con: null,
      },
      errors: {
        motivo_con: null,
      },
      disabled: false,
    };
  },
  methods: {
    _setIdPac() {
      this.idPac = this.$route.params.idPac;
      if (this.$route.params.idCon) {
        this._getThings("consulta/" + this.$route.params.idCon, "consulta");
      } else {
        this.consulta.id_pac_con = this.$route.params.idPac;
      }
    },
    _saveConsulta() {
      console.log(this.consulta);
      if (this._validateAll("consulta")) {
        const data = { consulta: this.consulta };
        this._saveAll("consulta", data, "id", "_handleWhereToRedirect");
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _handleWhereToRedirect(res) {
      this.$emit("msg", res.msg);
      this.$router.push(
        "receta" in res
          ? "/receta/" + res.receta
          : "/paciente/historiaclinica/" + this.idPac
      );
    },
  },
  created() {
    this._setIdPac();
    this.disabled = this.$route.params.idCon ? true : false;
  },
  watch: {
    $route(a, b) {
      this._setIdPac();
    },
  },
};
</script>
