<template>
  <div class="PacienteDetalleConsentimientos">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Consentimientos
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
        <v-btn
          icon
          class="primary"
          dark
          @click="showDialogConsentimiento = true"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="consentimientos"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.fecha="{ item }">
          {{_doDiaMesHoras(item.fecha)}}
        </template>
        <!-- <template v-slot:item.estatus_cit="{ item }">
            <v-chip
              :color="item.color"
              dark
              small
            >
              {{ item.estatus_cit }}
            </v-chip>
          </template> -->
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            v-if="item.evidencia_cns"
            @click="evidenciaImg = item.evidencia_cns;showDialogEvidencia=true;"
          >
            <v-icon>gesture</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="item.adicional_cns"
            :href="$raiz+'uploads/consentimientos/'+item.adicional_cns"
            target="_blank"
          >
            <v-icon>post_add</v-icon>
          </v-btn>

        </template>
      </v-data-table>
    </v-card>
    <div
      style="display:none; font-family:Helvetica,Arials"
      id="printMe"
      class="printMe"
    >
      <img
        style="align:center!important"
        src="@/assets/logo.svg"
        width="180px"
      />
      <h3>Consentimiento informado {{folioConsentimiento}}</h3>
      <span v-html="contenido" />
    </div>
    <v-dialog
      v-model="showDialogConsentimiento"
      width="400"
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title v-text="'¿Estás seguro deseas registrar un nuevo consentimiento informado al paciente?'" />
        <v-card-text>
          <v-select
            label="Formato de consentimiento*"
            v-model="cinformadoSelect"
            :items="cinformados"
            filled
            item-text="nombre_cfo"
            item-value="id_cfo"
            @change="_handleContentConsentimiento"
          />
          Al dar click en <strong>"continuar"</strong>, se abrirá una ventana con el formato de consentimiento para ser leído y firmado por el paciente.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cancelar'"
            @click="showDialogConsentimiento=false"
          />
          <v-btn
            class="primary"
            v-text="'Continuar'"
            :disabled="!cinformadoSelect"
            @click="showDialogConsentimientoContent=true"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      fullscreen
      persistent
      scrollable
      v-model="showDialogConsentimientoContent"
    >
      <v-card>
        <v-card-title>Consentimiento informado</v-card-title>
        <v-card-text>
          <div v-html="contenido" />
          <v-divider />
          <div ref="containerConsentimiento">
            <vue-drawing-canvas
              ref="firmaConsentimientoInformado"
              canvasID="firmaConsentimientoInformado"
              class="canvas firmaConsentimientoInformado"
              :image.sync="imgSync"
              :width="width"
              :height="height"
            />
            <v-btn-toggle>
              <v-btn @click="$refs.firmaConsentimientoInformado.undo()">
                <v-icon>undo</v-icon>
              </v-btn>
              <v-btn @click="$refs.firmaConsentimientoInformado.redo()">
                <v-icon>redo</v-icon>
              </v-btn>
              <v-btn @click="$refs.firmaConsentimientoInformado.reset()">
                <v-icon>clear</v-icon>
              </v-btn>
            </v-btn-toggle><br>
            <v-file-input
              class="mt24"
              filled
              label="Evidencia adicional"
              v-model="file"
              @change="fileData=$event"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cerrar'"
            @click="showDialogConsentimientoContent=false"
          />
          <v-btn
            class="primary"
            v-text="'Guardar'"
            @click="_guardarConsentimiento"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="400"
      v-model="showDialogEvidencia"
    >
      <v-card>
        <v-card-title v-text="'Firma del paciente'" />
        <v-img :src="evidenciaImg"></v-img>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cerrar'"
            @click="showDialogEvidencia=false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.canvas {
  border: 1px solid black;
  border-radius: 8px;
}
</style>
<script>
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
import VueDrawingCanvas from "vue-drawing-canvas";
export default {
  name: "PacienteDetalleConsentimientos",
  props: {
    id: String,
  },
  mixins: [forms, dates],
  components: { VueDrawingCanvas },
  data() {
    return {
      search: "",
      headers: [
        { text: "Fecha", value: "fecha" },
        { text: "Generado por", value: "medico" },
        { text: "Estatus", value: "estatus" },
        { text: "", value: "actions", align: "end" },
      ],
      showDialogConsentimiento: false,
      showDialogConsentimientoContent: false,
      showDialogEvidencia: false,
      idConsentimientoEvidencia: null,
      paciente: null,
      consentimientos: [],
      contenido: "",
      folioConsentimiento: "",
      file: null,
      fileData: null,
      width: "300",
      height: "800",
      imgSync: "",
      evidenciaImg: null,
      cinformados: [],
      cinformadoSelect: null,
    };
  },
  methods: {
    _getConsentimientos(res) {
      if (res) {
        if ("msg" in res) this.$emit("msg", res.msg);
        this.fileData = null;
        this.file = null;
        this.showDialogEvidencia = false;
        this.showDialogConsentimiento = false;
        this.showDialogConsentimientoContent = false;
      }
      this._getThings(
        "consentimientos/paciente/" + this.$route.params.idPac,
        "consentimientos"
      );
    },
    async _getConsentimientosInformados(res) {
      try {
        const { body } = await this.$http.get(
          "consentimientosinformados",
          this.$headers
        );
        this.cinformados = body.consentimientos;
      } catch (e) {
        this.$emit("httpError", e);
      }
    },
    _handleContentConsentimiento() {
      this.contenido = "";
      const a = this.cinformadoSelect;
      const b = this.cinformados.findIndex((e) => e.id_cfo == a);
      if (b >= 0) this.contenido = this.cinformados[b].contenido_cfo;
    },
    _handleDoPrint(res) {
      this.folioConsentimiento = res.id_cns;
      this.$emit("msg", res.msg);
      let self = this;
      setTimeout(() => this.$htmlToPaper("printMe"), 200);
    },
    async resizeCanvas() {
      if (this.showDialogConsentimientoContent) {
        await this.$nextTick();
        this.width = this.$refs.containerConsentimiento.offsetWidth;
        // this.height = this.$refs.containerConsentimiento.offsetWidth;
      }
    },
    // _guardarConsentimiento() {
    //   this.cargando = true;
    //   const data = {
    //     consentimiento: {
    //       id_pac_cns: this.paciente,
    //       evidencia_cns: this.imgSync,
    //     },
    //   };
    //   this._saveAll("consentimientos", data, "id", "_getConsentimientos");
    // },
    async _guardarConsentimiento() {
      this.cargando = true;
      try {
        let data = new FormData();
        data.append("imagen", this.file);
        data.append("imagen2", this.fileData);
        data.append("id_pac_cns", this.paciente);
        data.append("evidencia_cns", this.imgSync);
        const { body } = await this.$http.post(
          "consentimiento",
          data,
          this.$headers
        );
        this.cargando = false;
        "msg" in body
          ? this._getConsentimientos(body)
          : this.$emit("msg", body.error);
      } catch (err) {
        this.$emit("httpError", err);
        this.cargando = false;
      }
    },
  },
  created() {
    this.paciente = this.$route.params.idPac;
    this._getConsentimientos();
    this._getConsentimientosInformados();
    // this._getConsentimientoTexto();
  },
  mounted() {
    window.addEventListener("resize", this.resizeCanvas);
    this.resizeCanvas();
  },
  watch: {
    showDialogConsentimientoContent(a) {
      if (a) {
        this.resizeCanvas();
      } else {
        this.imgSync = null;
      }
    },
    showDialogEvidencia(a) {
      if (!a) this.evidenciaImg = false;
    },
  },
};
</script>