<template>
  <div class="OdontogramaDetalle">
    <v-dialog
      width="800"
      v-model="showDialogDetalle"
      scrollable
    >
      <v-card>
        <v-card-title>Detalle del odontograma</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              Superior Izquierdo
              <div
                v-for="n in 8"
                :key="n"
                class="mt16"
              ><label><strong>Diente 1{{n}}</strong></label><br>
                Anotaciones: {{odontograma['si'+n+'_odo']}}</div>
              <v-divider />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              Superior Derecho
              <div
                v-for="n in 8"
                :key="n"
                class="mt16"
              ><label><strong>Diente 2{{n}}</strong></label><br>
                Anotaciones: {{odontograma['sd'+n+'_odo']}}</div>
              <v-divider />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              Inferior Izquierdo
              <div
                v-for="n in 8"
                :key="n"
                class="mt16"
              ><label><strong>Diente 4{{n}}</strong></label><br>
                Anotaciones: {{odontograma['ii'+n+'_odo']}}</div>
              <v-divider />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              Inferior Derecho
              <div
                v-for="n in 8"
                :key="n"
                class="mt16"
              ><label><strong>Diente 4{{n}}</strong></label><br>
                Anotaciones: {{odontograma['id'+n+'_odo']}}</div>
              <v-divider />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cerrar'"
            @click="showDialogDetalle=false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "OdontogramaDetalle",
  mixins: [forms],
  props: {
    value: String, //este es el id del odontograma a consutlar
  },
  data() {
    return {
      odontograma: {
        id_odo: null,
        id_pac_odo: null,
        id_usr_odo: null,
        fecha_odo: null,
        si1_odo: null,
        si2_odo: null,
        si3_odo: null,
        si4_odo: null,
        si5_odo: null,
        si6_odo: null,
        si7_odo: null,
        si8_odo: null,
        sd1_odo: null,
        sd2_odo: null,
        sd3_odo: null,
        sd4_odo: null,
        sd5_odo: null,
        sd6_odo: null,
        sd7_odo: null,
        sd8_odo: null,
        ii1_odo: null,
        ii2_odo: null,
        ii3_odo: null,
        ii4_odo: null,
        ii5_odo: null,
        ii6_odo: null,
        ii7_odo: null,
        ii8_odo: null,
        id1_odo: null,
        id2_odo: null,
        id3_odo: null,
        id4_odo: null,
        id5_odo: null,
        id6_odo: null,
        id7_odo: null,
        id8_odo: null,
      },
      errors: {
        id: null,
      },
      showDialogDetalle: false,
    };
  },
  methods: {
    _getOdontograma() {
      if (this.value)
        this._getThings("odontograma/" + this.value, "odontograma");
    },
    _checkIfOdontogramaHasData() {
      if (this.odontograma.id) {
        this.showDialogDetalle = true;
      } else {
        this.$emit("input", null);
      }
    },
  },
  watch: {
    value(a) {
      this._getOdontograma();
    },
    showDialogDetalle(a) {
      if (!a) {
        this.$emit("input", null);
        this._limpiarCampos("odontograma");
      }
    },
    odontograma: {
      deep: true,
      immediate: true,
      handler: "_checkIfOdontogramaHasData",
    },
  },
};
</script>