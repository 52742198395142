<template>
  <div class="ReportesEncuestas">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Encuestas de satisfacción del {{_doDiaMes(inicio)}} al {{_doDiaMes(fin)}}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <date-picker
              v-model="preInicio"
              label="Inicio"
              @change="_setFechas"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <date-picker
              v-model="preFin"
              label="Fin"
              @change="_setFechas"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="encuestas"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.importe="{ item }">
          {{$nF(item.importe)}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item>
                <v-list-item-title v-text="'Ver más'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <br><br>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="3"
          v-for="p in preguntas"
          :key="p"
        >
          <bar-chart
            :chart-id="p"
            :datasetIdKey="p"
            :chartData="data[p]"
          />
          <!-- {{data[p]}} -->
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
import BarChart from "@/components/BarChart.vue";
export default {
  name: "ReportesEncuestas",
  mixins: [standard, forms, dates],
  components: { DatePicker, BarChart },
  data() {
    return {
      headers: [
        { text: "Fecha", value: "created_enc" },
        { text: "Servicio", value: "pregunta_a_enc" },
        { text: "Explicacion", value: "pregunta_b_enc" },
        { text: "Recomendaria", value: "pregunta_c_enc" },
        { text: "Conoció", value: "pregunta_d_enc" },
        { text: "Comentario", value: "pregunta_e_enc" },
      ],
      encuestas: [],
      preguntas: [
        "pregunta_a_enc",
        "pregunta_b_enc",
        "pregunta_c_enc",
        "pregunta_d_enc",
      ],
      data: {
        pregunta_a_enc: [],
        pregunta_b_enc: [],
        pregunta_c_enc: [],
        pregunta_d_enc: [],
      },
    };
  },
  methods: {
    async _setFechas() {
      this.inicio = this.preInicio ? this.preInicio : this._today();
      this.fin = this.preFin ? this.preFin : this._today();
      this.cargando = true;
      try {
        const { body } = await this.$http.get(
          "reportes/encuestas?inicio=" + this.inicio + "&fin=" + this.fin,
          this.$headers
        );
        this.cargando = false;
        if ("encuestas" in body) {
          this.encuestas = body.encuestas;
          this.data.pregunta_a_enc = body.pregunta_a_enc;
          this.data.pregunta_b_enc = body.pregunta_b_enc;
          this.data.pregunta_c_enc = body.pregunta_c_enc;
          this.data.pregunta_d_enc = body.pregunta_d_enc;
        }
      } catch (err) {
        this._handleError(err);
      }
    },
  },
  created() {
    this.preInicio = this._today();
    this.preFin = this._today();
    this._setFechas();
  },
};
</script>