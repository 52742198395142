<template>
  <div class="UsuariosListado">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Usuarios
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
        <UsuariosFormDialog
          @msg="$emit('msg',$event)"
          @httpError="$emit('httpError',$event)"
          :usuarios="usuarios"
          :usuario="usuario"
        />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="usuarios"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item @click="_editUsr(item)">
                <v-list-item-title v-text="'Editar'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
export default {
  name: "UsuariosListado",
  mixins: [standard],
  components: {
    UsuariosFormDialog: () =>
      import("@/components/usuarios/UsuariosFormDialog.vue"),
  },
  methods: {
    _editUsr(a) {
      this.usuario.id = a.id;
      this.usuario.id_usr = a.id_usr;
      this.usuario.nombre_usr = a.nombre_usr;
      this.usuario.apePat_usr = a.apePat_usr;
      this.usuario.apeMat_usr = a.apeMat_usr;
      this.usuario.escuela_usr = a.escuela_usr;
      this.usuario.cedulaProfesional_usr = a.cedulaProfesional_usr;
      this.usuario.tutor_usr = a.tutor_usr;
      this.usuario.usuario_usr = a.usuario_usr;
      this.usuario.tipo_usr = a.tipo_usr;
      this.usuario.estatus_usr = a.estatus_usr;
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Tipo", value: "tipo_usr" },
        { text: "Estatus", value: "estatus_usr" },
        { text: "", value: "actions", align: "end" },
      ],
      usuarios: [],
      usuario: {
        id: null,
        id_usr: null,
        nombre_usr: null,
        apePat_usr: null,
        apeMat_usr: null,
        escuela_usr: null,
        cedulaProfesional_usr: null,
        tutor_usr: null,
        usuario_usr: null,
        password_usr: null,
        tipo_usr: null,
        estatus_usr: null,
      },
    };
  },
  created() {
    this._getAll("usuarios");
  },
};
</script>