<template>
  <div class="ConsentimientosForm">
    <v-card
      outlined
      :loading="cargando"
      :disabled="cargando"
    >
      <v-card-title v-text="'Consentimiento informado'" />
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="8"
          >
            <v-text-field
              label="Nombre del formato*"
              filled
              v-model="consentimiento.nombre_cfo"
              :error-messages="errors.nombre_cfo"
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-select
              filled
              label="Estatus*"
              v-model="consentimiento.estatus_cfo"
              :error-messages="errors.estatus_cfo"
              :items="[
                  { text: 'Activo', value: 'activo' },
                  { text: 'Inactivo', value: 'inactivo' },
                ]"
            />
          </v-col>
        </v-row>
        <quill-editor
          :content="consentimiento.contenido_cfo"
          :options="editorOption"
          @change="_handleContentChange($event)"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          v-text="'Cancelar'"
          to="/consentimientos"
        />
        <v-btn
          @click="_saveUpdateConsentimiento"
          color="primary"
          :loading="cargando"
        >
          {{consentimiento.id ? 'Actualizar':'Guardar'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
export default {
  name: "ConsentimientosForm",
  mixins: [standard, forms],
  components: { quillEditor },
  data() {
    return {
      contenido: "",
      contenidoUpdated: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
        theme: "snow",
      },
      consentimiento: {
        id: null,
        id_cfo: null,
        nombre_cfo: null,
        estatus_cfo: null,
        contenido_cfo: null,
      },
      errors: {
        nombre_cfo: null,
        estatus_cfo: null,
      },
    };
  },
  methods: {
    _saveUpdateConsentimiento() {
      if (this._validateAll("consentimiento")) {
        const data = { consentimiento: this.consentimiento };
        const h = "_handleResponseConsentimiento";
        const r = "consentimientosinformados/";
        this.consentimiento.id
          ? this._updateAll(r + this.consentimiento.id, data, "msg", h)
          : this._saveAll(r, data, "id", h);
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _handleContentChange({ html }) {
      this.consentimiento.contenido_cfo = html;
    },
    _handleResponseConsentimiento(res) {
      this.$emit("msg", res.msg);
      this.$router.push("/consentimientos");
    },
  },
  created() {
    if (this.$route.params.idCfo)
      this._getThings(
        "consentimientosinformados/" + this.$route.params.idCfo,
        "consentimiento"
      );
  },
};
</script>