var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":"","disabled":_vm.cargando,"loading":_vm.cargando}},[_c('v-tabs',{attrs:{"background-color":"accent","active-class":"secondary","fixed-tabs":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.estatus),function(status,idxstatus){return _c('v-tab',{key:idxstatus,attrs:{"to":_vm.ruta + status},domProps:{"textContent":_vm._s(_vm._doCustomText(status))}})}),1),(_vm.status != 'abono')?_c('div',[(_vm.status == 'incompleto')?_c('v-card-actions',{staticClass:"mb24"},[_c('v-spacer'),(_vm.cargos.length >= 1)?_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm._getSaldoPendiente()}}},[_vm._v(" Abonar "),_c('v-icon',[_vm._v("add")])],1):_vm._e()],1):_vm._e(),_c('v-data-table',{staticClass:"upper",attrs:{"headers":_vm.status == 'completo' ? _vm.headersCompleto : _vm.headers,"items":_vm.cargos,"loading":_vm.cargando,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"item.fecha_cgo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._doDiaMesHoras(item.fecha_cgo))+" ")]}},{key:"item.importe_cgo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$nF(item.importe_cgo))+" ")]}},{key:"item.pendiente_cgo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$nF(item.pendiente_cgo))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.status != 'cancelado')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('more_vert')}})],1)]}}],null,true)},[_c('v-list',{attrs:{"dark":""}},[_c('v-list-item',{on:{"click":function($event){return _vm._getCargoDetalle(item.id)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Ver detalle')}})],1),(_vm.status == 'incompleto')?_c('div',[_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm._getSaldoPendiente(item.id)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Abonar a este cargo')}})],1)],1):_vm._e(),(_vm.status == 'incompleto')?_c('div',[_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.cargoToDelete = item.id}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Cancelar cargo')}})],1)],1):_vm._e()],1)],1):_vm._e()]}}],null,false,812528042)})],1):_c('div',[_c('v-data-table',{staticClass:"upper",attrs:{"headers":_vm.headersAbono,"items":_vm.abonos,"loading":_vm.cargando,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"item.fecha_abo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._doDiaMesHoras(item.fecha_abo))+" ")]}},{key:"item.importe_abo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$nF(item.importe_abo))+" ")]}}])})],1)],1),_c('v-dialog',{attrs:{"scrollable":"","width":"400"},model:{value:(_vm.showDialogCargoDetalle),callback:function ($$v) {_vm.showDialogCargoDetalle=$$v},expression:"showDialogCargoDetalle"}},[_c('v-card',{attrs:{"loading":_vm.cargando,"disabled":_vm.cargando}},[_c('v-card-title',[_vm._v("Información del cargo")]),_c('v-list',[_c('v-subheader',{domProps:{"textContent":_vm._s('Productos y servicios del cargo')}}),_vm._l((_vm.cargo.servicios),function(ser){return _c('div',{key:ser.id_cse},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                  _vm.$nF(parseFloat(ser.precio_cse * ser.cantidad_cse)) +
                    ', ' +
                    ser.desc_ser
                )}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s('Cantidad: ' + ser.cantidad_cse)}})],1)],1),_c('v-divider')],1)})],2),('abonos' in _vm.cargo && _vm.cargo.abonos.length >= 1)?_c('v-list',[_c('v-subheader',[_vm._v("Abonos registrados al cargo")]),_vm._l((_vm.cargo.abonos),function(abc){return _c('div',{key:abc.id_abc},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Abono por ' + _vm.$nF(abc.importe_abo))}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm._doDiaMesHoras(abc.fecha_abo))}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s('Método de pago: ' + abc.metodo_pago_abo)}})],1)],1),_c('v-divider')],1)})],2):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},domProps:{"textContent":_vm._s('Cerrar')},on:{"click":function($event){_vm.showDialogCargoDetalle = false}}})],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","width":"400"},model:{value:(_vm.showDialogAbonar),callback:function ($$v) {_vm.showDialogAbonar=$$v},expression:"showDialogAbonar"}},[_c('v-card',{attrs:{"loading":_vm.cargando,"disabled":_vm.cargando}},[_c('v-card-title',{domProps:{"textContent":_vm._s('Abonar a la cuenta del cliente')}}),_c('h1',{staticClass:"h3 text-center"},[_vm._v(_vm._s(_vm.$nF(_vm.saldo)))]),_c('p',{staticClass:"text-center"},[_vm._v("Saldo pendiente")]),_c('v-card-text',[_c('v-text-field',{staticClass:"mt4",attrs:{"prepend-inner-icon":"attach_money","label":"Importe a abonar","min":"1","max":"saldo","outlined":""},model:{value:(_vm.abono.importe_abo),callback:function ($$v) {_vm.$set(_vm.abono, "importe_abo", $$v)},expression:"abono.importe_abo"}}),_c('v-select',{attrs:{"outlined":"","items":[
            { text: 'Efectivo', value: 'efectivo' },
            { text: 'Tarjeta', value: 'tarjeta' },
            { text: 'Transferencia', value: 'transferencia' },
            { text: 'Cheque', value: 'cheque' }
          ],"label":"Método de pago"},model:{value:(_vm.abono.metodo_pago_abo),callback:function ($$v) {_vm.$set(_vm.abono, "metodo_pago_abo", $$v)},expression:"abono.metodo_pago_abo"}}),_c('v-btn',{staticClass:"primary",attrs:{"block":"","disabled":!(_vm.abono.importe_abo >= 1 && _vm.abono.metodo_pago_abo)},domProps:{"textContent":_vm._s('Guardar abono')},on:{"click":_vm._guardarAbono}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},domProps:{"textContent":_vm._s('Cerrar')},on:{"click":function($event){_vm.showDialogAbonar = false}}})],1)],1)],1),_c('delete-dialog',{attrs:{"showDialog":_vm.showDialogConfirmDelete,"errorMessage":"¿Estas seguro deseas eliminar este concepto?","errorMessageDetail":"Eliminar este concepto también borrará toda informacón relacionada con sus abonos y no podrá ser revertido"},on:{"confirmDelete":_vm._cancelarCargo,"close":function($event){_vm.showDialogConfirmDelete = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }