<template>
  <div class="ReporteGarantias">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Garantias
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="garantias"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.fecha_cgo="{item}">
          {{_doDiaMes(item.fecha_cgo)}}
        </template>
        <template v-slot:item.caducidad="{item}">
          {{_doDiaMes(item.caducidad)}}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
export default {
  name: "ReporteGarantias",
  mixins: [standard, forms, dates],
  data() {
    return {
      garantias: [],
      headers: [
        { text: "Paciente", value: "paciente" },
        { text: "Servicio", value: "desc_ser" },
        { text: "Folio", value: "id_cgo" },
        { text: "Fecha de cargo", value: "fecha_cgo" },
        { text: "Vigencia garantía", value: "caducidad" },
        { text: "", value: "actions", align: "end" },
      ],
      idPac: null,
      value: {},
    };
  },
  methods: {},
  created() {
    this._getThings("garantias", "garantias");
  },
};
</script>