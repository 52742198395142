import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  }, {
    path: '/consulta/:idPac/:idCon?',
    name: 'ConsultasForm',
    component: () => import('@/views/consultas/ConsultasForm.vue')
  }, {
    path: '/citas',
    name: 'CitasCalendario',
    component: () => import('@/views/citas/CitasCalendario.vue')
  }, {
    path: '/configuracion/:tipo',
    name: 'ConfiguracionesForm',
    component: () => import('@/views/configuraciones/ConfiguracionesForm.vue')
  }, {
    path: '/consentimientos',
    name: 'ConsentimientosListado',
    component: () => import('@/views/consentimientos/ConsentimientosListado.vue')
  }, {
    path: '/consentimiento/:idCfo?',
    name: 'ConsentimientosForm',
    component: () => import('@/views/consentimientos/ConsentimientosForm.vue')
  }, {
    path: '/cumpleanos',
    name: 'CumpleanosCalendario',
    component: () => import('@/views/cumpleanos/CumpleanosCalendario.vue')
  }, {
    path: '/depositodental/materiales',
    name: 'DepositoDentalListado',
    component: () => import('@/views/depositodental/DepositoDentalListado.vue')
  }, {
    path: '/depositodental/categorias',
    name: 'DepositoCategoriaListado',
    component: () => import('@/views/depositodental/DepositoCategoriaListado.vue')
  }, {
    path: '/encuesta/:idPac?',
    name: 'EncuestaForm',
    component: () => import('@/views/encuesta/EncuestaForm.vue')
  }, {
    path: '/materiales',
    name: 'MaterialesListado',
    component: () => import('@/views/materiales/MaterialesListado.vue')
  }, {
    path: '/pacientes',
    name: 'PacientesListado',
    component: () => import('@/views/pacientes/PacientesListado.vue')
  }, {
    path: '/paciente/:idPac?',
    name: 'PacientesForm',
    component: () => import('@/views/pacientes/PacientesForm.vue')
  }, {
    path: '/paciente/historiaclinica/:idPac?',
    name: 'PacientesHistoriaClinica',
    component: () => import('@/views/pacientes/PacientesHistoriaClinica.vue'),
    children: [
      {
        path: 'citas',
        component: () => import('@/views/pacientes/detalle/PacientesDetalleCitas.vue'),
      }, {
        path: 'antecedentes',
        component: () => import('@/views/pacientes/detalle/PacientesDetalleAntecedentes.vue'),
      }, {
        path: 'consentimientos',
        component: () => import('@/views/pacientes/detalle/PacientesDetalleConsentimientos.vue'),
      }, {
        path: 'consultas',
        component: () => import('@/views/pacientes/detalle/PacientesDetalleConsultas.vue'),
      }, {
        path: 'odontogramas',
        component: () => import('@/views/pacientes/detalle/PacientesDetalleOdontogramas.vue'),
      }, {
        path: 'pagos/:estatus?',
        component: () => import('@/views/pacientes/detalle/PacientesDetallePagos.vue'),
      }, {
        path: 'planesdetratamiento',
        component: () => import('@/views/pacientes/detalle/PacientesDetallePlanesTratamiento.vue'),
      }, {
        path: 'galeriadetratamiento',
        component: () => import('@/views/pacientes/detalle/PacientesDetalleGaleriaTratamiento.vue'),
      }, {
        path: 'garantias',
        component: () => import('@/views/pacientes/detalle/PacientesDetalleGarantias.vue'),
      },
      //
    ]
  }, {
    path: '/proveedores',
    name: 'ProveedoresListado',
    component: () => import('@/views/proveedores/ProveedoresListado.vue')
  }, {
    path: '/receta/:idCon',
    name: 'ImprimiblesReceta',
    component: () => import('@/views/imprimibles/ImprimiblesReceta.vue')
  }, {
    path: '/reportes/cortecaja',
    name: 'ReportesCorteCaja',
    component: () => import('@/views/reportes/ReportesCorteCaja.vue')
  }, {
    path: '/reportes/encuestas',
    name: 'ReportesEncuestas',
    component: () => import('@/views/reportes/ReportesEncuestas.vue')
  }, {
    path: '/reportes/deudores',
    name: 'ReporteDeudores',
    component: () => import('@/views/reportes/ReporteDeudores.vue')
  }, {
    path: '/reportes/garantias',
    name: 'ReporteGarantias',
    component: () => import('@/views/reportes/ReporteGarantias.vue')
  }, {
    path: '/servicios',
    name: 'ServiciosListado',
    component: () => import('@/views/servicios/ServiciosListado.vue')
  }, {
    path: '/servicio/:idSer?',
    name: 'ServiciosForm',
    component: () => import('@/views/servicios/ServiciosForm.vue')
  }, {
    path: '/testimonios',
    name: 'TestimoniosListado',
    component: () => import('@/views/testimonios/TestimoniosListado.vue')
  }, {
    path: '/usuarios',
    name: 'UsuariosListado',
    component: () => import('@/views/usuarios/UsuariosListado.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
