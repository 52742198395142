<template>
  <div class="ConfiguracionesForm">
    <v-card
      outlined
      :loading="cargando"
      :disabled="cargando"
    >
      <v-card-title v-text="texto" />
      <v-card-text>
        <quill-editor
          :content="contenido"
          :options="editorOption"
          @change="_handleContentChange($event)"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          v-text="'Cancelar'"
          to="/"
        />
        <v-btn
          @click="_updateConfig"
          class="primary"
          v-text="'Actualizar'"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
export default {
  name: "ConfiguracionesForm",
  mixins: [standard, forms],
  components: { quillEditor },
  data() {
    return {
      tipo: null,
      texto: "",
      ruta: "configuracion/",
      contenido: "",
      contenidoUpdated: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
        theme: "snow",
      },
    };
  },
  methods: {
    _handleTipoConfig() {
      const a = this.$route.params.tipo;
      this.tipo = a;
      this.ruta = "configuracion/" + a;
      this._getTipoConfig();
      switch (a) {
        case "mision":
          this.texto = "Misión";
          break;
        case "vision":
          this.texto = "Visión";
          break;
        case "avisodeprivacidad":
          this.texto = "Aviso de privacidad";
          break;
        case "terminosycondiciones":
          this.texto = "Términos y condiciones";
          break;
        case "consentimientoinformado":
          this.texto = "Consentimiento informado";
          break;
        default:
          this.$emit(
            "msg",
            "No se encontró el tipo de configuración al que deseas acceder"
          );
          this.$router.push("/");
          break;
      }
    },
    _getTipoConfig() {
      this.contenido = "";
      this._getThings(this.ruta, "contenido");
    },
    _updateConfig() {
      const data = { contenido: this.contenido };
      this._updateAll(this.ruta, data, "msg", "_emitMsg");
    },
    _emitMsg(res) {
      if (res && "msg" in res) {
        this.$emit("msg", res.msg);
      }
    },
    _handleContentChange({ html }) {
      this.contenido = html;
    },
  },
  created() {
    this._handleTipoConfig();
  },
  watch: {
    $route(a, b) {
      this._handleTipoConfig();
    },
  },
};
</script>
