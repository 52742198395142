<template>
  <v-card
    class="PacientesAntecedentesNoPatologicos"
    outlined
    :loading="cargando"
  >
    <v-card-title>Antecedentes no patológicos</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="12"
        >
          <v-select
            label="Cepillo dental"
            v-model="nopatologicos.frecuencia_cepillo_ano"
            :items="[
              '1 vez al día',
              '2 veces al día',
              '3 veces al día',
              '4 + veces al día',
              'Ninguna',
            ]"
            @blur="_updateAntecedentesNoPatologicos"
            filled
            hint="¿Con qué frecuencia el paciente se cepilla los dientes?"
            persistent-hint
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="12"
        >
          <v-select
            label="Accesorios de higiene bucal"
            v-model="nopatologicos.accesorios_bucales_ano"
            :items="[
              'Hilo dental',
              'Cepillo',
              'Interproximal',
              'Enjuague dental',
            ]"
            multiple
            @blur="_updateAntecedentesNoPatologicos"
            filled
            hint="¿Qué accesorios usa el paciente?"
            persistent-hint
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="12"
        >
          <v-select
            label="Consumo de golosinas"
            v-model="nopatologicos.frecuencia_golosinas_ano"
            :items="[
              '1 vez al día',
              '2 veces al día',
              '3 + veces al día',
              'Ninguna',
            ]"
            @blur="_updateAntecedentesNoPatologicos"
            filled
            hint="Frecuencia con la que consume glosinas"
            persistent-hint
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="12"
        >
          <v-select
            label="Grupo sanguíneo"
            v-model="nopatologicos.grupo_sangre_ano"
            :items="[
              'O negativo',
              'O positivo',
              'A negativo',
              'A positivo',
              'B negativo',
              'B positivo',
              'AB negativo',
              'AB positivo',
            ]"
            @blur="_updateAntecedentesNoPatologicos"
            filled
          />
        </v-col>
      </v-row>
      <table
        style="min-width: 100%"
        v-for="(item, i) in campos"
        :key="i"
      >
        <tr>
          <td
            v-text="item.text"
            width="50%"
          />
          <td width="50%">
            <v-radio-group
              v-model="nopatologicos[item.radio]"
              row
              @change="_updateAntecedentesNoPatologicos"
            >
              <v-radio
                label="Si"
                value="si"
              />
              <v-radio
                label="No"
                value="no"
              />
            </v-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <v-text-field
              v-if="nopatologicos[item.radio] === 'si'"
              v-model="nopatologicos[item.input]"
              class="mt8"
              dense
              :label="'Notas del antecedente ' + item.text"
              filled
              @blur="_updateAntecedentesNoPatologicos"
            />
            <v-divider v-if="i + 1 < campos.length" />
          </td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</template>
<script>
import forms from "@/mixins/forms";
export default {
  name: "PacientesAntecedentesNoPatologicos",
  props: {
    paciente: String,
  },
  mixins: [forms],
  data() {
    return {
      /***
Con que frecuencia consume golosinas: ¿Cuántas veces al día? Una (  ) dos (  ) o más de tres (  )
Grupo sanguíneo: ____________________
Consumo de alcohol: si (  ) no (  )   frecuencia: _____________                                               Cigarros si ( ) no (  ) frecuencia _____________
       */
      cargando: true,
      nopatologicos: {
        frecuencia_cepillo_ano: null,
        accesorios_bucales_ano: null,
        frecuencia_golosinas_ano: null,
        grupo_sangre_ano: null,
        acohol_ano: null, //done
        alcohol_frecuencia_ano: null, //done
        fumador_ano: null, //done
        fumador_frecuencia_ano: null, //done
        updated_ano: null, //done
      },
      campos: [
        {
          text: "Consumo de alcohol",
          radio: "acohol_ano",
          input: "alcohol_frecuencia_ano",
        },
        {
          text: "Fumador",
          radio: "fumador_ano",
          input: "fumador_frecuencia_ano",
        },
      ],
      ruta: "antecedentes/nopatologicos/",
    };
  },
  methods: {
    async _getAntecedentesNoPatologicos() {
      if (this.paciente) {
        this.cargando = true;
        try {
          const { status, body } = await this.$http.get(
            this.ruta + this.paciente,
            this.$headers
          );
          if (status === 200 && "nopatologicos" in body) {
            this.nopatologicos = body.nopatologicos;
            this.nopatologicos.accesorios_bucales_ano = JSON.parse(
              body.nopatologicos.accesorios_bucales_ano
            );
          } else {
            this.$emit("httpError", res);
          }
          this.cargando = false;
        } catch (err) {
          this._handleError(err);
        }
      }
    },
    _updateAntecedentesNoPatologicos() {
      const predata = this.nopatologicos;
      const data = {
        nopatologicos: {
          frecuencia_cepillo_ano: predata.frecuencia_cepillo_ano,
          accesorios_bucales_ano: JSON.stringify(
            predata.accesorios_bucales_ano
          ),
          frecuencia_golosinas_ano: predata.frecuencia_golosinas_ano,
          grupo_sangre_ano: predata.grupo_sangre_ano,
          acohol_ano: predata.acohol_ano,
          alcohol_frecuencia_ano: predata.alcohol_frecuencia_ano,
          fumador_ano: predata.fumador_ano,
          fumador_frecuencia_ano: predata.fumador_frecuencia_ano,
        },
      };
      this._updateAll(this.ruta + this.paciente, data, "msg", "_wasUpdated");
    },
    _wasUpdated(res) {},
  },
  created() {
    if (this.paciente) {
      this._getAntecedentesNoPatologicos();
    }
  },
  watch: {
    paciente: {
      deep: true,
      immediate: true,
      handler: "_getAntecedentesNoPatologicos",
    },
  },
};
</script>