<template>
  <div class="PacientesListado">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Pacientes
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
        <ButtonAdd to="/paciente" />
      </v-card-title>
      <v-data-table
        v-model="selected"
        show-select
        class="upper"
        :headers="headers"
        :items="pacientes"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
        @click="value=$event"
      >
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item :to="'/paciente/'+item.id">
                <v-list-item-title v-text="'Editar'" />
              </v-list-item>
              <v-list-item :to="'/paciente/historiaclinica/'+item.id+'/citas'">
                <v-list-item-title v-text="'Historia clínica'" />
              </v-list-item>
              <v-list-item :to="'/consulta/'+item.id">
                <v-list-item-title v-text="'Consulta dental'" />
              </v-list-item>
              <v-list-item @click="idPac=item.id">
                <v-list-item-title v-text="'Carrito de compra'" />
              </v-list-item>
              <v-list-item :to="'/encuesta/'+item.id">
                <v-list-item-title v-text="'Encuesta de satisfacción'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-card-actions v-if="selected.length>=2">
        <v-btn
          color="primary"
          @click="showDialogUnirPacientes=true"
        >
          <v-icon style="margin-right:8px">group_remove</v-icon>Unificar pacientes
        </v-btn>
      </v-card-actions>
    </v-card>
    <carrito-dialog
      v-model="idPac"
      @msg="$emit('msg',$event)"
      @httpError="$emit('httpError',$event)"
    />
    <v-dialog
      v-model="showDialogUnirPacientes"
      persistent
      width="600"
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title>¿Estás seguro deseas unificar estos pacientes?</v-card-title>
        <v-card-text>Una vez unificados esto no podrá deshacerse</v-card-text>
        <v-list>
          <v-divider />
          <div
            v-for="p in selected"
            :key="p.id"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ p.nombre }}</v-list-item-title>
                <v-list-item-subtitle>{{ p.fecha_nac_pac }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </div>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="$selected=[];showDialogUnirPacientes
        =false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="unirPacientes"
          >Si, unificar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import CarritoDialog from "@/components/carrito/CarritoDialog.vue";
export default {
  name: "PacientesListado",
  mixins: [standard, forms],
  components: { CarritoDialog },
  data() {
    return {
      pacientes: [],
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Fecha nac.", value: "fecha_nac_pac" },
        { text: "Desde", value: "created_pac" },
        { text: "", value: "actions", align: "end" },
      ],
      idPac: null,
      value: {},
      selected: [],
      showDialogUnirPacientes: false,
    };
  },
  methods: {
    _getPacientes(res) {
      if (res && "msg" in res) this.$emit("msg", res.msg);
      this.showDialogUnirPacientes = false;
      this.selected = [];
      this._getAll("pacientes");
    },
    unirPacientes() {
      this._saveAll(
        "pacientes/unir",
        { pacientes: this.selected },
        "msg",
        "_getPacientes"
      );
    },
  },
  created() {
    this._getPacientes();
  },
};
</script>