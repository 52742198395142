<template>
  <v-card class="PacientesAntecedentesAlergias" outlined :loading="cargando">
    <v-card-title>Antecedentes alérgicos</v-card-title>
    <v-card-text>
      <table style="min-width: 100%" v-for="(item, i) in campos" :key="i">
        <tr>
          <td v-text="item.text" width="50%" />
          <td width="50%">
            <v-radio-group
              v-model="alergicos[item.radio]"
              row
              @change="_updateAntecedentesAlergicos"
            >
              <v-radio label="Si" value="si" />
              <v-radio label="No" value="no" />
            </v-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <v-text-field
              v-if="alergicos[item.radio] === 'si'"
              v-model="alergicos[item.input]"
              class="mt8"
              dense
              :label="'Notas de alergias a ' + item.text"
              filled
              @blur="_updateAntecedentesAlergicos"
            />
            <v-divider v-if="i + 1 < campos.length" />
          </td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</template>
<script>
import forms from "@/mixins/forms";
export default {
  name: "PacientesAntecedentesAlergias",
  props: {
    paciente: String,
  },
  mixins: [forms],
  data() {
    return {
      cargando: true,
      alergicos: {
        alimentos_aal: null,
        alimentos_notas_aal: null,
        antibioticos_aal: null,
        antibioticos_notas_aal: null,
        analgesicos_aal: null,
        analgesicos_notas_aal: null,
        otros_aal: null,
      },
      campos: [
        {
          text: "Alimentos",
          radio: "alimentos_aal",
          input: "alimentos_notas_aal",
        },
        {
          text: "Antibióticos",
          radio: "antibioticos_aal",
          input: "antibioticos_notas_aal",
        },
        {
          text: "Analgésicos",
          radio: "analgesicos_aal",
          input: "analgesicos_notas_aal",
        },
      ],
      ruta: "antecedentes/alergicos/",
    };
  },
  methods: {
    _getAntecedentesAlergicos() {
      if (this.paciente) {
        this._getThings(this.ruta + this.paciente, "alergicos");
      }
    },
    _updateAntecedentesAlergicos() {
      const data = { alergicos: this.alergicos };
      this._updateAll(this.ruta + this.paciente, data, "msg", "_wasUpdated");
    },
    _wasUpdated(res) {},
  },
  created() {
    if (this.paciente) {
      this._getAntecedentesAlergicos();
    }
  },
  watch: {
    paciente: {
      deep: true,
      immediate: true,
      handler: "_getAntecedentesAlergicos",
    },
  },
};
</script>