<template>
  <div class="CitasFormDialog">
    <v-dialog
      v-model="showDialogCita"
      width="400"
      scrollable
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title v-if="!cita.id">
          {{cita.id ? 'Editar ' : 'Registrar '}} cita {{cita.id || paciente.nombre ? 'para el paciente ' + (cita.id ? cita.nombre :paciente.nombre) : ''}}
        </v-card-title>
        <PacientesInfoGeneral
          v-if="cita.id"
          :id="cita.id_pac_cit"
        />
        <v-card-text>
          <p
            class="mt24"
            v-if="cita.celular && cita.celular!=''"
          >
            <v-icon>phone</v-icon> {{cita.celular}}
          </p>
          <!-- <v-btn v-if="cita.id" small block outlined v-text="'Ver historia clínica'" :to="'/paciente/historiaclinica/'+cita.id_pac_cit+'/citas'" target="_blank" /> -->
          <v-checkbox
            :disabled="cita.id"
            label="Es día feriado"
            value="si"
            v-model="cita.feriado_cit"
            @change="_checkIfFeriado"
          />
          <AutocompletePacientes
            v-if="(!paciente.id && !cita.id) && cita.feriado_cit!='si'"
            v-model="cita.id_pac_cit"
            :error="errors.id_pac_cit"
          />
          <DatePicker
            :label="cita.feriado_cit!='si'?'¿Cuándo es la cita?':'¿Cuál dia cancelaremos?'"
            v-model="cita.fecha_cit"
            :error="errors.fecha_cit"
            sinceToday
          />
          <TimePicker
            v-if="cita.feriado_cit!='si'"
            label="¿En qué horario?"
            v-model="cita.hora_cit"
            :error="errors.hora_cit"
            horaInicio="08:00"
            horaFin="19:00"
          />
          <AutocompleteServicios
            v-if="cita.feriado_cit!='si'"
            v-model="cita.servicios"
            multiple
          />
          <AutocompleteUsuarios
            v-if="cita.feriado_cit!='si'"
            label="¿Quién atenderá?"
            v-model="cita.id_usr_cit"
            :error="errors.id_usr_cit"
          />
          <v-select
            v-if="cita.feriado_cit!='si'"
            label="Confirmación de la cita"
            v-model="cita.estatus_cit"
            :items="[
              { text: 'Programada', value: 'programada' },
              { text: 'Confirmada', value: 'confirmada' },
              { text: 'Cancelada', value: 'cancelada' },
            ]"
            filled
          />
        </v-card-text>
        <v-card-actions
          v-if="cita.id"
          class="mb24"
        >
          <v-btn
            small
            block
            color="red"
            text
            @click="showDialogDeleteCita=true"
          >Eliminar cita</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cancelar'"
            @click="showDialogCita = false;showDialogDeleteCita=false;"
          />
          <v-btn
            @click="_preSaveCita"
            class="primary"
            v-text="cita.id ? 'Actualizar' : 'Guardar'"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="400"
      persistent
      v-model="showDialogDeleteCita"
    >
      <v-card
        :color="$error"
        dark
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title>¿Estás seguro deseas eliminar esta cita?</v-card-title>
        <v-card-text>El cambio no podrá deshacerse.</v-card-text>
        <v-card-actions>
          <v-btn
            light
            color="white"
            v-text="'Si, eliminar'"
            @click="_deleteCita(cita.id)"
          />
          <v-btn
            @click="showDialogDeleteCita=false"
            text
            v-text="'Cancelar'"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import citas from "@/mixins/cruds/citas.js";
import PacientesInfoGeneral from "@/components/pacientes/PacientesInfoGeneral.vue";
export default {
  name: "CitasFormDialog",
  mixins: [citas],
  props: {
    value: Boolean,
    id: String,
    idPac: String,
  },
  components: { PacientesInfoGeneral },
  data() {
    return {
      paciente: {},
    };
  },
  methods: {
    _getCita() {
      // this.showDialogDeleteCita = false;
      if (this.id) this._getThings("citas/" + this.id, "cita");
    },
    _getPaciente() {
      if (this.idPac)
        this._getThings("pacientes/" + this.idPac + "?simple=si", "paciente");
    },
  },
  created() {
    this._getPaciente();
  },
  watch: {
    value(a) {
      if (a) {
        this.showDialogCita = true;
      } else {
        this.cita.servicios = [];
        this._limpiarCampos("cita");
      }
    },
    id(a) {
      if (a) {
        this._getCita();
      }
    },
    paciente(a) {
      if (a.id && !this.id) {
        this.cita.id_pac_cit = a.id_pac;
      }
    },
  },
};
</script>