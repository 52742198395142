<template>
  <div class="DepositoDentalListado">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Depósito dental
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
        <deposito-dental-form-dialog
          @msg="$event=>$emit('msg',$event)"
          @httpError="$event=>$emit('httpError',$event)"
          @refresh="_getDepositos"
          v-model="idDep"
        />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="depositos"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.precio_dep="{ item }">
          {{$nF(item.precio_dep)}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item @click="idDep=item.id">
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="idDepImg=item.id_dep;nombre=item.nombre_dep;showDialogImagenes=true">
                <v-list-item-title>Imágenes</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      width="800"
      v-model="showDialogImagenes"
    >
      <v-card
        :loading="loading"
        :disabled="loading"
      >
        <v-card-title>Imágenes para {{nombre.toUpperCase() }}</v-card-title>
        <v-card-text>
          <v-file-input
            filled
            label="Subir fotografía del material"
            accept="image/jpeg, image/png, image/jpg"
            prepend-icon=""
            prepend-inner-icon="attach_file"
            v-model="archivo"
          />
          <v-btn
            :disabled="archivo?false:true"
            class="mb24"
            color="primary"
            @click="_saveImagenes"
          ><v-icon>upload</v-icon>Subir</v-btn>
          <v-row>
            <v-col
              v-for="img in imagenes"
              :key="img.id_def"
              class="d-flex child-flex"
              cols="3"
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    :src="_getImage(img.img_def)"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate />
                      </v-row>
                    </template>
                  </v-img>
                  <!--<v-btn color="primary" dark v-bind="attrs" v-on="on" >Dropdown</v-btn>-->
                </template>
                <v-list dark>
                  <v-list-item @click="_deleteImage(img.id_def)">
                    <v-list-item-title><v-icon>delete</v-icon>&nbsp;Eliminar imagen</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import DepositoDentalFormDialog from "@/components/depositodental/DepositoDentalFormDialog.vue";
export default {
  name: "DepositoDentalListado",
  mixins: [standard, forms],
  components: {
    DepositoDentalFormDialog,
  },
  methods: {
    _getDepositos() {
      this.idDep = null;
      this._getThings("depositodental/materiales", "depositos");
    },
    _getImagenes(res = null) {
      if (res && "msg" in res) this.$emit("msg", res.msg);
      this._getThings("depositodental/imagenes/" + this.idDepImg, "imagenes");
    },
    _saveImagenes() {
      let data = new FormData();
      data.append("imagen", this.archivo);
      this._saveAll(
        "depositodental/imagenes/" + this.idDepImg,
        data,
        "msg",
        "_getImagenes"
      );
    },
    _getImage(image) {
      return this.$raiz + "uploads/" + image;
    },
    _deleteImage(id) {
      this._deleteAll("depositodental/imagenes/" + id, "msg", "_getImagenes");
    },
  },
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        { text: "Nombre", value: "nombre_dep" },
        { text: "Categoría", value: "nombre_dca" },
        { text: "Desde", value: "precio_dep", align: "end" },
        { text: "Descripción", value: "descripcion_dep" },
        { text: "", value: "actions", align: "end" },
      ],
      depositos: [],
      idDep: null,
      idDepImg: null,
      nombre: "",
      showDialogImagenes: false,
      image: null,
      archivo: null,
      imagenes: [],
    };
  },
  created() {
    this._getDepositos();
  },
  watch: {
    showDialogImagenes(a) {
      if (!a) {
        this.nombre = "";
        this.idDepImg = null;
      } else {
        this._getImagenes();
      }
    },
  },
};
</script>