<template>
  <div class="AutocompleteUsuarios">
    <v-autocomplete
      v-if="!isSelect"
      v-model="value"
      append-icon="search"
      :error-messages="error"
      item-text="nombre"
      item-value="id_usr"
      :items="usuarios"
      :label="label ? label : 'Buscar usuario'"
      filled
      @change="_searchAndSendUsr"
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-text="data.item.nombre" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-select
      v-else
      v-model="value"
      :error-messages="error"
      item-text="nombre_cli"
      item-value="id_cli"
      :items="usuarios"
      :label="label ? label : 'Seleccionar usuario'"
      filled
      @change="_searchAndSendUsr"
    />
  </div>
</template>
<script>
import forms from '@/mixins/forms.js';
export default {
  name: 'AutocompleteUsuarios',
  mixins: [forms],
  props: {
    value: String,
    isSelect: Boolean,
    label: String,
    error: String
  },
  data() {
    return {
      usuarios: []
    };
  },
  methods: {
    _searchAndSendUsr(a) {
      this.$emit('input', a);
      let i = this.usuarios.findIndex(function(b) {
        return b.id_cli === a;
      });
      if (i >= 0) {
        this.$emit('change', this.usuarios[i]);
      }
    }
  },
  created() {
    this._getThings('usuarios?estatus=activo', 'usuarios');
  }
};
</script>
