<template>
  <div class="PacienteDetalleOdontogramas">
    <v-row dense>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-card outlined>
          <v-list>
            <v-list-item @click="showDialogForm=true">
              <v-list-item-content>
                <v-list-item-title v-text="'Registrar cambios en el odontograma'" />
              </v-list-item-content>
              <v-list-item-action>
                <button-add @click="showDialogForm=true" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="odo in odontogramas"
        :key="odo.id"
      >
        <v-card outlined>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Fecha: {{_doDiaMesHoras(odo.fecha_odo)}}</v-list-item-title>
                <v-list-item-subtitle class="nowrap">
                  Médico: {{odo.medico}}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="idOdontograma=odo.id"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <odontograma-form
      v-model="showDialogForm"
      :paciente="paciente"
      @msg="$emit('msg',$event)"
      @httpError="$emit('httpError',$event)"
      @updated="_getOdontogramas"
    />
    <odontograma-detalle
      v-model="idOdontograma"
      @msg="$emit('msg',$event)"
      @httpError="$emit('httpError',$event)"
    />
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
import ButtonAdd from "@/components/forms/buttons/ButtonAdd.vue";
import OdontogramaForm from "@/components/odontograma/OdontogramaForm.vue";
import OdontogramaDetalle from "@/components/odontograma/OdontogramaDetalle.vue";
export default {
  components: { ButtonAdd, OdontogramaForm, OdontogramaDetalle },
  name: "PacientesDetalleOdontogramas",
  mixins: [forms, dates],
  data() {
    return {
      paciente: null,
      odontogramas: [],
      showDialogForm: false,
      showDialogDetalle: false,
      idOdontograma: null,
    };
  },
  methods: {
    _getOdontogramas(res = null) {
      if (res) {
        this.showDialogForm = false;
      }
      this._getThings("odontogramas/" + this.paciente, "odontogramas");
    },
    _getOdontograma() {},
  },
  created() {
    this.paciente = this.$route.params.idPac;
    this._getOdontogramas();
  },
  watch: {
    $route(a, b) {
      this._getOdontogramas();
    },
  },
};
</script>