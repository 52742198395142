<template>
  <div class="ConsentimientosListado">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Formatos de consentimientos informadods
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
        <ButtonAdd to="/consentimiento" />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="consentimientos"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item :to="'/consentimiento/'+item.id">
                <v-list-item-title v-text="'Editar'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
export default {
  name: "ConsentimientosListado",
  mixins: [standard, forms],
  data() {
    return {
      consentimientos: [],
      headers: [
        { text: "Nombre", value: "nombre_cfo" },
        { text: "Estatus", value: "estatus_cfo" },
        { text: "", value: "actions", align: "end" },
      ],
    };
  },
  methods: {},
  created() {
    this._getThings("consentimientosinformados", "consentimientos");
  },
};
</script>