<template>
  <div class="AutocompleteServicios">
    <v-autocomplete
      v-if="!isSelect && !multiple"
      v-model="valor"
      append-icon="search"
      :error-messages="error"
      item-text="desc_ser"
      item-value="id_ser"
      :items="servicios"
      :label="label ? label : 'Buscar servicio'"
      filled
      @change="_searchAndSendSer"
      @keyup.enter="$emit('enter',true)"
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-text="data.item.desc_ser+', '+$nF(data.item.precio_ser)" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-if="!isSelect && multiple"
      v-model="valor"
      append-icon="search"
      multiple
      :error-messages="error"
      item-text="desc_ser"
      item-value="id_ser"
      :items="servicios"
      :label="label ? label : 'Seleccionar servicios'"
      filled
      @change="$emit('input',$event)"
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-text="data.item.desc_ser+', '+$nF(data.item.precio_ser)" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-select
      v-if="isSelect"
      v-model="valor"
      :error-messages="error"
      item-text="desc_ser"
      item-value="id_ser"
      :items="servicios"
      :label="label ? label : 'Seleccionar servicio'"
      filled
      @change="_searchAndSendSer"
    />
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "AutocompleteServicios",
  mixins: [forms],
  props: {
    value: [String, Array],
    isSelect: Boolean,
    label: String,
    error: String,
    multiple: Boolean,
  },
  data() {
    return {
      cargando: false,
      servicios: [],
      valor: null,
    };
  },
  methods: {
    _searchAndSendSer(a) {
      this.$emit("input", a);
      let i = this.servicios.findIndex(function (b) {
        return b.id_ser === a;
      });
      if (i >= 0) {
        this.$emit("change", this.servicios[i]);
      }
    },
  },
  created() {
    if (this.value) this.valor = this.value;
    this._getThings("servicios?simple=si", "servicios");
  },
  watch: {
    value(a) {
      this.valor = a;
    },
    valor(a) {
      this.$emit("input", a);
    },
  },
};
</script>