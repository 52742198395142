<template>
  <div class="AutocompleteOdontogramas">
    <v-autocomplete
      v-model="value"
      append-icon="search"
      :error-messages="error"
      item-text="fecha_odo"
      item-value="id_odo"
      :items="odontogramas"
      :label="label ? label : 'Buscar odontograma'"
      filled
      @change="_searchAndSendPro"
    >
      <template v-slot:item="data">
        <v-list-item-content dense>
          <v-list-item-title v-text="'Fecha: '+_doDiaMesHoras(data.item.fecha_odo)" />
          <v-list-item-subtitle v-text="'Médico: '+data.item.medico" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
export default {
  name: "AutocompleteOdontogramas",
  mixins: [forms, dates],
  props: {
    value: String,
    isSelect: Boolean,
    label: String,
    error: String,
    paciente: [String, Number],
  },
  data() {
    return {
      odontogramas: [],
    };
  },
  methods: {
    _searchAndSendPro(a) {
      this.$emit("input", a);
      let i = this.proveedores.findIndex(function (b) {
        return b.id_pro === a;
      });
      if (i >= 0) {
        this.$emit("change", this.proveedores[i]);
      }
    },
    _getOdontogramas() {
      this._getThings("odontogramas/" + this.paciente, "odontogramas");
    },
  },
  created() {
    this._getOdontogramas();
  },
  watch: {
    paciente: {
      deep: true,
      immediate: true,
      handler: "_getOdontogramas",
    },
  },
};
</script>