<template>
  <v-card class="PacientesAntecedentesPatologicos" outlined :loading="cargando">
    <v-card-title>Antecedentes patológicos</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          v-for="(item, i) in campos"
          :key="i"
          :cols="item.cols.xs"
          :sm="item.cols.sm"
          :md="item.cols.md"
          :lg="item.cols.lg"
        >
          <div v-if="item.radio">
            <table>
              <tr>
                <td width="50%">
                  <label for="">{{ item.text }}</label>
                </td>
                <td width="50%">
                  <v-radio-group
                    v-model="patologicos[item.radio]"
                    row
                    @change="_updateAntecedentesPatologicos"
                  >
                    <v-radio label="Si" value="si" />
                    <v-radio label="No" value="no" />
                  </v-radio-group>
                </td>
              </tr>
            </table>
          </div>
          <div v-if="patologicos[item.radio] === 'si' || !item.radio">
            <label for="" v-if="!item.radio">{{ item.text }}</label>
            <v-text-field
              v-for="(input, indexinput) in item.input"
              :key="indexinput + '_' + i"
              v-model="patologicos[input.input]"
              class="mt8"
              dense
              :label="input.text"
              filled
              @blur="_updateAntecedentesPatologicos"
            />
          </div>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import forms from "@/mixins/forms";
export default {
  name: "PacientesAntecedentesPatologicos",
  props: {
    paciente: String,
  },
  mixins: [forms],
  data() {
    return {
      cargando: true,
      patologicos: {
        diabetes_ahe: null,
        diabetes_notas_ahe: null,
        hipertension_ahe: null,
        hipertension_notas_ahe: null,
        cancer_ahe: null,
        cancer_notas_ahe: null,
        otras_ahe: null,
        otras_notas_ahe: null,
      },
      campos: [
        {
          cols: { xs: "12", sm: "6", md: "4", lg: "4" },
          text: "Varicela",
          radio: "varicela_apa",
          input: [
            { text: "Notas sobre la varicela", input: "varicela_notas_apa" },
          ],
        },

        {
          cols: { xs: "12", sm: "6", md: "4", lg: "4" },
          text: "Rubéola",
          radio: "rubeola_apa",
          input: [
            { text: "Notas sobre la rubéola", input: "rubeola_notas_apa" },
          ],
        },
        {
          cols: { xs: "12", sm: "6", md: "4", lg: "4" },
          text: "Sarampión",
          radio: "sarampion_apa",
          input: [
            { text: "Notas sobre el sarampión", input: "sarampion_notas_apa" },
          ],
        },
        {
          cols: { xs: "12", sm: "6", md: "12", lg: "12" },
          text: "Otras enfermedades de la infancia",
          radio: false,
          input: [{ text: "¿Cuáles otras enfermedades?", input: "otros_apa" }],
        },
        {
          cols: { xs: 12, sm: 6, md: 4, lg: 4 },
          text: "VIH",
          radio: "vih_apa",
          input: [{ text: "Notas sobre el VHI", input: "vih_notas_apa" }],
        },
        {
          cols: { xs: 12, sm: 6, md: 4, lg: 4 },
          text: "Sífilis",
          radio: "sifilis_apa",
          input: [
            { text: "Notas sobre la sífilis", input: "sifilis_notas_apa" },
          ],
        },
        {
          cols: { xs: 12, sm: 6, md: 4, lg: 4 },
          text: "VPH",
          radio: "vph_apa",
          input: [{ text: "Notas sobre el VPH", input: "vph_notas_apa" }],
        },
        {
          cols: { xs: 12, sm: 6, md: 12, lg: 12 },
          text: "Otras ETS",
          radio: false,
          input: [{ text: "¿Cuáles otras ETS?", input: "otras_ets_apa" }],
        },
        {
          cols: { xs: 12, sm: 6, md: 4, lg: 4 },
          text: "Hipertensión",
          radio: "hipertension_apa",
          input: [
            {
              text: "Notas sobre la hipertensión",
              input: "hipertension_notas_apa",
            },
          ],
        },
        {
          cols: { xs: 12, sm: 6, md: 4, lg: 4 },
          text: "Diabetes",
          radio: "diabetes_apa",
          input: [
            { text: "Notas sobre la diabetes", input: "diabetes_notas_apa" },
          ],
        },
        {
          cols: { xs: 12, sm: 6, md: 4, lg: 4 },
          text: "Cáncer",
          radio: "cancer_apa",
          input: [{ text: "Notas sobre el cáncer", input: "cancer_notas_apa" }],
        },
        {
          cols: { xs: 12, sm: 6, md: 12, lg: 12 },
          text: "Otras enfermedades crónico degenerativas",
          radio: false,
          input: [
            {
              text: "¿Cuáles cronico degenerativas?",
              input: "otros_cronico_apa",
            },
          ],
        },
        {
          cols: { xs: 12, sm: 6, md: 12, lg: 12 },
          text: "¿Ha sido hospitalizado?",
          radio: "hospitalizacion_apa",
          input: [
            {
              text: "Motivo de la hospitalización",
              input: "hospitalizacion_motivo_apa",
            },
            {
              text: "¿Hace cuánto tiempo fue hospitalizado?",
              input: "hospitalizacion_tiempo_apa",
            },
          ],
        },
        {
          cols: { xs: 12, sm: 6, md: 12, lg: 12 },
          text: "Enfermedades sitemáticas",
          radio: "enfermedad_sist_apa",
          input: [
            {
              text: "Fecha, año y otras anotaciones del diagnóstico",
              input: "enfermedad_sist_fecha_apa",
            },
          ],
        },
        {
          cols: { xs: 12, sm: 6, md: 12, lg: 12 },
          text: "¿Esta bajo algún tratamiento médico?",
          radio: "tratamiento_medico_apa",
          input: [
            {
              text: "¿Cuál tratamiento médico?",
              input: "tratamiento_medico_notas_apa",
            },
          ],
        },
      ],
      ruta: "antecedentes/patologicos/",
    };
  },
  methods: {
    _getAntecedentesPatologicos() {
      if (this.paciente) {
        this._getThings(this.ruta + this.paciente, "patologicos");
      }
    },
    _updateAntecedentesPatologicos() {
      const data = { patologicos: this.patologicos };
      this._updateAll(this.ruta + this.paciente, data, "msg", "_wasUpdated");
    },
    _wasUpdated(res) {},
  },
  created() {
    if (this.paciente) {
      this._getAntecedentesPatologicos();
    }
  },
  watch: {
    paciente: {
      deep: true,
      immediate: true,
      handler: "_getAntecedentesPatologicos",
    },
  },
};
</script>