<template>
  <div class="ReporteDeudores">
    <v-card
      outlined
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        Deudores
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="deudores"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
        @click="value=$event"
      >
        <template v-slot:item.pendiente="{ item }">
          {{$nF(item.pendiente)}}</template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item :to="'/paciente/'+item.id_pac">
                <v-list-item-title v-text="'Editar'" />
              </v-list-item>
              <v-list-item :to="'/paciente/historiaclinica/'+item.id_pac+'/citas'">
                <v-list-item-title v-text="'Historia clínica'" />
              </v-list-item>
              <v-list-item :to="'/consulta/'+item.id_pac">
                <v-list-item-title v-text="'Consulta dental'" />
              </v-list-item>
              <v-list-item @click="idPac=item.id_pac">
                <v-list-item-title v-text="'Carrito de compra'" />
              </v-list-item>
              <v-list-item :to="'/encuesta/'+item.id_pac">
                <v-list-item-title v-text="'Encuesta de satisfacción'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import CarritoDialog from "@/components/carrito/CarritoDialog.vue";
export default {
  name: "ReporteDeudores",
  mixins: [standard, forms],
  components: { CarritoDialog },
  data() {
    return {
      deudores: [],
      headers: [
        { text: "Nombre", value: "paciente" },
        { text: "Importe pendiente.", value: "pendiente", align: "end" },
        { text: "", value: "actions", align: "end" },
      ],
      idPac: null,
      value: {},
    };
  },
  methods: {},
  created() {
    this._getThings("reportes/deudores", "deudores");
  },
};
</script>