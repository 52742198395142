<template>
  <div class="CarritoDialog">
    <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-btn
            icon
            dark
            @click="showDialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title v-text="`Carrito del paciente ${paciente.nombre_pac} ${paciente.ape_pat_pac}`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="showDialog = false"
            >
              Cerrar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt24">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <autocomplete-servicios
                v-model="cart.id_ser_sca"
                @change="_handleServicioChange"
                @enter="_addCarrito"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                filled
                label="Cantidad"
                v-model="cart.cant_sca"
                type="number"
                min="1"
                @keyup.enter="_addCarrito"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                filled
                label="Precio"
                prepend-inner-icon="attach_money"
                v-model="cart.precio_sca"
                @keyup.enter="_addCarrito"
              />
            </v-col>

          </v-row>
          <v-spacer />

          <v-btn
            class="primary"
            @click="_addCarrito"
          >
            <v-icon>add</v-icon>Agregar
          </v-btn>
        </v-card-text>
        <v-simple-table v-if="carrito.length>=1">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Servicio</th>
                <th align="right">Cant.</th>
                <th align="right">P.U.</th>
                <th align="right">Subtotal</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,idxc) in carrito"
                :key="idxc"
              >
                <td v-text="item.desc_ser" />
                <td
                  align="right"
                  v-text="item.cant_sca"
                />
                <td
                  align="right"
                  v-text="$nF(item.precio_sca)"
                />
                <td
                  align="right"
                  v-text="$nF(item.cant_sca*item.precio_sca)"
                />
                <td>
                  <v-btn
                    icon
                    @click="_deleteCarrito(idxc)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td align="right"><strong>Total:</strong></td>
                <td
                  align="right"
                  v-text="$nF(total)"
                />
                <td></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions v-if="carrito && carrito.length>=1">
          <v-spacer />
          <v-btn
            class="primary"
            :loading="cargando"
            :disabled="cargando"
            v-text="'Enviar carrito a cobro'"
            @click="_guardarCarritoEnCobranza"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import AutocompleteServicios from "@/components/forms/autocompletes/AutocompleteServicios.vue";
export default {
  name: "CarritoDialog",
  mixins: [forms],
  props: {
    value: String,
  },
  components: { AutocompleteServicios },
  data() {
    return {
      showDialog: false,
      paciente: {},
      servicios: [],
      carrito: [],
      cart: {
        id_pac_sca: null,
        id_ser_sca: null,
        cant_sca: null,
        precio_sca: null,
      },
      errors: {
        id_ser_sca: null,
        cant_sca: null,
        precio_sca: null,
      },
    };
  },
  methods: {
    _getPaciente() {
      this._getThings("pacientes/" + this.value, "paciente", "?simple=true");
    },
    _getCarrito(res = null) {
      if (res && "msg" in res) {
        this.$emit("msg", res.msg);
        this._limpiarCampos("cart");
        this.cart.id_pac_sca = this.paciente.id_pac;
        this.cart.cant_sca = 1;
      }
      this.cargando = true;
      this._getThings("carrito/" + this.value, "carrito");
    },
    _handleServicioChange(a) {
      this.cart.precio_sca = a.precio_ser;
    },
    _addCarrito() {
      let err = null;
      if (!this.cart.id_ser_sca) {
        err = "Selecciona un servicio para agregar al carrito";
      }
      if (!this.cart.cant_sca && this.cart.cant_sca < 1) {
        err = "La cantidad debe ser por lo menos 1";
      }
      if (!this.cart.precio_sca && this.cart.precio_sca <= 0) {
        err = "El precio no puede ser negativo";
      }
      if (err) {
        this.$emit("msg", err);
        return false;
      }
      const data = { carrito: this.cart };
      this._saveAll("carrito", data, "id", "_getCarrito");
    },
    _deleteCarrito(idxc = null) {
      const { id } = this.carrito[idxc];
      this._deleteAll("carrito/" + id, "msg", "_getCarrito");
    },
    _guardarCarritoEnCobranza() {
      this._saveAll(
        "carrito/cobrar/" + this.value,
        { cuenta: this.value },
        "id",
        "_handleResponseCobranzaCarrito"
      );
    },
    _handleResponseCobranzaCarrito(res) {
      this.$router.push("/paciente/historiaclinica/" + this.value + "/pagos");
      this.$emit("msg", res.msg);
      this.showDialog = false;
    },
  },
  created() {},
  watch: {
    value(a) {
      if (a) {
        this._getPaciente();
        this._getCarrito();
      }
    },
    paciente(a) {
      if (a && a.id_pac) {
        this.cart.id_pac_sca = a.id_pac;
        this.cart.cant_sca = 1;
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    },
    showDialog(a) {
      if (!a) {
        this.$emit("input", false);
      }
    },
  },
  computed: {
    total() {
      let total = 0;
      this.carrito.forEach((c) => {
        total = total + c.cant_sca * c.precio_sca;
      });
      return total;
    },
  },
};
</script>