<template>
  <v-card
    class="PacientesAntecedentesHeredofamiliares"
    outlined
    :loading="cargando"
  >
    <v-card-title>Antecedentes heredofamiliares</v-card-title>
    <v-card-text>
      <table
        style="min-width: 100%"
        v-for="(item, i) in campos"
        :key="i"
      >
        <tr>
          <td
            v-text="item.text"
            width="50%"
          />
          <td width="50%">
            <v-radio-group
              v-model="heredofamiliares[item.radio]"
              row
              @change="_updateAntecedentesHeredofamiliares"
            >
              <v-radio
                label="Si"
                value="si"
              />
              <v-radio
                label="No"
                value="no"
              />
            </v-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <v-text-field
              v-if="heredofamiliares[item.radio] === 'si'"
              v-model="heredofamiliares[item.input]"
              class="mt8"
              dense
              :label="'Notas del antecedente ' + item.text"
              filled
              @blur="_updateAntecedentesHeredofamiliares"
            />
            <v-divider v-if="i + 1 < campos.length" />
          </td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</template>
<script>
import forms from "@/mixins/forms";
export default {
  name: "PacientesAntecedentesHeredofamiliares",
  props: {
    paciente: String,
  },
  mixins: [forms],
  data() {
    return {
      cargando: true,
      heredofamiliares: {
        diabetes_ahe: null,
        diabetes_notas_ahe: null,
        hipertension_ahe: null,
        hipertension_notas_ahe: null,
        cancer_ahe: null,
        cancer_notas_ahe: null,
        otras_ahe: null,
        otras_notas_ahe: null,
      },
      campos: [
        {
          text: "Diabetes",
          radio: "diabetes_ahe",
          input: "diabetes_notas_ahe",
        },
        {
          text: "Hipertensión",
          radio: "hipertension_ahe",
          input: "hipertension_notas_ahe",
        },
        {
          text: "Cáncer",
          radio: "cancer_ahe",
          input: "cancer_notas_ahe",
        },
        {
          text: "Otros",
          radio: "otras_ahe",
          input: "otras_notas_ahe",
        },
      ],
      ruta: "antecedentes/heredofamiliares/",
    };
  },
  methods: {
    _getAntecedentesHeredofamiliares() {
      if (this.paciente) {
        this._getThings(this.ruta + this.paciente, "heredofamiliares");
      }
    },
    _updateAntecedentesHeredofamiliares() {
      const data = { heredofamiliares: this.heredofamiliares };
      this._updateAll(this.ruta + this.paciente, data, "msg", "_wasUpdated");
    },
    _wasUpdated(res) {},
  },
  created() {
    if (this.paciente) {
      this._getAntecedentesHeredofamiliares();
    }
  },
  watch: {
    paciente: {
      deep: true,
      immediate: true,
      handler: "_getAntecedentesHeredofamiliares",
    },
  },
};
</script>