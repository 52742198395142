<template>
  <div class="ImprimiblesReceta">
    <v-card outlined>
      <v-card-title>Receta</v-card-title>
      <v-card-text>
        <div
          class="printMe"
          id="printMe"
          style="font-family: Arial !important; font-size: 15px !important; white-space: pre-wrap !important; white-space: -moz-pre-wrap !important; white-space: -pre-wrap !important; white-space: -o-pre-wrap !important; word-wrap: break-word !important; text-transform: uppercase !important;"
        >
          <div style="font-family: Arial !important; font-size: 15px !important;">
            <table>
              <tr>
                <td width="15%"></td>
                <td width="70%">
                  <img
                    src="./../../assets/receta-header.png"
                    style="max-width: 100%;"
                    alt=""
                  >
                </td>
                <td width="15%"></td>
              </tr>
              <tr>
                <td
                  width="100%"
                  colspan="3"
                >
                  <div style="font-size:12px">
                    López Rayón #85 Col. Centro, Los Reyes de Salgado, Michoacán, México.<br>
                    Horario: Lunes a Viernes 8:00 a 14:00 hr. Y de 16:00 a 20:00 hr. Sábados 8:00 a 14:00Hr.<br>
                    Tel: (354)5425787<br>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3"><br>{{_doDiaMesHoras(receta.fecha_con)}}<br>
                  PACIENTE <strong>{{receta.ape_pat_pac}} {{receta.ape_mat_pac}} {{receta.nombre_pac}}</strong> DE <strong style="font-family: Arial !important; font-size: 15px !important;">{{_doEdad(this.receta.fecha_nac_pac)}} AÑOS DE EDAD.</strong>
                  <br>
                  <br>
                  <hr class="receta">
                  <br>
                  <strong style="font-weight:700!important">MEDICAMENTOS Y RECOMENDACIONES:</strong>
                  <p v-html="receta.receta_con" />
                </td>
              </tr>
            </table>
            <br>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="_printReceta"
        >
          <v-icon style="margin-right:4px">print</v-icon>
          Imprimir
        </v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          :to="'/paciente/historiaclinica/'+idPac"
        >
          Continuar
          <!-- <v-icon>chevron_right</v-icon> -->
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import standard from "@/mixins/standard.js";
import dates from "@/mixins/dates.js";
export default {
  name: "ImprimiblesReceta",
  mixins: [standard, dates],
  data() {
    return {
      receta: {
        receta_con: null,
        id_pac: null,
      },
      idPac: null,
    };
  },
  methods: {
    async _getReceta() {
      this.cargando = true;
      try {
        const { status, body } = await this.$http.get(
          "consulta/" + this.$route.params.idCon,
          this.$headers
        );
        if (status === 200 && "consulta" in body) {
          this.receta = body.consulta;
          this.idPac = body.paciente;
          this._printReceta();
        } else {
          this.$emit("msg", body.error);
        }
        this.cargando = false;
      } catch (err) {
        this.$emit("httpError", err);
        this.cargando = false;
      }
    },
    async _printReceta() {
      await setTimeout(() => this.$htmlToPaper("printMe"), 700);
    },
  },
  created() {
    this._getReceta();
  },
};
</script>
